import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { isEmpty, isNull } from 'lodash';
import { NativeSelect, FormControl } from '@mui/material';

import { returnMonths } from '../../../common/actions/returnMonths';
import Loading from '../../../common/loading/loadingInBlock';

export default class VpnMonths extends Component {
    sanitizeVpnIdMonth = (item) => {
        let arraySize = 12;
        let prova = [];

        if (!isNull(item) && !isEmpty(item)) {
            for (let i = 0; i < arraySize; i++) {
                prova[i] = 0;
            }

            // eslint-disable-next-line
            item.map(el => {
                for (let [key, value] of Object.entries(el)) {
                    prova.splice(returnMonths(key), 1, value);
                }
            });
        }
        return prova;
    }

    render() {
        const { vpnOneMonthValue, vpnThreeMonthsValue, vpnSixMonthsValue, vpnTwelveMonthsValue, vpnTwentyfourMonthsValue, vpnThreeMonthsHwValue, vpnSixMonthsHwValue, vpnTwelveMonthsHwValue, vpnTwentyfourMonthsHwValue, loadingGraph12, onChange, vpnChange } = this.props;

        const optionsVpnMonthsLicence = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },
            
            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: !vpnChange ? 'Vpn Pass al Mese' : 'Vpn Box al Mese',
                    font: {
                        size: 20
                    }
                }
            },

            scales: {
                y: {
                    ticks: {
                        beginAtZero: true
                    },
                    stacked: true
                },
                x: {
                    stacked: true
                }
            }
        };

        const dataVpnMonthsLicence = {
            labels: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
            datasets: [
                {
                    label: "Vpn 1 Month",
                    backgroundColor: 'rgba(42,110,255, 0.5)',
                    data: this.sanitizeVpnIdMonth(vpnOneMonthValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn 3 Months",
                    backgroundColor: 'rgba(88,255,112,0.5)',
                    data: this.sanitizeVpnIdMonth(vpnThreeMonthsValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn 6 Months",
                    backgroundColor: 'white',
                    data: this.sanitizeVpnIdMonth(vpnSixMonthsValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn 12 Months",
                    backgroundColor: 'rgba(255,166,59,0.5)',
                    data: this.sanitizeVpnIdMonth(vpnTwelveMonthsValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn 24 Months",
                    backgroundColor: 'rgba(106, 120, 205,0.5)',
                    data: this.sanitizeVpnIdMonth(vpnTwentyfourMonthsValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        const dataVpnMonthsHwLicence = {
            labels: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
            datasets: [
                {
                    label: "Vpn Box 3 Months",
                    backgroundColor: 'rgba(88,255,112,0.5)',
                    data: this.sanitizeVpnIdMonth(vpnThreeMonthsHwValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn Box 6 Months",
                    backgroundColor: 'white',
                    data: this.sanitizeVpnIdMonth(vpnSixMonthsHwValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn Box 12 Months",
                    backgroundColor: 'rgba(255,166,59,0.5)',
                    data: this.sanitizeVpnIdMonth(vpnTwelveMonthsHwValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn Box 24 Months",
                    backgroundColor: 'rgba(106, 120, 205,0.5)',
                    data: this.sanitizeVpnIdMonth(vpnTwentyfourMonthsHwValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChart">
                    <FormControl className="licenceMonth">
                        <NativeSelect
                            name="vpnChange"
                            value={vpnChange}
                            onChange={onChange}
                        >
                            <option className="colorMonth" value={false}>VPN Pass</option>
                            <option className="colorMonth" value={true}>VPN Box</option>
                        </NativeSelect>
                    </FormControl>

                    {
                        loadingGraph12 ? <Loading /> :
                            <Bar options={optionsVpnMonthsLicence} data={!vpnChange ? dataVpnMonthsLicence : dataVpnMonthsHwLicence} />
                    }
                </div>
            </div>
        )
    }
}