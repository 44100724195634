import React, { Component } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

/**
 * Attivazione modale per inviare un messaggio al player
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "deviceId" : l'id del player a cui mandare il messaggio
 *      "toggle" : al click di annulla o un'area esterna alla modale, chiude la modale
 *      "stateModal" : stato della modale (chiusa o aperta), boolean
 *      "titleModal" : titolo della modale
 */
export default class modalSendMessagePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            messageNull: false,
        }
        this.setMessage = this.setMessage.bind(this);
    }

    setMessage = (event) => {
        this.setState({
            message: event.target.value,
            messageNull: false
        });
    }

    confirmSend = () => {
        if (this.state.message === "") {
            this.props.errorNotify('Inserisci un messaggio');
            this.setState({ messageNull: true });
        } else {
            this.setState({ messageNull: false });
            let data = {
                deviceId: this.props.deviceId,
                message: this.state.message
            };
            this.props.click(data);
        }
    }

    render() {
        const { deviceId, stateModal, toggle, titleModal } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalMail">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label text-right">ID Device</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" defaultValue={deviceId} readOnly />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label text-end">Messaggio</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.message} onChange={this.setMessage}></textarea>
                    </div>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}