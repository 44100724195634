import React, { Component } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import { countries } from '../countries';
import { isEmpty, isNull } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default class ModalCreateLicence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.user.user.name || '',
            surname: this.props.user.user.surname || '',
            email: this.props.user.user.email || '',
            country: this.props.user.user.country || '',
            discount: null
        }
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    sendData = () => {
        const { errorNotify } = this.props;
        let data = {};

        if (this.state.name === '') {
            errorNotify('Inserisci un nome');

        } else if (this.state.surname === '') {
            errorNotify('Inserisci un cognome');

        } else if (this.state.email === '') {
            errorNotify('Inserisci un\'email');

        } else if (isEmpty(this.state.country)) {
            errorNotify('Inserire un paese');

        } else if (!isNull(this.state.discount) && this.state.discount > 100) {
            errorNotify('Lo sconto non può essere maggiore del 100%');

        } else {
            data = {
                discount: this.state.discount === null ? 0 : (this.state.discount * 0.01).toFixed(2),
                author: {
                    email: this.state.email,
                    address: {
                        name: this.state.name,
                        surname: this.state.surname,
                        country: this.state.country
                    }
                }
            }

            this.props.sendData(data, this.props.filterLicence);
            this.props.toggle();
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true}>
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <div className='row'>
                        <div className='col-6' style={{ marginBottom: '1rem' }}>
                            <TextField
                                name="name"
                                label="Nome"
                                value={this.state.name}
                                onChange={this.setChange}
                            />
                        </div>

                        <div className='col-6' style={{ marginBottom: '1rem' }}>
                            <TextField
                                name="surname"
                                label="Cognome"
                                value={this.state.surname}
                                onChange={this.setChange}
                            />
                        </div>

                        <div className='col-10' style={{ marginBottom: '1rem' }}>
                            <TextField
                                name="email"
                                label="Email"
                                value={this.state.email}
                                onChange={this.setChange}
                            />
                        </div>

                        <div className='col-9' style={{ marginBottom: '1rem' }}>
                            <FormControl className='customSelect'>
                                <InputLabel>Paese</InputLabel>
                                <Select
                                    labelId="keyCountry"
                                    name="country"
                                    value={this.state.country}
                                    onChange={this.setChange}
                                >
                                    {
                                        countries.map((item, k) => {
                                            return (
                                                <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div className='col-3' style={{ marginBottom: '1rem' }}>
                            <TextField
                                name="discount"
                                label="Sconto (%)"
                                type="number"
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                value={this.state.discount}
                                onChange={this.setChange}
                            />
                        </div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.sendData}>OK</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}