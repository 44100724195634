import React, { Component, Fragment } from 'react'
import Logo from '../../images/logoWhite.svg';
import { NavLink } from "react-router-dom";
import store from '../../store';

export default class Header extends Component {
    openSidebar = () => {
        this.props.expandSidebarMobile(true);
    }

    render() {
        const { user } = this.props;

        return (
            <Fragment>
                <div className="header">
                    {
                        user.isAuthenticated ?
                            <div className=" row deactiveForMobile">
                                <div className="col-12 userArea">
                                    <span className="infoVersion">v. {this.props.versionSite}</span>
                                    |
                                    <span className="userMail">
                                        {
                                            (store.getState().user.user.email !== "" || store.getState().user.user.email !== undefined) &&
                                            store.getState().user.user.email
                                        }
                                    </span>
                                    |
                                    <span className="userRole">
                                        {
                                            (
                                                (store.getState().user.user.role !== "" && store.getState().user.user.role !== undefined) ||
                                                store.getState().user.user.role !== undefined
                                            ) &&
                                            'Ruolo: ' + store.getState().user.user.role
                                        }
                                    </span>
                                </div>
                            </div>
                            :
                            <div className=" row deactiveForMobile">
                                <div className="col-12 userArea">
                                    <img className="logo" src={Logo} alt="Logo Nanomid" />
                                    <span className="infoVersion">v. {this.props.versionSite}</span>
                                </div>
                            </div>
                    }


                    {/* MODALITÀ MOBILE */}
                    <div className="row activeForMobile">
                        <div className="col-3 text-left">
                            <button className="btn btnSidebar" onClick={this.openSidebar} >
                                <i className="fas fa-bars"></i>
                            </button>
                        </div>

                        <div className="col-9 logo text-right">
                            <NavLink to="/">
                                <img src={Logo} alt="Logo nanomid" className="logo-image" />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}