import React, { Component, Fragment } from 'react';
import axios from 'axios';

import {
    API_WORKER_ACTIVE_JOBS,
    API_WORKER_SERVICES,
    API_WORKER_TASKS,
    API_WORKER_SAVED_JOBS,
    TIME_POLLING_ACTIVE_JOBS
} from '../../config';
import './style.scss';
import Loading from '../../common/loading';
import ActiveJobs from '../components/activeJobs';
import JobTaskService from '../components/job_task_service';
import WorkerStatus from '../components/worker';
import { controlRole } from '../../common/actions/controlRole';
import { isEmpty } from '../../utility';

export default class Worker extends Component {
    timeout = 0;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            loadingActiveJob: true,
            listActiveJobs: [],

            //contiene la lista di nomi dei job attivi (usato per la lista dei savedJob, per capire se un job è gia attivo o meno)
            listActiveJobsName: [],

            loadingServices: true,
            listServices: [],

            loadingTask: true,
            listTasks: [],

            loadingJob: true,
            listJobs: [],

            stopUpdate: false
        }
    }

    componentDidMount() {
        this.timeout = setInterval(() => {
            this.getListActiveJobs();
        }, TIME_POLLING_ACTIVE_JOBS);
        if (!isEmpty(this.props.role.worker)) {
            this.callAllList();
        }
    }

    componentDidUpdate() {
        if (!isEmpty(this.props.role.worker) && !this.state.stopUpdate) {
            this.callAllList();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    /**
     * Scarica la lista dei job attivi.
     */
    getListActiveJobs = async () => {
        try {
            let response = await axios.get(API_WORKER_ACTIVE_JOBS);
            let listActiveJobsNameTemp = [];

            // riempie un array solo con i nomi dei job attivi
            // eslint-disable-next-line
            response.data.map(el => {
                listActiveJobsNameTemp.push(el.id);
            })

            this.setState({
                loadingActiveJob: false,
                listActiveJobs: response.data,
                listActiveJobsName: listActiveJobsNameTemp
            });

        } catch (result) {

        }
    }

    /**
     * Scarica la lista dei services
     */
    getListServices = async () => {
        this.setState({ loadingServices: true });
        try {
            let response = await axios.get(API_WORKER_SERVICES);
            this.setState({
                loadingServices: false,
                listServices: response.data
            });
        } catch (result) {

        }
    }

    /**
     * Scarica la lista dei task.
     */
    getListTask = async () => {
        this.setState({ loadingTask: true });
        try {
            let response = await axios.get(API_WORKER_TASKS);
            this.setState({
                loadingTask: false,
                listTasks: response.data
            });
        } catch (result) {

        }
    }

    /**
     * Scarica la lista dei jobs presenti nel DB.
     */
    getListJobs = async () => {
        this.setState({ loadingJob: true });
        try {
            let response = await axios.get(API_WORKER_SAVED_JOBS);
            this.setState({
                loadingJob: false,
                listJobs: response.data
            });
        } catch (result) {

        }
    }

    /**
     * Chiama tutte le liste.
     */
    callAllList = () => {
        const { role } = this.props;
        this.setState({ stopUpdate: true });
        if (controlRole(role.worker, "api/Worker/ActiveJobs", "GET")) this.getListActiveJobs();
        if (controlRole(role.worker, "api/Worker/SavedJobs", "GET")) this.getListJobs();
        if (controlRole(role.worker, "api/Worker/Tasks", "GET")) this.getListTask();
        if (controlRole(role.worker, "api/Worker/Services", "GET")) this.getListServices();
    }

    /**
     * Chiama
     * @param {*} id del job da attivare
     */
    activeJob = (id) => {
        let that = this;
        let thatProps = this.props;
        this.setState({ isLoading: true });

        axios.post(`${API_WORKER_ACTIVE_JOBS}/${id}`)
            .then(function () {
                that.setState({ isLoading: false });
                thatProps.successNotify('Active JOB ' + id + ' avviato!');
                that.callAllList();
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                thatProps.errorNotify(`Error ${result.response.status} su ${API_WORKER_ACTIVE_JOBS} - POST`);
            });
    }

    render() {
        const { successNotify, errorNotify, infoNotify, warningNotify, worker, removeActiveJob, role } = this.props;
        const {
            loadingActiveJob,
            listActiveJobs,
            loadingServices,
            listServices,
            loadingTask,
            listTasks,
            loadingJob,
            listJobs,
            listActiveJobsName
        } = this.state;

        return (
            <Fragment>
                {(worker.isLoading || role.isLoading || this.state.isLoading) ? <Loading />
                    :
                    <Fragment>
                        {
                            controlRole(role.worker, "api/Worker/Status", "GET") &&
                            <WorkerStatus
                                successNotify={successNotify}
                                errorNotify={errorNotify}
                                infoNotify={infoNotify}
                                warningNotify={warningNotify}
                                worker={worker}
                                role={role}
                            />
                        }

                        <div className="row contentWorker">
                            <div className="col-xl-10 col-lg-9 col-md-9 col-sm-12 col-12">
                                {
                                    controlRole(role.worker, "api/Worker/ActiveJobs", "GET") &&
                                    <ActiveJobs
                                        loadingActiveJob={loadingActiveJob}
                                        callAllList={this.callAllList}

                                        listActiveJobs={listActiveJobs}
                                        listJobs={listJobs}
                                        listTasks={listTasks}
                                        listServices={listServices}

                                        removeActiveJob={removeActiveJob}
                                        successNotify={successNotify}
                                        errorNotify={errorNotify}
                                        infoNotify={infoNotify}
                                        warningNotify={warningNotify}

                                        role={role}
                                    />
                                }
                            </div>

                            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12">
                                <JobTaskService
                                    loadingTask={loadingTask}
                                    loadingServices={loadingServices}
                                    loadingJob={loadingJob}
                                    activeJob={this.activeJob}

                                    listTasks={listTasks}
                                    listServices={listServices}
                                    listJobs={listJobs}
                                    listActiveJobsName={listActiveJobsName}

                                    successNotify={successNotify}
                                    errorNotify={errorNotify}
                                    infoNotify={infoNotify}
                                    warningNotify={warningNotify}

                                    role={role}
                                />
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}