import React, { Component } from 'react';
import './style.scss';
import { controlRole } from '../../common/actions/controlRole';
import { isNull } from '../../utility';

export default class actionMenu extends Component {

    render() {
        const { role, data, payment, hasClickModalDeletePayment, hasClickModalRefundPayment, hasClickModalDeactiveSub } = this.props;
        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        !isNull(payment.invoiceUrl) &&
                        <li>
                            <a className="dropdown-item" href={payment.invoiceUrl}>
                                <i className="fas fa-download blue" />
                                Download Fattura
                            </a>
                        </li>
                    }

                    {
                        !isNull(payment.deliveryNoteUrl) &&
                        <li>
                            <a className="dropdown-item" href={payment.deliveryNoteUrl}>
                                <i className="fas fa-download blue" />
                                Download Bolla
                            </a>
                        </li>
                    }

                    {
                        // se il prodotto è un'acquisto con status 1 (pagato) o status 6 (rimborsato parzialmente)', allora permette un rimborso sulle quantità disponibili
                        (
                            payment.paymentProvider !== 'Stark' && (payment.totalpaid !== payment.totalRefunded) &&
                            (payment.paymentStatus === 1 || payment.paymentStatus === 6 || payment.paymentStatus === 3) &&
                            controlRole(role.payment, "api/Payment/partialRefund", "POST")
                        ) &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickModalRefundPayment(payment)}>
                                <i className="fas fa-sync-alt orange"></i>
                                Rimborsa Pagamento
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.payment, "api/Payment/CancelSubscription", "POST") && payment.paymentStatus === 4 &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickModalDeactiveSub(payment._id)}>
                                <i className="fas fa-times red"></i>
                                Disattiva Sottoscrizione
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.payment, "api/Payment", "DELETE") &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickModalDeletePayment(payment._id)}>
                                <i className="far fa-trash-alt red"></i>
                                Elimina Pagamento
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}