import React, { Component } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

/**
 * Attivazione modale per inviare mail
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "userMail" : mail dell'utente a cui mandare la mail
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oggetto: '',
            testo: '',
            objectNull: false,
            contentNull: false,
        }
        this.setContentMail = this.setContentMail.bind(this);
        this.setObjectMail = this.setObjectMail.bind(this);
    }

    setObjectMail = (event) => {
        this.setState({ oggetto: event.target.value, objectNull: false });
    }

    setContentMail = (event) => {
        this.setState({ testo: event.target.value, contentNull: false });
    }

    confirmSend = () => {
        if (this.state.oggetto === "") {
            this.props.errorNotify('Inserisci l\'oggetto');
            this.setState({ objectNull: true });
        } else if (this.state.testo === "") {
            this.props.errorNotify('Inserisci il messaggio');
            this.setState({ contentNull: true });
        } else {
            this.setState({ contentNull: false, objectNull: false });
            this.props.click(this.props.userMail, this.state.oggetto, this.state.testo);
        }
    }

    render() {
        const { userMail, stateModal, toggle, titleModal } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalMail">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label text-end">Destinatario</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" defaultValue={userMail} readOnly />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput2" className="form-label text-end">Oggetto</label>
                        <input type="text" className="form-control" id="exampleFormControlInput2" value={this.state.oggetto} onChange={this.setObjectMail} disabled={this.state.objectNull} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Messaggio</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={this.state.content} onChange={this.setContentMail} disabled={this.state.contentNull}></textarea>
                    </div>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}