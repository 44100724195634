import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './style.scss';
import { preferencesActions } from '../../store/actions';
import { isEmpty, isNull } from '../../utility';

/**
 * Attivazione modale per aggiungere una vpn pass/account
 * 
 * titleModal: titolo della modale
 * click: funzione da attivare al click di OK
 * stateModal: stato della modale (booleano)
 * successNotify
 * errorNotify
 * warningNotify
 * toggle: per chiudere la modale
 * key: non viene passato per le operazioni di modale ma solo per permetter di resettare il costruttore ogni volta che apro la modale
 */
class modalVpnPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productName: this.props.vpnTempEdit.productName || '',
            createdAt: this.props.vpnTempEdit.createdAt || null,
            expireAt: this.props.vpnTempEdit.expireAt || null,
            maxAllowedPeers: this.props.vpnTempEdit.maxAllowedPeers || '',
            user: this.props.vpnTempEdit.user || '',
            paymentId: this.props.vpnTempEdit.paymentId || '',
            id: this.props.vpnTempEdit._id || '',
            description: this.props.vpnTempEdit.description || '',

            formIsValid: false,

            // variabile di controllo per verificare se sono in "Modifica Pass"
            inEdit: !isEmpty(this.props.vpnTempEdit)
        }

        this.setChange = this.setChange.bind(this);
        this.setCreatedAt = this.setCreatedAt.bind(this);
        this.setExpireAt = this.setExpireAt.bind(this);
    }

    componentDidMount() {
        this.props.getProductList();
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setCreatedAt = (newDate) => {
        this.setState({ createdAt: newDate });
    }

    setExpireAt = (newDate) => {
        this.setState({ expireAt: newDate });
    }

    /**
     * Se i dati sono corretti, prepara l'oggetto da mandare al BE
     * per aggiungere o modificare una VPN
     */
    sendVpn = () => {
        if (this.formIsValid()) {
            let data = {};
            if (this.state.id === '') {
                data = {
                    createdAt: this.state.createdAt,
                    expireAt: this.state.expireAt,
                    maxAllowedPeers: parseInt(this.state.maxAllowedPeers),
                    user: this.state.user,
                    paymentId: this.state.paymentId,
                    description: this.state.description
                }
            } else {
                data = {
                    id: this.state.id,
                    productName: this.state.productName,
                    createdAt: this.state.createdAt,
                    expireAt: this.state.expireAt,
                    maxAllowedPeers: parseInt(this.state.maxAllowedPeers),
                    user: this.state.user,
                    paymentId: this.state.paymentId,
                    description: this.state.description
                }
            }
            this.props.click(data);
        } else {
            this.props.errorNotify('Compila tutti i campi!');
        }

    }

    /**
     * Controlla se i campi sono stati inseriti tutti
     */
    formIsValid = () => {
        if (isNaN(this.state.maxAllowedPeers)) {
            this.props.errorNotify('Devi inserire un numero intero come numero massimo peers!');
            return false;
        }
        if (
            (this.state.productName === '' && this.state.inEdit) ||
            this.state.maxAllowedPeers === '' ||
            this.state.createdAt === null ||
            this.state.expireAt === null
        ) {
            this.setState({ formIsValid: true });
            return false;
        } else {
            this.setState({ formIsValid: false });
            return true;
        }
    }

    render() {
        const { stateModal, toggle, titleModal, preferences } = this.props;

        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalVpn">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <FormControl className="choose-form">
                        <div className="row">
                            {
                                this.state.inEdit &&
                                <div className="col-6">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Prodotto</InputLabel>
                                        <Select
                                            labelId="typeProductLabel"
                                            name="productName"
                                            variant='standard'
                                            value={this.state.productName}
                                            onChange={this.setChange}
                                            error={this.state.productName === '' && this.state.formIsValid}
                                        >
                                            {
                                                // eslint-disable-next-line
                                                preferences.allProducts.map((item, key) => {
                                                    if (item.type === "VPN-PASS" || item.type === "VPN-BOX") {
                                                        return (
                                                            <MenuItem key={key} value={item.name}> {item.name} </MenuItem>
                                                        )
                                                    }
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            }
                            <div className="col-6">
                                <TextField
                                    name="maxAllowedPeers"
                                    variant='standard'
                                    label="Numero Massimo Peers"
                                    value={this.state.maxAllowedPeers}
                                    onChange={this.setChange}
                                    error={(this.state.maxAllowedPeers === '' && this.state.formIsValid) || (isNaN(this.state.maxAllowedPeers))}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <TextField
                                    name="user"
                                    variant='standard'
                                    label="Associa utente"
                                    value={this.state.user}
                                    onChange={this.setChange}
                                    error={this.state.user === '' && this.state.formIsValid}
                                />
                            </div>

                            <div className="col-4">
                                <TextField
                                    name="description"
                                    variant='standard'
                                    label="Descrizione"
                                    value={this.state.description}
                                    onChange={this.setChange}
                                />
                            </div>

                            <div className="col-4">
                                <TextField
                                    name="paymentId"
                                    variant='standard'
                                    label="ID Pagamento"
                                    value={this.state.paymentId}
                                    onChange={this.setChange}
                                    error={this.state.paymentId === '' && this.state.formIsValid}
                                />
                            </div>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="row">
                                <div className="col-6">
                                    <DatePicker
                                        id="createdAtPass"
                                        variant="inline"
                                        label="A partire da"
                                        value={this.state.createdAt === null ? this.state.createdAt : dayjs(this.state.createdAt)}
                                        onChange={(newValue) => this.setCreatedAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        format="DD/MM/YYYY"
                                        helperText={(this.state.createdAt === null && this.state.formIsValid) && "Data Obbligatoria"}
                                    />
                                </div>

                                <div className="col-6">
                                    <DatePicker
                                        id="expireAtPass"
                                        variant="inline"
                                        label="Fino al"
                                        value={this.state.expireAt === null ? this.state.expireAt : dayjs(this.state.expireAt)}
                                        onChange={(newValue) => this.setExpireAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        format="DD/MM/YYYY"
                                        helperText={(this.state.expireAt === null && this.state.formIsValid) && "Data Obbligatoria"}
                                    />
                                </div>
                            </div>
                        </LocalizationProvider>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.sendVpn}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        preferences: state.preferences
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductList: (productType) => {
            dispatch(preferencesActions.getProductList(productType))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(modalVpnPass);