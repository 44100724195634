import React, { Component, Fragment } from 'react';

import './style.scss';
import Loading from '../../common/loading';
import ModalMail from '../../common/modal/modalMail';
import ModalUser from '../../common/modal/modalUser';
import Modal from '../../common/modal';
import FormDetailUser from '../components/detailUserForm';
import ContentDetailUser from '../components/detailUserContent';
import { isEmpty, withLocation, withNavigate } from '../../utility';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,

            // contiene la lista degli utenti restituita dopo la chiamata al BE
            getUserList: [],

            titleModal: '',
            contentModal: '',
            actionModal: '',
            modalMail: false,
            modal: false,
            editUser: false,
            editRole: false,
            editGroup: false,
            role: null,

            // informazioni dell'utente che apparirà nel dettaglio
            userInDetail: {
                id: '',
                registrationIp: '',
                email: '',
                lastLogOn: '',
                isRegistered: '',
                createdAt: '',
                role: '',
                isReseller: false,
                name: '',
                surname: '',
                country: '',
                group: [],
                addresses: []
            },

            billingAddress: {},
            shippingAddress: {},

            userId: this.props.location.pathname.split('/')[2] || '',

            // controlla se sia stato cliccato nuova mail
            hasClickNewMail: false,
            hasClickResetPassword: false,
            hasClickResetTfa: false,
            hasClickUpdateAddress: false,
            hasClickDeleteUser: false,
            hasClickEditUser: false,
            hasClickRole: false,
            hasClickGroup: false,
            openFilters: true
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.getUser();
            this.props.getGroupList();
            if (!isEmpty(this.props.role.role)) {
                this.props.getRoleList();
            }
        }, 500);
    }

    /**
     * Chiama il BE passando determinati filtri, in modo da ricevere tutte le informazioni sull'utente
     */
    getUser = async () => {
        let params = {
            'elementsByPage': '1',
            'filters[0][Id]': this.state.userId
        };

        this.props.getUserList(params);

        setTimeout(() => {
            !isEmpty(this.props.user.listUser) ?
                this.setState({
                    userInDetail: {
                        id: this.props.user.listUser[0].id,
                        registrationIp: this.props.user.listUser[0].registrationIp,
                        email: this.props.user.listUser[0].email,
                        lastLogOn: this.props.user.listUser[0].lastLogOn,
                        isRegistered: this.props.user.listUser[0].isRegistered,
                        createdAt: this.props.user.listUser[0].createdAt,
                        role: this.props.user.listUser[0].role,
                        isReseller: this.props.user.listUser[0].isReseller,
                        group: this.props.user.listUser[0].groups,
                        tfa: this.props.user.listUser[0].tfa,
                        addresses: this.sanitizeUserAddress(this.props.user.listUser[0].addresses)
                    },
                })
                :
                this.setState({
                    userInDetail: {
                        id: '',
                        registrationIp: '',
                        email: this.state.userId,
                        lastLogOn: '',
                        isRegistered: false,
                        createdAt: null,
                        role: '',
                        isReseller: false,
                        group: '',
                        addresses: '',
                        tfa: false
                    },
                });
        }, 500);

        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 700);
    }

    toggleModal = () => {
        this.setState({
            modalMail: false,
            modal: false,
            hasClickNewMail: false,
            hasClickResetPassword: false,
            hasClickResetTfa: false,
            hasClickUpdateAddress: false,
            hasClickRole: false,
            hasClickGroup: false,
            hasClickDeleteUser: false,
            editRole: false,
            editGroup: false,
            editUser: false
        });
    }

    openModalMail = () => {
        this.setState({
            userMail: this.state.userInDetail.id,
            hasClickNewMail: true
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalMail: !prevState.modalMail }));
        }, 100);
    }

    openModalUserInDetail = (action) => {
        if (action === "resetPassword") {
            this.setState({
                titleModal: 'Reset Password',
                contentModal: "Sei sicuro di voler resettare la password dell'utente: " + this.state.userInDetail.id + " ?",
                actionModal: action,
                hasClickResetPassword: true,
            });

        } else if (action === "resetTfa") {
            this.setState({
                titleModal: 'Resetta TFA',
                contentModal: "Sei sicuro di voler resettare il TFA dell'utente: " + this.state.userInDetail.id + " ?",
                actionModal: action,
                hasClickResetTfa: true
            });

        } else if (action === "deleteUser") {
            this.setState({
                titleModal: 'Elimina Utente',
                contentModal: "Sei sicuro di voler eliminare l'utente: " + this.state.userInDetail.id + " ?",
                actionModal: action,
                hasClickDeleteUser: true
            });

        } else if (action === "editUser") {
            this.setState({
                titleModal: this.state.userInDetail.isReseller ? "Rimuovi da Reseller" : "Rendi Reseller",
                contentModal: this.state.userInDetail.isReseller ? "Sei sicuro di voler Rimuovere " + this.state.userInDetail.id + " da Reseller ?" : "Sei sicuro di voler rendere " + this.state.userInDetail.id + " Reseller ?",
                actionModal: action,
                hasClickEditUser: true
            });

        } else if (action === "editAddress") {
            this.setState({
                titleModal: 'Modifica Indirizzi',
                contentModal: "Sei sicuro di voler eliminare l'utente: " + this.state.userInDetail.id + " ?",
                actionModal: action,
                hasClickUpdateAddress: true,
                editUser: true,
            });

        } else if (action === "assignRole") {
            this.setState({
                titleModal: 'Assegna Ruolo',
                actionModal: action,
                hasClickRole: true,
                editRole: true
            });

        } else if (action === "assignGroup") {
            this.setState({
                titleModal: 'Assegna Gruppo',
                actionModal: action,
                hasClickGroup: true,
                editGroup: true
            });
        }

        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    decideToClick = (actionModal) => {
        if (actionModal === "resetPassword") {
            this.props.resetPassword(this.state.userInDetail);
            this.toggleModal();

        } else if (actionModal === "resetTfa") {
            this.props.resetTfa(this.state.userInDetail);
            this.toggleModal();
            this.props.handleNavigation(this.props.navigate, '/users');

        } else if (actionModal === "deleteUser") {
            this.props.deleteUser(this.state.userInDetail.id);
            this.toggleModal();
            this.props.handleNavigation(this.props.navigate, '/users');


        } else if (actionModal === "editUser") {
            this.updateUser();
            this.toggleModal();

        } else if (actionModal === "editAddress") {
            this.updateAddress();

        } else if (actionModal === "assignRole") {
            this.assignRole();

        } else if (actionModal === "assignGroup") {
            this.assignGroup(this.state.userInDetail);

        }
    }

    /**
     * Invia i dati della mail al BE, e a sua volta invia la mail all'utente
     */
    sendMail = (mail, oggetto, testo) => {
        let data = {
            email: mail,
            subject: oggetto,
            body: testo
        }
        this.toggleModal();
        this.props.sendMail(data);
    }

    /**
     * Invia i dati al BE dopo aver aggiornato l'utente
     */
    assignRole = (data) => {
        this.props.assignRole(data);
        this.toggleModal();
    }

    /**
     * Invia i dati al BE dopo aver aggiornato l'utente
     */
    assignGroup = (data) => {
        this.props.assignGroup(data);
        this.toggleModal();
    }

    /**
     * Invia i dati al BE dopo aver aggiornato l'utente
     */
    updateAddress = (data, id) => {
        this.props.updateAddress(data, id);
        this.toggleModal();
    }

    /**
     * Invia i dati al BE dopo aver aggiornato l'utente
     */
    updateUser = () => {
        let data = {
            id: this.state.userInDetail.id,
            registrationIp: this.state.userInDetail.registrationIp,
            email: this.state.userInDetail.email,
            lastLogOn: this.state.userInDetail.lastLogOn,
            isRegistered: this.state.userInDetail.isRegistered,
            createdAt: this.state.userInDetail.createdAt,
            role: this.state.userInDetail.role,
            isReseller: this.state.userInDetail.isReseller ? false : true,
            tfa: this.state.userInDetail.tfa
        }

        this.props.updateUser(data);
    }

    // prendo gli indirizzi e ne estraggo solo quello predefinito di fatturazione
    sanitizeUserAddress = (addresses) => {
        let tempData = {};
        let tempData2 = {};
        if (!isEmpty(addresses)) {
            // eslint-disable-next-line
            addresses.map(el => {
                if ((el.isShipping === false && el.isFavorite === true)) {
                    tempData = el;
                }

                if (el.isShipping === true && el.isFavorite === true) {
                    tempData2 = el;
                }
            })

            this.setState({
                billingAddress: tempData,
                shippingAddress: tempData2
            });

        } else
            return [];
    }

    render() {
        const { role, group } = this.props

        return (
            <Fragment>
                <div className="row">
                    <div className="col-12 userInDetail">

                        {(this.state.isLoading || this.state.userId === '' || group.isLoading || role.isLoading) && <Loading />}

                        <div className="contentFilterSearch">
                            <FormDetailUser
                                handleNavigation={this.props.handleNavigation}
                                openModalMail={this.openModalMail}
                                openModalUserInDetail={this.openModalUserInDetail}
                                userInDetail={this.state.userInDetail}
                                billingAddress={this.state.billingAddress}

                                /**
                                * Si trova anche in userInDetail, però questo campo nello state viene popolato prima che la pagina venga caricata.
                                * Quindi, per evitare problemi (500 o invio senza filtri) con le chiamate al BE, mando anche questa props.
                                */
                                userId={this.state.userId}

                                role={role}
                                user={this.props.user}
                            />
                        </div>

                        <div className="contentUser">
                            <ContentDetailUser
                                userInDetail={this.state.userInDetail}
                                setIdPaymentForDetail={this.props.setIdPaymentForDetail}
                                setIdVpnPassForDetail={this.props.setIdVpnPassForDetail}
                                setKeyVpnPassForDetail={this.props.setKeyVpnPassForDetail}
                                setIdPlayerForDetail={this.props.setIdPlayerForDetail}
                                removeVpnPass={this.props.removeVpnPass}
                                handleNavigation={this.props.handleNavigation}

                                // /**
                                //  * Si trova anche in userInDetail, però questo campo nello state viene popolato prima che la pagina venga caricata.
                                //  * Quindi, per evitare problemi (500 o invio senza filtri) con le chiamate al BE, mando anche questa props.
                                //  *
                                userId={this.state.userId}

                                user={this.props.user}
                                role={this.props.role}

                                // chiamata lista dei prodotti su cui può essere creata una PROMO
                                productList={this.props.getProductList}

                                // chiamata per la get delle PROMO
                                getPromoList={this.props.getPromoList}

                                // chiamata per la get delle LICENZE
                                getLicenceList={this.props.getLicenceList}

                                // chiamata per la get dei pagamenti
                                getPaymentList={this.props.getPaymentList}
                                payment={this.props.payment}
                                deletePayment={this.props.deletePayment}
                                deactiveSub={this.props.deactiveSub}
                                refundPayment={this.props.refundPayment}

                                // variabili passate per avere la lista dei gruppi disponibili e la lista dei prodotti
                                group={this.props.group}
                                preferences={this.props.preferences}
                                promo={this.props.promo}

                                sendNewPromo={this.props.sendNewPromo}
                                sendEditPromo={this.props.sendEditPromo}
                                deletePromo={this.props.deletePromo}

                                errorNotify={this.props.errorNotify}
                                successNotify={this.props.successNotify}
                                warningNotify={this.props.warningNotify}
                                infoNotify={this.props.infoNotify}
                            />
                        </div>
                    </div>
                </div>

                {
                    this.state.hasClickNewMail &&
                    <ModalMail
                        titleModal="Invio Mail"
                        userMail={this.state.userInDetail.id}
                        successNotify={this.props.successNotify}
                        errorNotify={this.props.errorNotify}
                        warningNotify={this.props.warningNotify}
                        click={this.sendMail}
                        toggle={this.toggleModal}
                        stateModal={this.state.modalMail}
                        key={this.state.userInDetail.id}
                    />
                }

                {
                    (this.state.hasClickResetPassword || this.state.hasClickResetTfa || this.state.hasClickDeleteUser || this.state.hasClickEditUser || this.state.hasClickEditUser) &&
                    <Modal
                        titleModal={this.state.titleModal}
                        contentModal={this.state.contentModal}

                        click={() => this.decideToClick(this.state.actionModal)}

                        toggle={this.toggleModal}
                        stateModal={this.state.modal}
                        key={this.state.userInDetail.id}
                    />
                }

                {

                    (this.state.hasClickUpdateAddress || this.state.hasClickRole || this.state.hasClickGroup) &&
                    <ModalUser
                        titleModal={this.state.titleModal}

                        click={
                            this.state.hasClickUpdateAddress ? this.updateAddress :
                                this.state.hasClickRole ? this.assignRole :
                                    this.state.hasClickGroup ? this.assignGroup :
                                        ''
                        }

                        toggle={this.toggleModal}
                        stateModal={this.state.modal}

                        successNotify={this.props.successNotify}
                        errorNotify={this.props.errorNotify}
                        warningNotify={this.props.warningNotify}

                        email={this.state.userInDetail.id}
                        isReseller={this.state.userInDetail.isReseller}
                        editUser={this.state.editUser}
                        editRole={this.state.editRole}
                        editGroup={this.state.editGroup}
                        groupUser={this.state.userInDetail.group}
                        billingAddress={this.state.billingAddress}
                        shippingAddress={this.state.shippingAddress}

                        key={this.state.userMail}
                        group={group}
                        role={this.state.userInDetail.role}
                        roleList={this.props.role.roleList}
                    />
                }
            </Fragment>
        )
    }
}

export default withNavigate(withLocation(Users));