import React, { Component, Fragment } from 'react';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@mui/material';
import moment from 'moment';
import { connect } from 'react-redux';

import { notifyActions } from '../../store/actions';
import './style.scss';
import MyTooltip from '../../common/tooltip';
import SelectorCopy from '../../common/selectorCopy';
import { isEmpty, isNull } from 'lodash';
import { returnNationality } from '../../common/actions/returnNationality';
import { withNavigate } from '../../utility';

class expanableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // dati della promo associata
            webUser: this.props.data.webUser,
            toBuy: this.props.data.toBuy,
            id: this.props.data._id,
            startAt: this.formatData(this.props.data.startAt),
            endAt: this.props.data.endAt === null ? 'Illimitata' : this.formatData(this.props.data.endAt),
            productNames: this.props.data.productNames,
            discount: this.props.data.discount === null ? null : (this.props.data.discount * 100) + '%',
            discountValue: this.props.data.discountValue,
            priceValue: this.props.data.priceValue,
            description: this.props.data.description,
            groups: this.props.data.groups,
            countries: this.props.data.countries === null ? 'Nessun Paese Associato' : this.props.data.countries,
            startItems: this.props.data.startItems === null ? this.props.data.totalUses + "/ Illimitato" : this.props.data.totalUses + "/" + this.props.data.startItems,
            maxItems: this.props.data.maxItems,
            totalUses: this.props.data.totalUses,
            promoByCountry: this.props.data.promoByCountry,
            isReseller: this.props.data.isReseller,
            paymentId: this.props.data.paymentId
        }
    }

    /**
     * Vai ai dettagli sull'utente selezionato
     */
    goToUser = () => {
        this.props.handleNavigation(this.props.navigate, `/userInDetail/${this.state.webUser}`);
    }

    /**
     * Dato un id, vai alla schermata dei pagamenti portando id pagamento nei filtri
     */
    goToPayment = () => {
        this.props.setIdPaymentForDetail(this.state.paymentId);
        this.props.handleNavigation(this.props.navigate, `/payments`);
    }

    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    render() {

        return (
            <div className="tabExpanablePlayer">
                <FormControl>
                    <div className="row">
                        <div className="col-5">
                            <TextField
                                value={this.state.productNames || ''}
                                label="Nome Prodotto"
                                variant='standard'
                            />
                        </div>
                        {
                            !this.state.isReseller &&
                            <Fragment>
                                <div className="col-5">
                                    <FormControl className='customSelect'>
                                        <InputLabel htmlFor="id-user">Utente associato</InputLabel>
                                        <Input
                                            id="id-user"
                                            value={this.state.webUser}
                                            readOnly
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {
                                                        !isEmpty(this.state.webUser) &&
                                                        <Fragment>
                                                            <SelectorCopy
                                                                item={this.state.webUser}
                                                                infoNotify={this.props.infoNotify}
                                                                noLabel
                                                            />
                                                            <span className="clickForUser" onClick={this.goToUser}>
                                                                <MyTooltip
                                                                    title="Vai a questo utente"
                                                                    position="top"
                                                                    content={<i className="fas fa-share-square"></i>}
                                                                />
                                                            </span>
                                                        </Fragment>
                                                    }
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </Fragment>
                        }

                        {
                            this.state.isReseller &&
                            <div className="col-5">
                                <TextField
                                    value={this.state.isReseller || ''}
                                    label="Promo per Reseller"
                                    variant='standard'
                                />
                            </div>
                        }

                        {
                            this.state.discount !== null &&
                            <div className="col-2">
                                <TextField
                                    value={this.state.discount || ''}
                                    label="Percentuale di Sconto"
                                    variant='standard'
                                />
                            </div>
                        }

                        {
                            this.state.discountValue !== null &&
                            <div className="col-2">
                                <TextField
                                    value={this.state.discountValue || ''}
                                    label="Sconto Intero"
                                    variant='standard'
                                />
                            </div>
                        }

                        {
                            this.state.priceValue !== null &&
                            <div className="col-2">
                                <TextField
                                    value={this.state.priceValue || ''}
                                    label="Prezzo Fisso"
                                    variant='standard'
                                />
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.id || ''}
                                label="ID Promo"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.description || ''}
                                label="Descrizione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.countries || ''}
                                label="Paesi associati"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.startAt || ''}
                                label="Data Inizio"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.endAt || ''}
                                label="Data Fine"
                                variant='standard'
                            />
                        </div>

                        <div className="col-2">
                            <TextField
                                value={this.state.totalUses || ''}
                                label="Utilizzi Effettuati"
                                variant='standard'
                            />
                        </div>

                        <div className="col-2">
                            <TextField
                                value={(this.state.maxItems) === 0 ? "0" : this.state.maxItems}
                                label="Utilizzi Rimanenti"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-4'>
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-user">ID Pagamento</InputLabel>
                                <Input
                                    id="id-payment"
                                    value={this.state.paymentId}
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {
                                                !isEmpty(this.state.paymentId) &&
                                                <Fragment>
                                                    <SelectorCopy
                                                        item={this.state.paymentId}
                                                        infoNotify={this.props.infoNotify}
                                                        noLabel
                                                    />
                                                    <span className="clickForUser" onClick={this.goToPayment}>
                                                        <MyTooltip
                                                            title="Vai a questo pagamento"
                                                            position="top"
                                                            content={<i className="fas fa-share-square"></i>}
                                                        />
                                                    </span>
                                                </Fragment>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    {
                        (!isEmpty(this.state.promoByCountry) || !isNull(this.state.promoByCountry)) &&
                        <div className="row" style={{ padding: '1.5vh' }}>
                            <div className="col-12">
                                <h5><b>{this.state.toBuy === null ? "Coupon usato nei seguenti paesi" : "Promo usata nei seguenti paesi"}</b></h5>
                                {
                                    this.state.promoByCountry.map(el => {
                                        return (
                                            el.country !== null &&
                                            <Fragment key={el}>
                                                {
                                                    el.count !== 0 &&
                                                    <div className="col-3">
                                                        <span>{returnNationality(el.country)}:</span>
                                                        <span>{el.count}</span>
                                                    </div>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        !isEmpty(this.state.groups) &&
                        <div className="row" style={{ padding: '1.5vh' }}>
                            <div className="col-12">
                                <h5><b>Gruppi di appartenenza della promo:</b></h5>
                                {
                                    this.state.groups.map(el => {
                                        return (
                                            <span key={el}>
                                                {`${el} - `}
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </FormControl>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player,
        promo: state.promo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        },
    };
};

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(expanableComponent));