import React, { Component } from 'react';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@mui/material';
import moment from 'moment';

import '../pages/style.scss';
import SelectorCopy from '../../common/selectorCopy';

export default class expanableComponentPass extends Component {

    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    render() {
        const {
            createdAt,
            expireAt,
            maxAllowedPeers,
            productName,
            _id
        } = this.props.data;

        return (
            <div className="tabExpanablePass">
                <FormControl>
                    <div className="row">
                        <div className="col-6">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-pass">ID Pass</InputLabel>
                                <Input
                                    id="id-pass"
                                    value={_id}
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={_id}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={this.formatData(createdAt)}
                                label="Data Creazione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                value={productName}
                                label="Nome Prodotto"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={this.formatData(expireAt)}
                                label="Data Scadenza"
                                variant='standard'
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                value={maxAllowedPeers}
                                label="Numero Max Peers"
                                variant='standard'
                            />
                        </div>
                    </div>
                </FormControl>
            </div>
        )
    }
}