import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';

import CSVExport from '../../../common/csvExport';
import Date from '../../../common/date';
import Loading from '../../../common/loading';
import ExpanablePayment from './tabExpanablePayment';
import SelectorCopy from '../../../common/selectorCopy';
import IconLicence from '../../../common/iconLicence';
import ActionMenuPayments from './actionMenuPayments';
import ModalRefundPayment from '../../../common/modal/modalRefundPayment';
import Modal from '../../../common/modal';
import { isNull } from '../../../utility';

export default class tabPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            headersCSV: [],
            dataCSV: [],
            // quale campo ordinare
            fieldOrder: 'CreatedAt',

            // tipo di ordinamento
            typeOrder: 'desc',

            modalDeletePayment: false,
            modalDeactiveSub: false,
            modalRefundPayment: false,

            // info usate per le informazioni del pagamento
            paymentId: '',
            deviceId: '',
            paymentTemp: {},

            hasClickModalDeletePayment: false,
            hasClickModalDeactiveSub: false,
            hasClickModalRefundPayment: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.getListPayment();
        }, 500);
    }

    /**
     * La funzione prende in input l'id dell'utente selezionato (input preso in automatico dallo stato), 
     * e verifica i player associati ad esso
     * 
     */
    getListPayment = async () => {
        let params = {
            'elementsByPage': '1000',
            'page': 1,
            'sortField': this.state.fieldOrder,
            'sortType': this.state.typeOrder,
            'userFilters[0][Email]': this.props.userId,
        };

        this.props.getPaymentList(params);

        this.setState({ isLoading: false });
    }

    /**
    * Quando l'utente clicca per eliminare un pagamento.
    */
    hasClickModalDeletePayment = (id) => {
        this.setState({
            hasClickModalDeletePayment: true,
            paymentId: id
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalDeletePayment: !prevState.modalDeletePayment }));
        }, 200);
    }

    /**
    * Quando l'utente clicca per disattivare una sottoscrizione
    */
    hasClickModalDeactiveSub = (id) => {
        this.setState({
            hasClickModalDeactiveSub: true,
            paymentId: id
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalDeactiveSub: !prevState.modalDeactiveSub }));
        }, 200);
    }

    /**
     * Quando l'utente clicca per eliminare un pagamento.
     */
    hasClickModalRefundPayment = (data) => {
        this.setState({
            hasClickModalRefundPayment: true,
            paymentTemp: data
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalRefundPayment: !prevState.modalRefundPayment }));
        }, 200);
    }

    toggleModal = () => {
        this.setState({
            hasClickModalDeletePayment: false,
            hasClickModalDeactiveSub: false,
            hasClickModalRefundPayment: false,
            modalDeletePayment: false,
            modalDeactiveSub: false,
            modalRefundPayment: false,
            deviceId: '',
            paymentId: '',
            paymentTemp: {}
        });
    }

    render() {
        const { payment, role, errorNotify, successNotify, preferences, refundPayment } = this.props;
        const columns = [
            {
                name: '',
                selector: row =>
                    // Pagato
                    row.paymentStatus === 1 ?
                        <span className="iconLifetime">
                            <i className="far fa-thumbs-up"></i>
                        </span>
                        :
                        // Rimborsato
                        row.paymentStatus === 3 ?
                            <span className="iconRefund">
                                <i className="fas fa-undo-alt"></i>
                            </span>
                            :
                            // Rimborso Parziale
                            row.paymentStatus === 6 ?
                                <span className="iconPartialRefund">
                                    <i className="fas fa-undo-alt"></i>
                                </span>
                                :
                                // Cancellato / Respinto
                                row.paymentStatus === -1 ?
                                    <span className="iconTrial">
                                        <i className="far fa-thumbs-down"></i>
                                    </span>
                                    :
                                    // Non confermato / In attesa
                                    row.paymentStatus === 2 ?
                                        <span className="iconWait">
                                            <i className="fas fa-clock"></i>
                                        </span>
                                        :
                                        // Non Pagato / Errore
                                        row.paymentStatus === 0 ?
                                            <span className="iconTrial">
                                                <i className="fas fa-times"></i>
                                            </span>
                                            :
                                            // Sottoscrizione Attiva
                                            row.paymentStatus === 4 ?
                                                <span className="iconSub">
                                                    <i className="far fa-thumbs-up"></i>
                                                </span>
                                                :
                                                // Sottoscrizione Annullata
                                                row.paymentStatus === 5 ?
                                                    <span className="iconNotSub">
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                    :
                                                    '',
                width: '80px',
                center: true
            },
            {
                name: 'ID Pagamento',
                selector: row => <SelectorCopy item={row._id} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'Utente',
                selector: row => row.invoiceAddress.name + ' ' + row.invoiceAddress.surname
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />
            },
            {
                name: 'Numero Fattura',
                selector: row => isNull(row.invoiceUrl) ? "Non esiste fattura" : row.invoiceNumber === row._id ? 'F-Promo' : row.invoiceNumber
            },
            {
                selector: row =>
                    <ActionMenuPayments
                        hasClickModalRefundPayment={this.hasClickModalRefundPayment}
                        hasClickModalDeletePayment={this.hasClickModalDeletePayment}
                        hasClickModalDeactiveSub={this.hasClickModalDeactiveSub}
                        data={payment.listPayments}
                        paymentTemp={row}
                        role={role}
                    />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {this.state.isLoading && <Loading />}
                <div className="row title">
                    <div className="col-12 col-sm-12 col-md-6 col-xl-6">
                        <h2>Numero Pagamenti: <span className="badge text-bg-secondary">{payment.totalPayments}</span></h2>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-xl-6 text-end">
                        {
                            payment.listPayments.length !== 0 &&
                            <CSVExport
                                data={payment.listPayments}
                                label="Scarica la lista di tutti i pagamenti"
                                filename="payments-list.csv"
                            />
                        }
                    </div>
                </div>

                <IconLicence legend forPayment />

                <hr />

                <DataTable
                    columns={columns}
                    data={payment.listPayments}
                    customStyles={customStyles}
                    noHeader
                    striped
                    highlightOnHover
                    pointerOnHover
                    pagination
                    paginationPerPage={30}

                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent={ExpanablePayment}
                    expandableRowsComponentProps={{
                        "refreshList": this.getListPayment,
                        "errorNotify": this.props.errorNotify,
                        "successNotify": this.props.successNotify,
                        "infoNotify": this.props.infoNotify,
                        "setIdVpnPassForDetail": this.props.setIdVpnPassForDetail,
                        "setIdPlayerForDetail": this.props.setIdPlayerForDetail,
                        "handleNavigation": this.props.handleNavigation,
                    }}
                />

                {
                    this.state.hasClickModalRefundPayment &&
                    <ModalRefundPayment
                        titleModal="Rimborsa un pagamento"
                        toggle={this.toggleModal}
                        stateModal={this.state.modalRefundPayment}
                        dataPayment={this.state.paymentTemp}

                        refundPayment={refundPayment}
                        successNotify={successNotify}
                        errorNotify={errorNotify}
                        preferences={preferences}
                        payment={payment}
                    />
                }

                {
                    this.state.hasClickModalDeactiveSub &&
                    <Modal
                        click={() => { this.props.deactiveSub(this.state.paymentId); this.toggleModal(); }}
                        titleModal="Disattiva Sottoscrizione"
                        contentModal={'Sei sicuro di voler disattivare la sottoscrizione ' + this.state.paymentId + ' ?'}
                        toggle={this.toggleModal}
                        stateModal={this.state.modalDeactiveSub}
                    />
                }

                {
                    this.state.hasClickModalDeletePayment &&
                    <Modal
                        click={() => { this.props.deletePayment(this.state.paymentId); this.toggleModal(); }}
                        titleModal="Cancella pagamento"
                        contentModal={'Sei sicuro di voler cancellare il pagamento ' + this.state.paymentId + ' ?'}
                        toggle={this.toggleModal}
                        stateModal={this.state.modalDeletePayment}
                    />
                }
            </Fragment>
        )
    }
}