import React, { Component, Fragment } from 'react';

import TabPlayers from './tabDetail/tabPlayers';
import TabLicences from './tabDetail/tabLicences';
import TabPayment from './tabDetail/tabPayments';
import TabPromo from './tabDetail/tabPromo';
import TabVpnPass from './tabDetail/tabVpnPass';
import TabVpnBox from './tabDetail/tabVpnBox';
import { controlRole } from '../../common/actions/controlRole';
import { isEmpty } from '../../utility';

export default class contentDetailUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingForRoleUser: true,
            loadingForRolePlayer: true,
            loadingForRoleLicence: true,
            loadingForRolePayment: true,
            loadingForRolePromo: true,
            loadingForRolePass: true,
            loadingForRoleBox: true,

            tabActive: 'Player',
        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        this.controlAllRole();
    }

    componentDidUpdate() {
        this.controlAllRole();
    }

    controlAllRole = () => {
        const { role } = this.props;
        if (!isEmpty(role.promo)) {
            // se l'utente non ha nessun permesso per visualizzare le promo dell'utente, viene notificato
            if (controlRole(role.promo, "api/Promo", "GET")) {
                if (this.state.loadingForRolePromo) {
                    this.setState({ loadingForRolePromo: false });
                }
            }
        }

        if (!isEmpty(role.player)) {
            // se l'utente non ha nessun permesso per visualizzare i player dell'utente, viene notificato
            if (controlRole(role.player, "api/Player/GetPlayers", "GET")) {
                if (this.state.loadingForRolePlayer) {
                    this.setState({ loadingForRolePlayer: false });
                }
            }
        }

        if (!isEmpty(role.player)) {
            // se l'utente non ha nessun permesso per visualizzare i player dell'utente, viene notificato
            if (controlRole(role.player, "api/Player/GetLicences", "GET")) {
                if (this.state.loadingForRoleLicence) {
                    this.setState({ loadingForRoleLicence: false });
                }
            }
        }

        if (!isEmpty(role.payment)) {
            // se l'utente non ha nessun permesso per visualizzare i pagamenti dell'utente, viene notificato
            if (controlRole(role.payment, "api/Payment", "GET")) {
                if (this.state.loadingForRolePayment) {
                    this.setState({ loadingForRolePayment: false });
                }
            }
        }

        if (!isEmpty(role.vpnPass)) {
            // se l'utente non ha nessun permesso per visualizzare le promo dell'utente, viene notificato
            if (controlRole(role.vpnPass, "api/VpnPass", "GET")) {
                if (this.state.loadingForRolePass) {
                    this.setState({ loadingForRolePass: false });
                }
            }
        }

        if (!isEmpty(role.vpnBox)) {
            // se l'utente non ha nessun permesso per visualizzare le vpnBox dell'utente, viene notificato
            if (controlRole(role.vpnBox, "api/VpnBox", "GET")) {
                if (this.state.loadingForRoleBox) {
                    this.setState({ loadingForRoleBox: false });
                }
            }
        }
    }

    handleClick = (id) => {
        if (id === 1) this.setState({ tabActive: 'Player' });
        if (id === 2) this.setState({ tabActive: 'Licence' });
        if (id === 3) this.setState({ tabActive: 'Payments' });
        if (id === 4) this.setState({ tabActive: 'Promo' });
        if (id === 5) this.setState({ tabActive: 'Pass' });
        if (id === 6) this.setState({ tabActive: 'Box' });
    };

    render() {

        const {
            userInDetail, errorNotify, successNotify, warningNotify, infoNotify, setIdPaymentForDetail, setIdVpnPassForDetail,
            setKeyVpnPassForDetail, setIdPlayerForDetail, removeVpnPass,
            userId, role, group, preferences, productList, promo,
            sendNewPromo, sendEditPromo, deletePromo, getPromoList, getPaymentList, player, payment, deletePayment, deactiveSub, refundPayment, getLicenceList
        } = this.props;

        const items = [{
            id: 1,
            content:
                <div className={this.state.tabActive === "Player" ? "navIcon active" : "navIcon"}>
                    <i className="fas fa-play-circle"></i>
                    <p className="labelNav">Player</p>
                </div>
        },
        {
            id: 2,
            content:
                <div className={this.state.tabActive === "Licence" ? "navIcon active" : "navIcon"}>
                    <i className="fas fa-key"></i>
                    <p className="labelNav">Licenze</p>
                </div>
        },
        {
            id: 3,
            content:
                <div className={this.state.tabActive === "Payments" ? "navIcon active" : "navIcon"}>
                    <i className="fas fa-money-bill"></i>
                    <p className="labelNav">Pagamenti</p>
                </div>
        },
        {
            id: 4,
            content:
                <div className={this.state.tabActive === "Promo" ? "navIcon active" : "navIcon"}>
                    <i className="fas fa-gift"></i>
                    <p className="labelNav">Promo</p>
                </div>
        },
        {
            id: 5,
            content:
                <div className={this.state.tabActive === "Pass" ? "navIcon active" : "navIcon"}>
                    <i className="fas fa-user-shield"></i>
                    <p className="labelNav">VPN Pass</p>
                </div>
        },
        {
            id: 6,
            content:
                <div className={this.state.tabActive === "Box" ? "navIcon active" : "navIcon"}>
                    <i className="fas fa-user-lock"></i>
                    <p className="labelNav">VPN Box</p>
                </div>
        }];

        return (
            <Fragment>
                <div className='myNavTabs'>
                    {
                        items.map(item => (
                            <div key={item.id} onClick={() => this.handleClick(item.id)}>
                                {item.content}
                            </div>
                        ))
                    }
                </div>
                {
                    !this.state.loadingForRolePlayer && this.state.tabActive === "Player" &&
                    <TabPlayers
                        userInDetail={userInDetail}
                        userId={userId}
                        errorNotify={errorNotify}
                        successNotify={successNotify}
                        warningNotify={warningNotify}
                        infoNotify={infoNotify}
                        role={role}
                        handleNavigation={this.props.handleNavigation}
                    />
                }

                {
                    !this.state.loadingForRoleLicence && this.state.tabActive === "Licence" &&
                    <TabLicences
                        userInDetail={userInDetail}
                        userId={userId}
                        getLicenceList={getLicenceList}
                        errorNotify={errorNotify}
                        successNotify={successNotify}
                        warningNotify={warningNotify}
                        infoNotify={infoNotify}
                        player={player}
                    />
                }

                {
                    !this.state.loadingForRolePayment && this.state.tabActive === "Payments" &&
                    <TabPayment
                        userInDetail={userInDetail}
                        userId={userId}

                        setIdVpnPassForDetail={setIdVpnPassForDetail}
                        setIdPlayerForDetail={setIdPlayerForDetail}
                        getPaymentList={getPaymentList} // chiamata per la get dei pagamenti
                        payment={payment}
                        deletePayment={deletePayment}
                        deactiveSub={deactiveSub}
                        refundPayment={refundPayment}

                        handleNavigation={this.props.handleNavigation}
                        errorNotify={errorNotify}
                        successNotify={successNotify}
                        warningNotify={warningNotify}
                        infoNotify={infoNotify}
                        role={role}
                    />
                }

                {
                    (userInDetail.isRegistered && !this.state.loadingForRolePromo) && this.state.tabActive === "Promo" &&
                    <TabPromo
                        userInDetail={userInDetail}
                        userId={userId}

                        // chiamata lista dei prodotti su cui può essere creata una PROMO
                        productList={productList}

                        // chiamata per la get delle PROMO
                        getPromoList={getPromoList}

                        // variabili passate per avere la lista dei gruppi disponibili e la lista dei prodotti
                        group={group}
                        preferences={preferences}

                        promo={promo}

                        sendNewPromo={sendNewPromo}
                        sendEditPromo={sendEditPromo}
                        deletePromo={deletePromo}

                        errorNotify={errorNotify}
                        successNotify={successNotify}
                        warningNotify={warningNotify}
                        infoNotify={infoNotify}
                        role={role}
                    />
                }

                {
                    !this.state.loadingForRolePass && this.state.tabActive === "Pass" &&
                    <TabVpnPass
                        userInDetail={userInDetail}
                        userId={userId}
                        setIdPaymentForDetail={setIdPaymentForDetail}
                        removeVpnPass={removeVpnPass}
                        handleNavigation={this.props.handleNavigation}
                        errorNotify={errorNotify}
                        successNotify={successNotify}
                        warningNotify={warningNotify}
                        infoNotify={infoNotify}
                        role={role}
                    />
                }

                {
                    !this.state.loadingForRoleBox && this.state.tabActive === "Box" &&
                    <TabVpnBox
                        userInDetail={userInDetail}
                        userId={userId}
                        setKeyVpnPassForDetail={setKeyVpnPassForDetail}
                        handleNavigation={this.props.handleNavigation}
                        errorNotify={errorNotify}
                        successNotify={successNotify}
                        warningNotify={warningNotify}
                        infoNotify={infoNotify}
                        role={role}
                    />
                }
            </Fragment>
        )
    }
}