import React, { Component } from 'react';
import { NativeSelect, FormControl } from '@mui/material';
import { Bar } from 'react-chartjs-2';

import { months } from '../../../common/actions/months';
import Loading from '../../../common/loading/loadingInBlock';

export default class licenceOfDay extends Component {
    render() {
        const { lifetimeMonth, onChange, labelDays, valueDays, loadingGraph2 } = this.props;

        const optionsMonthLicence = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: 'Licenze al Giorno',
                    font: {
                        size: 20
                    }
                }
            },

            scales: {
                y: {
                    ticks: {
                        beginAtZero: true
                    }
                }
            }
        };

        const dataMonthLicence = {
            labels: labelDays,
            datasets: [
                {
                    label: "LifeTime",
                    backgroundColor: 'rgba(42,110,255, 0.5)',
                    data: valueDays,
                    hoverBackgroundColor: 'rgba(42,110,255, 0.5)',
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChart">
                    <FormControl className="licenceMonth">
                        <NativeSelect
                            name="lifetimeMonth"
                            value={lifetimeMonth}
                            onChange={onChange}
                        >
                            {
                                months.map((item, k) => {
                                    return (
                                        <option className="colorMonth" key={k} value={item.value}>{item.label}</option>
                                    );
                                })
                            }
                        </NativeSelect>
                    </FormControl>
                    {
                        loadingGraph2 ? <Loading /> :
                            <Bar options={optionsMonthLicence} data={dataMonthLicence} />
                    }
                </div>
            </div>
        )
    }
}