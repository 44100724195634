import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { notifyActions } from '../../store/actions';
import './style.scss';
import { isEmpty } from '../../utility';

class expanableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // restituisce tutti i metodi associati al ruolo corrispondente
            getMethodsList: this.props.data.accessibleMethods,

            methodsList: {}
        }
    }

    componentDidMount() {
        this.categorizedRoles();
    }

    /**
    * Funzione che restituiscei metodi associati ad un ruolo e li categorizza per controller 
    * (es. vengono raggruppati tutti sotto lo stesso nome, i ruoli di Vpn si trovano sotto la sezione Vpn etc.)
    */
    categorizedRoles = () => {
        let map = {};

        this.state.getMethodsList.forEach(element => {
            if (element.controller in map) {
                let list = map[element.controller];
                list.push(element);
                map[element.controller] = list;
            } else {
                let list = [];
                list.push(element);
                map[element.controller] = list;
            }
            this.setState({
                methodsList: map,
                isLoading: false,
            });
        });
    }

    render() {
        return (
            <div className="tabExpanableRole">
                {
                    !isEmpty(this.state.methodsList) &&
                    Object.keys(this.state.methodsList).map((item, key) => {
                        return (
                            <div className="row" key={key}>
                                <div className="col-12 titleGroup">
                                    <h5> {item} </h5>
                                </div>
                                {
                                    this.state.methodsList[item].map((el, key2) => {
                                        return (
                                            <Fragment key={key2}>
                                                {
                                                    el.httpVerb === 'DELETE' &&
                                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 containSwaggerDelete">
                                                        <span className="delete">{el.httpVerb}</span>
                                                        <span className="boxLine">{el.action || ''}</span>
                                                    </div>
                                                }

                                                {
                                                    el.httpVerb === 'PUT' &&
                                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 containSwaggerPut">
                                                        <span className="put">{el.httpVerb}</span>
                                                        <span className="boxLine">{el.action || ''}</span>
                                                    </div>
                                                }

                                                {
                                                    el.httpVerb === 'POST' &&
                                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 containSwaggerPost">
                                                        <span className="post">{el.httpVerb}</span>
                                                        <span className="boxLine">{el.action || ''}</span>
                                                    </div>
                                                }

                                                {
                                                    el.httpVerb === 'PATCH' &&
                                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 containSwaggerPatch">
                                                        <span className="patch">{el.httpVerb}</span>
                                                        <span className="boxLine">{el.action || ''}</span>
                                                    </div>
                                                }
                                                {
                                                    el.httpVerb === 'GET' &&
                                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 containSwaggerGet">
                                                        <span className="get">{el.httpVerb}</span>
                                                        <span className="boxLine">{el.action || ''}</span>
                                                    </div>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(expanableComponent);