import React, { Component } from 'react';
import { controlRole } from '../../../common/actions/controlRole';
import isNull from 'lodash/isNull';
import './style.scss';

export default class actionMenuPayments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentId: '',
            paymentTemp: {}
        };
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati del player
     */
    actionClick = (action) => {
        this.props.actionClick(action, this.props.promoTemp._id, this.props.promoTemp);
    }

    render() {
        const { data, role, paymentTemp, hasClickModalDeletePayment, hasClickModalRefundPayment, hasClickModalDeactiveSub } = this.props;
        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        !isNull(paymentTemp.invoiceUrl) &&
                        <li>
                            <a className="dropdown-item" href={paymentTemp.invoiceUrl}>
                                <i className="fas fa-download orange" />
                                Download Fattura
                            </a>
                        </li>
                    }

                    {
                        !isNull(paymentTemp.deliveryNoteUrl) &&
                        <li>
                            <a className="dropdown-item" href={paymentTemp.deliveryNoteUrl} >
                                <i className="fas fa-download orange" />
                                Download Bolla
                            </a>
                        </li>
                    }

                    {
                        // se il prodotto è un'acquisto con status 1 (pagato) o status 6 (rimborsato parzialmente)', allora permette un rimborso sulle quantità disponibili
                        (
                            paymentTemp.paymentProvider !== 'Stark' && (paymentTemp.totalpaid !== paymentTemp.totalRefunded) &&
                            (paymentTemp.paymentStatus === 1 || paymentTemp.paymentStatus === 6 || paymentTemp.paymentStatus === 3) &&
                            controlRole(role.payment, "api/Payment/partialRefund", "POST")
                        ) &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickModalRefundPayment(paymentTemp)}>
                                <i className="fas fa-sync-alt blue"></i>
                                Rimborsa Pagamento
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.payment, "api/Payment/CancelSubscription", "POST") && paymentTemp.paymentStatus === 4 &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickModalDeactiveSub(paymentTemp._id)}>
                                <i className="fas fa-times red"></i>
                                Disattiva Sottoscrizione
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.payment, "api/Payment", "DELETE") &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickModalDeletePayment(paymentTemp._id)}>
                                <i className="far fa-trash-alt red"></i>
                                Elimina Pagamento
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}