import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './index.scss';

import Dashboard from '../home/containerHome';
import Users from '../users/containerUsers';
import UserInDetail from '../userInDetail/containerUserInDetail';
import Players from '../players/containerPlayers';
import Licences from '../players/containerLicences';
import Playlists from '../players/containerPlaylists';
import Payments from '../payments/containerPayments';
import Promo from '../promo/containerPromo';
import Worker from '../worker/containerWorker';
import Role from '../role/containerRole';
import ChangeLog from '../changeLog/containerChangeLog';

import VpnMachine from '../vpn/containerVpnMachine';
import VpnPass from '../vpn/containerVpnPass';
import VpnPeer from '../vpn/containerVpnPeer';
import VpnBox from '../vpn/containerVpnBox';
import VpnSetting from '../vpn/containerVpnSetting';

import VpnBoxStock from '../stock/containerVpnBoxStock';

import PreferencesWL from '../preferences/containerPreferencesWhiteList';
import PreferencesIC from '../preferences/containerPreferencesIvaCountry';
import PreferencesPR from '../preferences/containerPreferencesPricing';

import Groups from '../preferences/containerPreferencesGroups';
import ErrorStatusCode from '../errors/containerErrors';

import ProSidebar from '../common/sidebar';
import Header from '../common/header';
import { setAuthorizationToken } from '../utility';
import { userActions, notifyActions, preferencesActions } from '../store/actions';

class RouterComponent extends Component {
    constructor(props) {
        super(props);
        if (localStorage.token) {
            setAuthorizationToken(localStorage.token);
            try {
                this.props.refreshToken(localStorage.token);
            } catch (e) {
                localStorage.clear();
            }
        }

        this.state = {
            expandSidebarDesktop: true,
            expandSidebarMobile: false,
            windowWidth: window.innerWidth,

            activeSidebar: false
        }

        this.toggleExpandSidebarDesktop = this.toggleExpandSidebarDesktop.bind(this);
        this.props.getVersionSite();
    }

    // Metodo per aggiornare lo stato con la larghezza della finestra
    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth
        });
    };

    // Aggiungi l'evento resize al montaggio del componente e rimuovilo allo smontaggio
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    toggleExpandSidebarDesktop = () => {
        this.setState({ expandSidebarDesktop: !this.state.expandSidebarDesktop });
    }

    toggleExpandSidebarMobile = open => {
        this.setState({ expandSidebarMobile: open });
    }

    /**
     * Controlla se è in modalita dashboard.
     * Se è in modalita dashboard restituisce true.
     */
    inDashboard = () => {
        return ((this.state.activeSidebar && this.state.expandSidebarDesktop) || (this.state.activeSidebar && !this.state.expandSidebarDesktop));
    }

    handleNavigation = (navigate, path) => {
        navigate(path);
    };

    render() {

        const { windowWidth } = this.state;
        const isMobile = windowWidth < 769 ? true : false;

        return (
            <BrowserRouter future={{ v7_relativeSplatPath: true }}>
                <div className="main">

                    {
                        (this.props.user.isAuthenticated && this.props.user.user.email !== undefined) &&

                        <ProSidebar
                            user={this.props.user}
                            role={this.props.role}
                            logout={this.props.logoutRequest}

                            toggleOpenMobile={this.toggleExpandSidebarMobile}
                            valueSidebarMobile={this.state.expandSidebarMobile}

                            versionSite={this.props.preferences.versionSite}
                        />
                    }

                    {/* Se la sidebar è attiva, allora attiva (in base se è aperta o chiusa), una determinata classe css */}
                    {/* Se è presente la schermata di login, attiva la classe all'header per espansione massima */}
                    <div
                        className={
                            (!this.props.user.isAuthenticated && this.props.user.user.email === undefined) ? "contentMain onlyLogin" :
                                this.state.expandSidebarDesktop ? "contentMain expand" : "contentMain"
                        }
                    >

                        <Header
                            expandSidebarMobile={this.toggleExpandSidebarMobile}
                            versionSite={this.props.preferences.versionSite}
                            flush={this.props.flush}
                            role={this.props.role}
                            user={this.props.user}
                        />

                        <div className="mainLayout">
                            <Routes>
                                <Route exact path="/" element={<Dashboard handleNavigation={this.handleNavigation} isMobile={isMobile} />} />
                                <Route exact path="/register" element={<Dashboard handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/login" element={<Dashboard handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/dashboard" element={<Dashboard handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/users" element={<Users handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/userInDetail/:userId" element={<UserInDetail handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/player/devices" element={<Players handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/player/licences" element={<Licences handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/player/licences/:userId" element={<Licences handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/player/playlists" element={<Playlists handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/payments" element={<Payments handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/promo/createdPromo" element={<Promo handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/promo/createdCoupon" element={<Promo handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/promo/buyCoupon" element={<Promo handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/promo/expiredPromo" element={<Promo handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/promo/expiredCoupon" element={<Promo handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/worker" element={<Worker handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/role" element={<Role handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/changeLog" element={<ChangeLog handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/vpn/machine" element={<VpnMachine handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/vpn/peer" element={<VpnPeer handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/vpn/peer/:peerId" element={<VpnPeer handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/vpn/pass" element={<VpnPass handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/vpn/pass/:passId" element={<VpnPass handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/vpn/box" element={<VpnBox handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/vpn/setting" element={<VpnSetting handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/stock/vpnBox" element={<VpnBoxStock handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/preferences/whitelist" element={<PreferencesWL handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/preferences/ivacountry" element={<PreferencesIC handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/preferences/pricing" element={<PreferencesPR handleNavigation={this.handleNavigation} />} />
                                <Route exact path="/preferences/groups" element={<Groups handleNavigation={this.handleNavigation} />} />
                                <Route exact path="*" element={<ErrorStatusCode statusCode='404' errorMsg="Error.404" errorDesc="Error.404.desc" />} />
                            </Routes>
                            <ToastContainer position='top-right' />
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        user: state.user,
        preferences: state.preferences,
        role: state.role
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        refreshToken: token => {
            dispatch(userActions.refreshToken(token));
        },
        getVersionSite: () => {
            dispatch(preferencesActions.getVersionSite());
        },
        logoutRequest: (handleNavigation) => {
            dispatch(userActions.logoutRequest(handleNavigation));
            dispatch(notifyActions.infoAction('Logout effettuato con successo!'));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);