import React, { Component, Fragment } from 'react';
import { countries } from '../../common/countries';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, InputLabel, MenuItem, Pagination, Select, Switch, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isNull } from '../../utility';

export default class formFiltersMachine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // parametri per filtri ricerca
            id: '',
            mantainer: '',
            createdStartAt: null,
            createdEndAt: null,
            updateStartAt: null,
            updateEndAt: null,
            country: '',
            os: '',
            city: '',
            model: '',
            cpuDescription: '',
            status: '',
            typeOrder: '',
            fieldOrder: '',

            // switch VPN Box e VPN Pass
            isProxy: false
        };


        this.onChange = this.onChange.bind(this);
        this.setDateCreatedStartAt = this.setDateCreatedStartAt.bind(this);
        this.setDateCreatedEndAt = this.setDateCreatedEndAt.bind(this);
        this.setDateUpdateStartAt = this.setDateUpdateStartAt.bind(this);
        this.setDateUpdateEndAt = this.setDateUpdateEndAt.bind(this);
    }

    /**
     * Cambia il valore di quello stato.
     * 
     * @param {*} event 
     */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'country' || event.target.name === 'typeOrder' || event.target.name === 'status') {
            setTimeout(() => {
                this.sendParamsForSearch();
            }, 200);
        }
    }

    /**
     * Setto il valore isProxy per filtrare per VPN Box o VPN Pass.
     * 
     * @param {*} event 
     */
    setIsProxy = (event) => {
        this.setState({
            isProxy: event.target.checked,
        });

        setTimeout(() => {
            this.sendParamsForSearch();
        }, 100);
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(24);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    // change per le date
    setDateCreatedStartAt = (newDate) => { this.setState({ createdStartAt: newDate }); }
    setDateCreatedEndAt = (newDate) => { this.setState({ createdEndAt: newDate }); }
    setDateUpdateStartAt = (newDate) => { this.setState({ updateStartAt: newDate }); }
    setDateUpdateEndAt = (newDate) => { this.setState({ updateEndAt: newDate }); }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            id: '',
            mantainer: '',
            createdStartAt: null,
            createdEndAt: null,
            updateStartAt: null,
            updateEndAt: null,
            country: '',
            os: '',
            city: '',
            model: '',
            cpuDescription: '',
            status: '',
            typeOrder: '',
            fieldOrder: '',
            isProxy: ''
        });

        setTimeout(() => {
            this.sendParamsForSearch('vuoto');
        }, 300);
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.sendParamsForSearch();
        }
    }

    /**
     * Invia i parametri filtro per la ricerca.
     * Se il parametro vuoto è presente, invia stringhe vuote per la ricerca.
     * 
     * @param {*} label se è vuoto, manda parametri vuoti
     */
    sendParamsForSearch = (label) => {
        if (label === 'vuoto') {
            this.props.setParamsForSearch({
                id: '',
                mantainer: '',
                createdStartAt: '',
                createdEndAt: '',
                updateStartAt: '',
                updateEndAt: '',
                country: '',
                os: '',
                city: '',
                model: '',
                cpuDescription: '',
                status: '',
                typeOrder: '',
                fieldOrder: '',
                isProxy: ''
            });
        } else {
            this.props.setParamsForSearch({
                id: this.state.id,
                mantainer: this.state.mantainer,
                createdStartAt: this.state.createdStartAt === null ? '' : this.setHourStart(this.state.createdStartAt.$d),
                createdEndAt: this.state.createdEndAt === null ? '' : this.setHourEnd(this.state.createdEndAt.$d),
                updateStartAt: this.state.updateStartAt === null ? '' : this.setHourStart(this.state.updateStartAt.$d),
                updateEndAt: this.state.updateEndAt === null ? '' : this.setHourEnd(this.state.updateEndAt.$d),
                country: this.state.country,
                os: this.state.os,
                city: this.state.city,
                model: this.state.model,
                cpuDescription: this.state.cpuDescription,
                status: this.state.status,
                typeOrder: this.state.typeOrder,
                fieldOrder: this.state.fieldOrder,
                isProxy: this.state.isProxy === false ? 0 : 1,
            });
        }
    }

    render() {
        const {
            numberVpnMachine,
            totalPages,
            setChangePagination,
            currentNumberPage
        } = this.props;

        return (
            <Fragment>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="id"
                            variant='standard'
                            value={this.state.id}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="ID VPN Machine"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl className='customSelect'>
                            <InputLabel>Cerca per Country</InputLabel>
                            <Select
                                name="country"
                                variant='standard'
                                value={this.state.country}
                                onChange={this.onChange}
                            >
                                {
                                    countries.map((item, k) => {
                                        return (
                                            <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl className='customSelect'>
                            <InputLabel>Cerca Stato Macchina</InputLabel>
                            <Select
                                labelId="statusLabel"
                                name="status"
                                variant='standard'
                                value={this.state.status}
                                onChange={this.onChange}
                            >
                                <MenuItem value={'-2'}>Failed</MenuItem>
                                <MenuItem value={'-1'}>Suspended</MenuItem>
                                <MenuItem value={'0'}>Created</MenuItem>
                                <MenuItem value={'1'}>Installing</MenuItem>
                                <MenuItem value={'2'}>Started</MenuItem>
                                <MenuItem value={'3'}>Published</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="mantainer"
                            variant='standard'
                            value={this.state.mantainer}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Cerca per Mantainer"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.isProxy}
                                        onChange={this.setIsProxy}
                                        value="isProxy"
                                        color="primary"
                                    />
                                }

                                label={this.state.isProxy ? "Proxy" : "VPN"}
                            />
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={numberVpnMachine === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChangePagination({ target: { name: 'numberVpnMachine' } }, '10')}
                        > 10 </button>
                        <button
                            className={numberVpnMachine === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChangePagination({ target: { name: 'numberVpnMachine' } }, '25')}
                        > 25 </button>
                        <button
                            className={numberVpnMachine === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChangePagination({ target: { name: 'numberVpnMachine' } }, '50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(totalPages) || 0}
                            page={parseInt(currentNumberPage)}
                            onChange={setChangePagination}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={this.sendParamsForSearch}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            variant='standard'
                                            value={this.state.fieldOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'Id'}>ID VPN Machine</MenuItem>
                                            <MenuItem value={'Mantainer'}>Mantainer</MenuItem>
                                            <MenuItem value={'Country'}>Country</MenuItem>
                                            <MenuItem value={'City'}>City</MenuItem>
                                            <MenuItem value={'Model'}>Model</MenuItem>
                                            <MenuItem value={'Os'}>OS</MenuItem>
                                            <MenuItem value={'CreatedAtStart'}>Data creazione</MenuItem>
                                            <MenuItem value={'UpdatedAtStart'}>Data modifica</MenuItem>
                                            <MenuItem value={'Score'}>Score</MenuItem>
                                            <MenuItem value={'Tx'}>TX</MenuItem>
                                            <MenuItem value={'Rx'}>RX</MenuItem>
                                            <MenuItem value={'Memory'}>RAM</MenuItem>
                                            <MenuItem value={'Cpu'}>CPU</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''} className='customSelect'>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            variant='standard'
                                            value={this.state.typeOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            format="DD/MM/YYYY"
                                            id="createdStartAt"
                                            label="Creata dal"
                                            value={this.state.createdStartAt}
                                            onChange={(newValue) => this.setDateCreatedStartAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            id="createdEndAt"
                                            label="Fino a"
                                            value={this.state.createdEndAt}
                                            onChange={(newValue) => this.setDateCreatedEndAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            format="DD/MM/YYYY"
                                            id="updateStartAt"
                                            label="Modificata dal"
                                            value={this.state.updateStartAt}
                                            onChange={(newValue) => this.setDateUpdateStartAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            id="updateEndAt"
                                            label="Fino a"
                                            value={this.state.updateEndAt}
                                            onChange={(newValue) => this.setDateUpdateEndAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="city"
                                        variant='standard'
                                        value={this.state.city}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Cerca per Città"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="os"
                                        variant='standard'
                                        value={this.state.os}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Cerca per OS"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="cpuDescription"
                                        variant='standard'
                                        value={this.state.cpuDescription}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Cerca Descrizione CPU"
                                    />
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Fragment>
        )
    }
}