import React, { Component, Fragment } from 'react';

import IconLicence from '../../common/iconLicence';
import Date from '../../common/date';

export default class detailPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaymentClicked: this.props.isPaymentClicked,
            countPayment: this.props.countPayment,
            listPayment: this.props.listPayment.length > 4 ? this.props.listPayment.slice(this.props.listPayment.length - 4, this.props.listPayment.legend) : this.props.listPayment
        }
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.isPaymentClicked && this.state.countPayment !== 0) &&
                    <Fragment>
                        <div className="row">
                            <div className="col text-center">
                                <h2> Pagamenti associati: <span className="badge text-bg-secondary">{this.state.countPayment}</span></h2>
                            </div>
                        </div>

                        <br />
                        <IconLicence legend forPayment />
                        <br />

                        <div className="row title">
                            <div className="col">
                                Effettuato il
                            </div>
                            <div className="col">
                                Confermato il
                            </div>
                            <div className="col">
                                Provider / status
                            </div>
                            <div className="col">
                                N° Prodotti
                            </div>
                            <div className="col">
                                Fattura
                            </div>
                        </div>
                    </Fragment>
                }
                <div className="blockHeight">
                    {
                        (this.state.isPaymentClicked) &&
                        this.state.listPayment.map((item, k) => {
                            return (
                                <div className="row subList payment" key={k}>
                                    <div className="col">
                                        <Date format="L" date={item.createdAt} />
                                    </div>
                                    <div className="col">
                                        <Date format="L" date={item.issuedAt} />
                                    </div>
                                    <div className="col">
                                        <span className="provider"> {item.paymentProvider} </span>
                                        {item.paymentStatus === -1 &&
                                            <span className="iconTrial">
                                                <i className="far fa-thumbs-down"></i>
                                            </span>}
                                        {item.paymentStatus === 1 &&
                                            <span className="iconLifetime">
                                                <i className="far fa-thumbs-up"></i>
                                            </span>}
                                        {(item.paymentStatus === 2 || item.paymentStatus === 0) &&
                                            <span className="iconWait">
                                                <i className="fas fa-clock"></i>
                                            </span>}
                                        {item.paymentStatus === 3 &&
                                            <span className="iconRefund">
                                                <i className="fas fa-undo-alt"></i>
                                            </span>}
                                        {item.paymentStatus === 6 &&
                                            <span className="iconPartialRefund">
                                                <i className="fas fa-undo-alt"></i>
                                            </span>}
                                        {item.paymentStatus === 4 &&
                                            <span className="iconSub">
                                                <i className="far fa-thumps-up"></i>
                                            </span>}
                                        {item.paymentStatus === 5 &&
                                            <span className="iconNotSub">
                                                <i className="fas fa-times"></i>
                                            </span>}
                                    </div>
                                    <div className="col">
                                        {item.products.length}
                                    </div>
                                    <div className="col">
                                        {
                                            item._id === item.invoiceNumber ?
                                                'Non presente'
                                                :
                                                <a target="_blank" href={item.invoiceURL} rel="noopener noreferrer" >
                                                    {item.invoiceNumber}
                                                </a>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    (this.state.isPaymentClicked && this.state.countPayment === 0) && <div> Nessun pagamento associato... </div>
                }
            </Fragment>
        )
    }
}