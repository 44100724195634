import axios from 'axios';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function setAuthorizationToken(token) {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

// funzioni per sostituire la libreria lodash 
export function isEmpty(elem) {
    if (elem === '' || elem === undefined) {
        return true;

    } else if (elem === null) {
        return true;

    } else if (elem.length === 0) {
        return true;

    } else if (Object.entries(elem).length === 0) {
        return true;

    } else return false;
}

export function isEqual(elem1, elem2) {
    if (elem1 === elem2) {
        return true;

    } else return false;
}

export function isUndefined(elem) {
    if (elem === undefined) {
        return true;

    } else return false;
}

export function isNil(elem) {
    if (elem === null) {
        return true;
    } else if (elem === undefined) {
        return true;
    } else if (elem === "") {
        return true;
    } else if (elem.length === 0) {
        return true;
    } else if (Object.entries(elem).length === 0) {
        return true;
    } else return false;
}

export function isNull(elem) {
    if (elem === null) {
        return true;

    } else return false;
}

export const withNavigate = (Component) => {
    return (props) => {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
};

export const withLocation = (Component) => {
    return (props) => {
        const location = useLocation();
        return <Component {...props} location={location} />;
    };
};
