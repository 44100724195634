import React, { Component, Fragment } from 'react';

import './style.scss';
import Loading from '../../common/loading';
import ModalGroup from '../../common/modal/modalGroup';
import { randomString } from '../../common/actions/randomString';
import { controlRole } from '../../common/actions/controlRole';
import Date from '../../common/date';
import { isEmpty, withNavigate } from '../../utility';

class partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            isLoading: false,
            loadingForRole: true,
            randomString: randomString(),

            hasClickNewGroup: false,
            hasClickUpdateGroup: false,
            hasClickDeleteGroup: false,

            groupTemp: {}
        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare i gruppi, viene rispedito alla home
        if (!isEmpty(this.props.role.user)) {
            if (!controlRole(this.props.role.user, "api/User/Group", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare i gruppi!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.props.getGroupList();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare i gruppi, viene rispedito alla home
        if (!isEmpty(this.props.role.user)) {
            if (!controlRole(this.props.role.user, "api/User/Group", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare i gruppi!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.props.getGroupList();
                }
            }
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    closeModal = () => {
        this.setState({
            modal: false,
            hasClickNewGroup: false,
            hasClickUpdateGroup: false,
            hasClickDeleteGroup: false,
            groupTemp: {}
        })
    }

    /**
     * Se clicco nuovo gruppo, attivo la modale con le relative informazioni.
     */
    hasClickNewGroup = () => {
        this.setState({
            hasClickNewGroup: true
        });
        setTimeout(() => {
            this.toggleModal();
        }, 200);
    }

    /**
     * Se clicco modifica gruppo, attivo la modale con le relative informazioni.
     */
    hasClickUpdateGroup = (el) => {
        this.setState({
            hasClickUpdateGroup: true,
            groupTemp: el
        });
        setTimeout(() => {
            this.toggleModal();
        }, 200);
    }

    /**
     * Se clicco elimina gruppo, attivo la modale con le relative informazioni.
     */
    hasClickDeleteGroup = (el) => {
        this.setState({
            hasClickDeleteGroup: true,
            groupTemp: el
        });
        setTimeout(() => {
            this.toggleModal();
        }, 200);
    }

    /**
     * Controllo se è un nuovo gruppo, da modificare o da cancellare e 
     * chiama il BE, inviando i dati che occorrono.
     * 
     * @param {*} data 
     */
    confirmSend = (data) => {
        if (this.state.hasClickNewGroup) this.props.addGroup(data);
        if (this.state.hasClickUpdateGroup) this.props.updateGroup(data);
        if (this.state.hasClickDeleteGroup) this.props.deleteGroup(data);
        this.closeModal();
    }

    render() {
        const { hasClickDeleteGroup, hasClickNewGroup, hasClickUpdateGroup } = this.state;
        const { group, role, errorNotify } = this.props;

        return (
            <Fragment>
                <div className="row justify-content-center groups">
                    <div className="col-sm-4 col-12 mt-3">
                        <h2>Gruppi</h2>
                    </div>

                    <div className="col-sm-4 col-12 mt-3 text-center">
                        {
                            controlRole(role.preferences, "api/Preferences/flush", "POST") &&
                            <button style={{ 'marginTop': '5px' }} className="btn btn-warning" onClick={this.props.flush}>
                                Flush
                            </button>
                        }
                        {
                            controlRole(role.configFlush, "api/Config", "POST") &&
                            <button style={{ 'marginTop': '5px', 'marginLeft': '15px' }} className="btn btn-danger" onClick={this.props.flushConfig}>
                                Flush CONFIG
                            </button>
                        }
                    </div>

                    <div className="col-sm-4 col-12 mt-3 text-end">
                        {
                            controlRole(role.preferences, "api/Preferences", "PUT") &&
                            <button className="btn btn-success" onClick={this.hasClickNewGroup} >
                                <i className="fas fa-plus"></i> Aggiungi Nuovo Gruppo
                            </button>
                        }
                    </div>
                </div>

                {
                    (group.isLoading || this.state.isLoading || this.state.loadingForRole) ? <Loading /> :
                        <Fragment>
                            <div className="contentGroups">
                                <div className="row title">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        Nome
                                    </div>
                                    <div className="col">
                                        Descrizione
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                        Data Creazione
                                    </div>
                                    <div className="col-2">
                                    </div>
                                </div>

                                {
                                    group.listGroup.map((el, key) => {
                                        return (
                                            <div className="row whiteRow" key={key}>
                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                    {el.name}
                                                </div>
                                                <div className="col">
                                                    {el.description}
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <Date format="L" date={el.createdAt} onlyLabel />
                                                </div>
                                                <div className="col-1">
                                                    {
                                                        controlRole(role.user, "api/User/Group/Update", "PUT") &&
                                                        <button
                                                            className="btnEdit"
                                                            onClick={() => { this.hasClickUpdateGroup(el) }}
                                                        >
                                                            <i className="far fa-edit"></i> Modifica
                                                        </button>
                                                    }
                                                </div>
                                                <div className="col-1">
                                                    {
                                                        controlRole(role.user, "api/User/Group", "DELETE") &&
                                                        <button
                                                            className="btnDelete"
                                                            onClick={() => { this.hasClickDeleteGroup(el) }}
                                                        >
                                                            <i className="fas fa-trash-alt"></i> Elimina
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Fragment>
                }

                {
                    (this.state.hasClickNewGroup || this.state.hasClickUpdateGroup || this.state.hasClickDeleteGroup) &&
                    <ModalGroup
                        titleModal={
                            hasClickNewGroup ? "Nuovo Gruppo" :
                                hasClickUpdateGroup ? "Modifica Gruppo" :
                                    hasClickDeleteGroup ? "Elimina Gruppo" : ""
                        }

                        toggle={this.closeModal}
                        stateModal={this.state.modal}

                        errorNotify={errorNotify}
                        isNewGroup={hasClickNewGroup}
                        isUpdateGroup={hasClickUpdateGroup}
                        isDeleteGroup={hasClickDeleteGroup}

                        confirmSend={this.confirmSend}

                        dataGroup={this.state.groupTemp}

                        key={this.state.randomString}
                    />
                }

            </Fragment>
        )
    }
}

export default withNavigate(partners);