import React, { Component } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

export default class modalWhiteList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ip: this.props.ipReference || '',
            keyReference: this.props.keyReference || '',
            subKey: this.props.subKeyReference || '',
            keyIp: this.props.keyIp || '',
            ipReferenceNull: false
        }
        this.setIpReference = this.setIpReference.bind(this);
    }

    setIpReference = (event) => {
        this.setState({ ip: event.target.value, ipReferenceNull: false });
    }

    confirmSend = () => {
        const { type, clickNewKey, clickEdit, clickRemoveIp, clickRemoveKey, clickNewIp } = this.props;

        if (this.state.ip === "" && type === 'newKey') {
            this.props.errorNotify('Inserisci una chiave!');
            this.setState({ ipReferenceNull: true });
        } else if (this.state.ip === "" && type !== 'removeKey' && type !== 'newKey') {
            this.props.errorNotify('Inserisci l\'indirizzo IP');
            this.setState({ ipReferenceNull: true });
        } else {
            if (type === 'newKey') {
                clickNewKey(this.state.ip);
            } else if (type === 'newIp') {
                clickNewIp(this.state.ip);
            } else if (type === 'edit') {
                clickEdit(this.state.keyReference, this.state.subKey, this.state.ip);
            } else if (type === 'removeIp') {
                clickRemoveIp(this.state.keyReference, this.state.subKey);
            } else if (type === 'removeKey') {
                clickRemoveKey(this.state.keyReference);
            }
        }
    }

    render() {
        const { stateModal, toggle, titleModal, type } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalMail">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {
                        type === 'edit' &&
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label text-end">Indirizzo IP</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" value={this.state.ip} onChange={this.setIpReference} />
                        </div>
                    }

                    {
                        type === 'newKey' &&
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput2" className="form-label text-end">Nuova Chiave</label>
                            <input type="text" className="form-control" id="exampleFormControlInput2" value={this.state.ip} onChange={this.setIpReference} />
                        </div>
                    }

                    {
                        type === 'newIp' &&
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput3" className="form-label text-end">IP</label>
                            <input type="text" className="form-control" id="exampleFormControlInput3" value={this.state.ip} onChange={this.setIpReference} />
                        </div>
                    }

                    {
                        type === 'removeIp' &&
                        <div>
                            Sei sicuro di voler rimuovere l'indirizzo <b>{this.state.ip}</b> dalla WhiteList?
                        </div>
                    }

                    {
                        type === 'removeKey' &&
                        <div>
                            Sei sicuro di voler rimuovere tutto l'oggetto key <b>{this.state.keyReference}</b>? <br />
                            <p style={{ 'color': 'red' }}>Verranno cancellati tutti gli indirizzi Ipv4 e Ipv6 associati a esso!!!</p>
                        </div>
                    }
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}