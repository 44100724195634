import React, { Component } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Alert } from '@mui/material';
import { isEmpty } from '../../utility';

export default class modalChangeDns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetDns: ''
        }
    }

    setChange = (event) => {
        this.setState({ targetDns: event.target.value });
    }

    sendData = () => {
        let dataDns = {
            title: this.props.dataDns.title === "" ? null : this.props.dataDns.title,
            source: this.props.dataDns.source === "" ? null : this.props.dataDns.source,
            createdAtStart: this.props.dataDns.createdAtStart,
            createdAtEnd: this.props.dataDns.createdAtEnd,
            deviceId: this.props.dataDns.deviceId === "" ? null : this.props.dataDns.deviceId,
            targetDns: this.state.targetDns,
        }

        if (!isEmpty(this.state.targetDns)) {
            this.props.sendData(dataDns);
            this.props.toggle();

        } else {
            this.props.errorNotify('TargetDns obbligatorio');
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true}>
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Alert severity="info" style={{ marginBottom: '2rem' }}>Inserire il nuovo dominio con questo formato: <b>dominio.com</b> <br />
                        <b>Nota:</b> omettendo l'intestazione <b>http://</b></Alert>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label text-end">Target Dns</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" value={this.state.targetDns} onChange={this.setChange} />
                    </div>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.sendData}>Cambia Dns</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}