import { isEmpty } from '../../utility';
import { userActionType } from '../actions';

const initialState = {
    isLoading: false,
    isAuthenticated: false,
    user: {},
    listUser: [],
    paramsGetUserListTemp: {},
    totalUsers: '',
    totalPages: ''
}

export default function user(state = initialState, action = {}) {
    switch (action.type) {
        case userActionType.SET_CURRENT_USER:
            if (!action.user.isAuthenticated) {
                return {
                    ...state,
                    isAuthenticated: !isEmpty(action.user),
                    user: action.user,
                    totalUsers: action.totalUsers
                }
            }; break;
        case `${userActionType.RESET_PASSWORD}_PENDING`:
        case `${userActionType.RESET_TFA}_PENDING`:
        case `${userActionType.REFRESH_TOKEN}_PENDING`:
        case `${userActionType.DELETE_USER}_PENDING`:
        case `${userActionType.SEND_MAIL}_PENDING`:
        case `${userActionType.SEND_NEW_USER}_PENDING`:
        case `${userActionType.LOGIN_REQUEST}_PENDING`:
        case `${userActionType.UPDATE_ADDRESS}_PENDING`:
        case `${userActionType.UPDATE_USER}_PENDING`:
        case `${userActionType.GET_USER}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${userActionType.RESET_PASSWORD}_FULFILLED`:
        case `${userActionType.RESET_TFA}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${userActionType.DELETE_USER}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${userActionType.SEND_MAIL}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${userActionType.SEND_NEW_USER}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${userActionType.UPDATE_ADDRESS}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${userActionType.UPDATE_USER}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${userActionType.LOGIN_REQUEST}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: !isEmpty(action.payload.user),
                user: isEmpty(action.payload.user) ? {} : action.payload.user
            }
        case userActionType.LOGOUT_REQUEST:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                user: action.payload.user
            }
        case `${userActionType.REFRESH_TOKEN}_FULFILLED`:
            return {
                ...state,
                user: action.payload.user,
                isLoading: false
            }
        case `${userActionType.GET_USER}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listUser: action.payload.listUser,
                totalUsers: action.payload.totalUsers,
                totalPages: action.payload.totalPages,
                paramsGetUserListTemp: action.payload.paramsGetUserListTemp
            }
        default:
            return state;
    }
}