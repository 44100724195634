import React, { Component } from 'react';
import { FormControl, TextField, InputAdornment, Input, IconButton } from '@mui/material';

import './style.scss';
import { ReCaptcha } from '../../common/reCaptcha';
import Loading from '../../common/loading';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../../images/logo.png';


export class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            recaptcha: '',
            otpGoogle: '',
            formIsValid: false,
            showPassword: false
        }
    }

    setChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    generateNewCaptcha = (recaptchaToken) => {
        this.setState({ recaptcha: recaptchaToken });
    }

    login = () => {
        const { errorNotify, loginRequest } = this.props;

        // Se il tasto premuto corrisponde a enter o al click del mouse
        this.formIsValid();
        this.recaptcha.execute();

        setTimeout(() => {
            if (this.state.formIsValid) {
                errorNotify('Inserire i campi mancanti!');
            } else {
                let data = {
                    email: this.state.email,
                    password: this.state.password,
                    captchaCode: this.state.recaptcha,
                    pin: this.state.otpGoogle
                };
                loginRequest(data);
            }
        }, 300);
    }

    /**
     * Controlla se i campi sono stati inseriti tutti
     */
    formIsValid = () => {
        if (this.state.email === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.password === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.otpGoogle === '') {
            this.setState({ formIsValid: true });
        } else {
            this.setState({ formIsValid: false });
            return true;
        }
    }

    changeShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    /**
     * Se viene premuto il tasto invio, invia il codice.
     * 
     * @param {*} event 
     */
    imitateClick = (event) => {
        if (event.keyCode === 13) {
            this.login();
        }
    }

    render() {
        return (
            <div className="loginContainer">
                {this.props.user.isLoading && <Loading />}

                <div className="formLogin">
                    <img src={logo} alt='err' />
                    <div className="row justify-content-center">

                        <div className="col-12 mb-3">
                            <TextField
                                name="email"
                                label="Email"
                                variant='standard'
                                value={this.state.email}
                                onChange={this.setChange}
                                error={this.state.email === '' && this.state.formIsValid}
                            />
                        </div>

                        <div className="col-12 mb-3">
                            <FormControl className='customSelect'>
                                <Input
                                    className="passwordVisibility"
                                    id="standard-adornment-password"
                                    name="password"
                                    placeholder='Insert Password'
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.password}
                                    onChange={this.setChange}
                                    error={this.state.password === '' && this.state.formIsValid}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton className="iconVisibility"
                                                aria-label="toggle password visibility"
                                                onClick={this.changeShowPassword}
                                            >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-12 mb-3">
                            <TextField
                                name="otpGoogle"
                                label="OTP"
                                variant='standard'
                                value={this.state.otpGoogle}
                                onChange={this.setChange}
                                onKeyDown={this.imitateClick}
                            />
                        </div>

                        <div className="col-12 mb-3">
                            <button className="btn btn-warning" onClick={this.login} >
                                Accedi
                            </button>
                        </div>
                    </div>
                </div>

                <ReCaptcha
                    action='LOGON'
                    verifyCallback={this.generateNewCaptcha}
                    ref={ref => this.recaptcha = ref}
                />
            </div>
        )
    }
}

export default index
