import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import Alert from '@mui/material/Alert';
import { FormGroup, FormControlLabel, Switch, FormControl, TextField, InputLabel, Input, InputAdornment, MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './style.scss';

import { countries } from '../countries';
import { sanificationArray, sanificationValueBE } from '../actions/promoFunctions';

import MultiSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { withLocation } from '../../utility';

/**
 * Attivazione modale per aggiungere / modificare promo * 
 */
class modalCouponGenerate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPromo: this.props.couponTemp._id || '',
            description: this.props.couponTemp.description || '',
            discount: (this.props.couponTemp.discount * 100) || '',
            discountValue: this.props.couponTemp.discountValue || '',
            priceValue: this.props.couponTemp.priceValue || '',
            webUsers: this.props.couponTemp.webUser || '',
            toBuy: null,
            startAt: this.props.couponTemp.startAt || null,
            endAt: this.props.couponTemp.endAt || null,
            productNames: sanificationValueBE(this.props.couponTemp.productNames, 'products'),
            qty: 1,
            code: this.props.couponTemp.code || '',
            countries: sanificationValueBE(this.props.couponTemp.countries, 'countries') || null,
            startItems: this.props.couponTemp.startItems,
            totalUses: this.props.couponTemp.totalUses || '',
            isRotten: this.props.couponTemp.isRotten,

            // array contentente i prodotti da regalare
            arrayProduct: this.props.arrayProduct ||
                [{
                    name: '',
                    qty: 0
                }],

            verify: false,

            arrayProva: [{
                name: '',
                qty: 0
            }],

            /**
             * Variabile Passata da userInDetails per capire se sto attivando il COUPON da li.
             * Se è true mi sto gestendo il COUPON da userInDetails, se è false la sto gestendo dalla sezione Promo/Coupon
             */
            isEditCouponUserInDetail: this.props.isEditCouponUserInDetail || false,

            idPromoNull: false,
            descriptionNull: false,
            discountNull: false,
            toBuyNull: false,
            startItemsNull: false,
            emailFromListNull: false,
            startAtNull: false,
            endAtNull: false,
            productNameNull: false,
            qtyNull: false,
            countriesNull: false,

            unlimitedUse: this.props.couponTemp.startItems === null ? true : false,
            unlimitedDate: this.props.couponTemp.endAt === null ? true : false,

            disableDate: false,
            disableUse: false,
            disableField: false,

            titleModal: this.props.titleModal,
            listCoupon: false,

            /**
             * Array rispettivamente di countries e prodotti da mandare al BE durante la creazine di un COUPON.
             */
            countriesFromListArray: null,
            productsFromListArray: null,

            /**
             * Se nel titolo è presente la seguente dicitura, allora attiva elementi per una nuova modale promozione.
             * Se la dicitura è diversa, setta la variabile a false.
             */
            isNewCoupon: this.props.titleModal === 'Crea Coupon Generato',
            editCoupon: this.props.titleModal === 'Modifica Coupon',

            urlSection: this.props.location.pathname.split('/')[2]
        }
    }

    componentDidMount() {
        if (!this.state.isEditCouponUserInDetail) {
            this.props.listDevice();
            this.props.productList();
        }
    }

    /**
     * onChange utilizzata nella multiSelect quando seleziono i dati.
     * Quando seleziono i dati da mandare al BE le trasformo da oggetti {label: xx, value: xx}
     * a un array di value (valore accettato dal BE).
    */
    setDataFromOptionsList = (options, name) => {
        let arrayTemp = [];

        switch (name) {
            case 'products':
                if (!isNull(options) || !isEmpty(options)) {
                    // eslint-disable-next-line
                    options.map(el => {
                        arrayTemp.push(el.value);
                    });
                }

                this.setState({
                    productsFromListArray: arrayTemp,
                    productNames: ''
                });

                break;

            case 'countries':
                if (!isNull(options) || !isEmpty(options)) {
                    // eslint-disable-next-line
                    options.map(el => {
                        arrayTemp.push(el.value);
                    });
                }

                this.setState({
                    countriesFromListArray: arrayTemp,
                    countries: null
                });

                break;

            default:
                this.setState({
                    productsFromListArray: null,
                    countriesFromListArray: null,
                    countries: null,
                    webUsers: '',
                    productNames: ''
                });
                break;
        }
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        switch (event.target.name) {
            case 'description': this.setState({ descriptionNull: false }); break;
            case 'discount': this.setState({ discountNull: false }); break;
            case 'startItems': this.setState({ startItemsNull: false }); break;
            case 'productNames': this.setState({ productNameNull: false }); break;
            default: break;
        }
    }

    setPromoTime = (newDate, name) => {
        switch (name) {
            case 'startAt':
                this.setState({
                    startAt: newDate,
                    startAtNull: false
                });
                break;

            case 'endAt':
                this.setState({
                    endAt: newDate,
                    endAtNull: false
                });
                break;

            default: break;
        }
    }

    /**
     * Setta lo stato del numero di usi dell'app in modo che sia illimitato
     */
    setUnlimitedUse = () => {
        this.setState({ unlimitedUse: !this.state.unlimitedUse })
        setTimeout(() => {
            if (this.state.unlimitedUse) {
                this.setState({
                    startItems: null,
                    disableUse: true
                });
            } else {
                this.setState({
                    startItems: '',
                    disableUse: false
                });
            }
        }, 300);
    }

    /**
     * Setta lo stato della data di fine in modo che la promo non scada mai
     */
    setUnlimitedDate = () => {
        this.setState({ unlimitedDate: !this.state.unlimitedDate });
        setTimeout(() => {
            if (this.state.unlimitedDate) {
                this.setState({
                    endAt: null,
                    disableDate: true
                });
            } else {
                this.setState({ disableDate: false });
            }
        }, 300);
    }

    setListCoupon = () => {
        this.setState({ listCoupon: !this.state.listCoupon });
    }

    /**
     * Aggiunge un prodotto nuovo all'array dei prodotti.
     */
    addProduct = () => {
        let newProduct = {
            name: '',
            qty: 0
        };

        const arrayProduct = [...this.state.arrayProduct, newProduct];
        this.setState({ arrayProduct });
    }

    /**
     * Aggiorna l'oggetto selezionato che si trova all'interno dell'array dei prodotti.
     * 
     * @param {*} k è l'indice in cui si trova l'oggetto
     * @param {*} i è l'oggetto modificato
     */
    updateItem = (k, i) => {
        let arrayProduct = [...this.state.arrayProduct];
        let item = { ...arrayProduct[k] };

        if (!this.state.checkedForce) {
            item = {
                name: i.name,
                qty: i.qty
            }
        } else {
            item = i;
        }

        arrayProduct[k] = item;
        this.setState({ arrayProduct });
    }

    /**
     * Rimuove un prodotto dall'array dei prodotti.
     */
    removeProduct = (k) => {
        this.setState(state => {
            // eslint-disable-next-line
            const arrayProduct = state.arrayProduct.filter((item, i) => k !== i);
            return { arrayProduct }
        });
    }

    verifyProduct = () => {
        // eslint-disable-next-line
        this.state.arrayProduct.map((el) => {
            if (el.name === this.state.arrayProva[0].name) {
                this.setState({ verify: true });
            } else if (el.qty === this.state.arrayProva[0].qty) {
                this.setState({ verify: true });
            } else {
                this.setState({ verify: false });
            }
        })

        setTimeout(() => {
            this.confirm();
        }, 100);
    }

    /**
     * Prima di essere mandati i dati, modifico i dati come li vuole il BE.
     * La quantità dovrà essere di tipo int.
     */
    sanitizeArray = (data) => {
        let dataTemp = [];

        if (this.state.arrayProduct[0].name === this.state.arrayProva[0].name) {
            return null;

        } else {
            // eslint-disable-next-line
            data.map(el => {
                dataTemp.push({
                    name: el.name,
                    qty: parseInt(el.qty)
                })
            })

            return dataTemp;
        }
    }

    confirm = () => {
        const { canEditCouponApi, canPostCouponApi, infoNotify, errorNotify } = this.props;

        if (this.state.idPromo === '' && !this.state.isNewCoupon) {
            this.setState({ idPromoNull: true });
            errorNotify('ID del Coupon obbligatorio');
        } else if ((isEmpty(this.state.productsFromListArray) || isNull(this.state.productsFromListArray)) && (isEmpty(this.state.productNames) || isNull(this.state.productNames))) {
            this.setState({ productNameNull: true });
            errorNotify('Prodotto al quale associare il Coupon obbligatorio');
        } else if (this.state.description === '') {
            this.setState({ descriptionNull: true });
            errorNotify('Descrizione del Coupon obbligatoria');
        } else if (this.state.startItems === '') {
            this.setState({ startItemsNull: true });
            errorNotify('Numero di volte di utilizzo del Coupon obbligatorio');
        } else if ((this.state.discount === '' || this.state.discount === null) && (this.state.discountValue === '' || this.state.discountValue === null) && (this.state.priceValue === '' || this.state.priceValue === null)) {
            this.setState({ discountNull: true });
            errorNotify('Sconto del Coupon obbligatoria');
        } else if (this.state.discount > 100) {
            this.setState({ discountNull: true });
            this.props.warningNotify('La percentuale del Coupon non puo superare i 100!');
        } else if (this.state.qty > 1000) {
            this.setState({ qtyNull: true });
            errorNotify('Il Coupon non può superare le 1000 unità!');
        } else if (this.state.qty < 1) {
            this.setState({ qtyNull: true });
            errorNotify('Il Coupon non può avere una quantità minore di 1!');
        } else if (this.state.startAt === null) {
            this.setState({ startAtNull: true });
            errorNotify('Data di inizio del Coupon obbligatoria');
        } else if (this.state.endAt === null && !this.state.unlimitedDate) {
            this.setState({ endAtNull: true });
            errorNotify('Data di fine del Coupon obbligatoria');
        } else {
            // CREAZIONE
            if (this.state.isNewCoupon) {
                // se devo creare un coupon
                let data = {
                    webUser: null,
                    description: this.state.description,
                    startAt: this.state.startAt,
                    endAt: this.state.endAt,
                    productNames: this.state.productsFromListArray,
                    discount: this.state.discount === '' ? null : (this.state.discount * 0.01).toFixed(2),
                    discountValue: this.state.discountValue === '' ? null : parseFloat(this.state.discountValue).toFixed(2),
                    priceValue: this.state.priceValue === '' ? null : parseFloat(this.state.priceValue).toFixed(2),
                    toBuy: null,
                    qty: this.state.qty,
                    code: this.state.code,
                    listCoupon: this.state.listCoupon,
                    countries: this.state.countriesFromListArray,
                    startItems: this.state.startItems,
                    totalUses: this.state.totalUses,
                    prizes: this.sanitizeArray(this.state.arrayProduct)
                };

                if (canPostCouponApi) {
                    this.props.clickNew(data, true);
                } else {
                    infoNotify('Non hai i permessi per creare un coupon!');
                }
            }
            // EDIT
            else if (this.state.editCoupon) {
                // se devo modificare un coupon
                let data = {}

                if (this.state.urlSection !== "buyCoupon") {
                    data = {
                        id: this.state.idPromo,
                        webUser: this.state.webUsers,
                        description: this.state.description,
                        startAt: this.state.startAt,
                        endAt: this.state.endAt,
                        productNames: (this.state.productNames === null || this.state.productNames === '') ? this.state.productsFromListArray : sanificationArray(this.state.productNames, 'products'),
                        discount: this.state.discount === '' ? null : (this.state.discount * 0.01).toFixed(2),
                        discountValue: this.state.discountValue === '' ? null : parseInt(this.state.discountValue),
                        priceValue: this.state.priceValue === '' ? null : parseInt(this.state.priceValue),
                        qty: this.state.qty,
                        code: this.state.code,
                        countries: (this.state.countries === null || this.state.countries === '') ? this.state.countriesFromListArray : sanificationArray(this.state.countries, 'countries'),
                        startItems: this.state.startItems,
                        totalUses: this.state.totalUses,
                        prizes: this.sanitizeArray(this.state.arrayProduct)
                    };

                } else {
                    data = {
                        id: this.state.idPromo,
                        webUser: this.state.webUsers,
                        description: this.state.description,
                        startAt: this.state.startAt,
                        endAt: this.state.endAt,
                        productNames: (this.state.productNames === null || this.state.productNames === '') ? this.state.productsFromListArray : sanificationArray(this.state.productNames, 'products'),
                        discount: this.state.discount === '' ? null : (this.state.discount * 0.01).toFixed(2),
                        discountValue: this.state.discountValue === '' ? null : parseInt(this.state.discountValue),
                        priceValue: this.state.priceValue === '' ? null : parseInt(this.state.priceValue),
                        qty: this.state.qty,
                        code: this.state.code,
                        countries: (this.state.countries === null || this.state.countries === '') ? this.state.countriesFromListArray : sanificationArray(this.state.countries, 'countries'),
                        startItems: this.state.startItems,
                        totalUses: this.state.totalUses
                    };
                }

                if (canEditCouponApi) {
                    this.props.clickEdit(data, true);
                } else {
                    infoNotify('Non hai i permessi per modificare un coupon!');
                }
            }
            this.props.toggle();
        }
    }

    render() {
        const { stateModal, toggle, titleModal, preferences } = this.props;
        const animatedComponents = makeAnimated();
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalCoupon">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {
                        // questo messaggio appare solo quando un COUPON è scaduto per notificare all'utente che modificandolo verrà riattivato con il numero di utilizzi inseriti
                        this.state.isRotten &&
                        <Alert severity="error">Attenzione! Modificando questo COUPON sarà riattivato con gli utilizzi selezionati</Alert>
                    }

                    {
                        !this.state.isNewCoupon &&
                        <FormControl>
                            <div className="row">
                                <div className="col-12">
                                    <TextField
                                        name="idPromo"
                                        label="ID Promo"
                                        variant='standard'
                                        value={this.state.idPromo}
                                        error={this.state.idPromoNull}
                                    />
                                </div>
                            </div>
                        </FormControl>
                    }

                    <FormControl>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-12 downSelect">
                                <MultiSelect
                                    placeholder="Seleziona il prodotto"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={sanificationValueBE(preferences.allProducts, 'productList')}
                                    isClearable
                                    isSearchable
                                    value={(isNull(this.state.productNames) || isEmpty(this.state.productNames)) ? undefined : this.state.productNames}
                                    name="productNames"
                                    onChange={options => this.setDataFromOptionsList(options, 'products')}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12 col-12">
                                <TextField
                                    name="code"
                                    label="Codice"
                                    variant='standard'
                                    value={this.state.code.toUpperCase()}
                                    onChange={this.setChange}
                                    disabled={this.state.isNewCoupon}
                                />
                            </div>
                        </div>
                    </FormControl>

                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-12 mb-3">
                            <TextField
                                name="description"
                                label="Descrizione"
                                variant='standard'
                                value={this.state.description}
                                onChange={this.setChange}
                                error={this.state.descriptionNull}
                            />
                        </div>

                        <div className="col-md-6 col-sm-12 col-12 mb-3">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="standard-adornment-switch1">Quanti utilizzi?</InputLabel>
                                <Input
                                    id="standard-adornment-switch1"
                                    name="startItems"
                                    type="number"
                                    value={this.state.startItems}
                                    onChange={this.setChange}
                                    error={this.state.startItemsNull}
                                    disabled={this.state.disableUse || this.state.unlimitedUse || (!this.state.isRotten && !this.state.isNewCoupon)} // il controllo con maxItems è fatto per evitare la modifica degli usi a dispozione prima della scadenza del COUPON
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton className="iconVisibility"
                                                aria-label="toggle password visibility"
                                                onClick={this.changeShowPassword}
                                            >
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.unlimitedUse}
                                                                onChange={this.setUnlimitedUse}
                                                                value="unlimitedUse"
                                                                color="primary"
                                                                disabled={(!this.state.isRotten && !this.state.isNewCoupon)} // il controllo con maxItems è fatto per evitare la modifica degli usi a dispozione prima della scadenza del COUPON
                                                            />
                                                        }
                                                        label="Illimitato"
                                                    />
                                                </FormGroup>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    <FormControl>
                        <div className="row">
                            {
                                !this.state.isEditCouponUserInDetail &&
                                <div className="col-md-6 col-sm-12 col-12 downSelect">
                                    <MultiSelect
                                        placeholder="Seleziona Paese"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        name="countries"
                                        defaultValue={
                                            this.state.editCoupon ? this.state.countries : undefined
                                        }
                                        options={countries}
                                        onChange={options => this.setDataFromOptionsList(options, 'countries')}
                                    />
                                </div>
                            }

                            <div className="col-md-2 col-sm-12 col-12">
                                <TextField
                                    name="qty"
                                    type="number"
                                    label="Quantità"
                                    variant='standard'
                                    value={this.state.qty}
                                    onChange={this.setChange}
                                    error={this.state.qtyNull}
                                />
                            </div>

                            {
                                !this.state.editCoupon &&
                                <div className="col-md-2 col-sm-12 col-12 downLabel">
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Download Coupons"
                                            control={
                                                <Switch
                                                    checked={this.state.listCoupon}
                                                    disabled={this.state.editCoupon}
                                                    onChange={this.setListCoupon}
                                                    value="listCoupon"
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            }
                        </div>
                    </FormControl>

                    <FormControl>
                        <div className='row'>
                            <div className='col-md-4 col-sm-12 col-12 mb-3'>
                                <TextField
                                    name="discount"
                                    type="number"
                                    label="Sconto (%)"
                                    variant='standard'
                                    value={this.state.discount}
                                    onChange={this.setChange}
                                    error={this.state.discountNull}
                                    disabled={this.state.discountValue !== '' || this.state.priceValue !== ''}
                                />
                            </div>

                            <div className='col-md-4 col-sm-12 col-12 mb-3'>
                                <TextField
                                    name="discountValue"
                                    type="number"
                                    label="Sconto (int)"
                                    variant='standard'
                                    value={this.state.discountValue}
                                    onChange={this.setChange}
                                    error={this.state.discountNull}
                                    disabled={this.state.discount !== '' || this.state.priceValue !== ''}
                                />
                            </div>

                            <div className='col-md-4 col-sm-12 col-12 mb-3'>
                                <TextField
                                    name="priceValue"
                                    type="number"
                                    label="Prezzo Fisso"
                                    variant='standard'
                                    value={this.state.priceValue}
                                    onChange={this.setChange}
                                    error={this.state.discountNull}
                                    disabled={this.state.discount !== '' || this.state.discountValue !== ''}
                                />
                            </div>
                        </div>
                    </FormControl>

                    <FormControl>
                        <div className="row">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="col-md-4 col-sm-12 col-12">
                                    <DatePicker
                                        id="dateStartAtPromo"
                                        variant="inline"
                                        label="Inizio Promo"
                                        value={isNull(this.state.startAt) ? this.state.startAt : dayjs(this.state.startAt)}
                                        onChange={(newValue) => this.setPromoTime(isNull(newValue) ? null : dayjs(newValue.$d), 'startAt')}
                                        format="DD/MM/YYYY"
                                    />
                                </div>

                                <div className="col-md-4 col-sm-12 col-12">
                                    <DatePicker
                                        id="dateEndAtPromo"
                                        variant="inline"
                                        label="Fine Promo"
                                        value={isNull(this.state.endAt) ? this.state.endAt : dayjs(this.state.endAt)}
                                        onChange={(newValue) => this.setPromoTime(isNull(newValue) ? null : dayjs(newValue.$d), 'endAt')}
                                        disabled={this.state.disableDate || this.state.unlimitedDate}
                                        format="DD/MM/YYYY"
                                    />
                                </div>
                                <div className="col-md-2 col-sm-12 col-12 downLabel">
                                    <FormGroup>
                                        <FormControlLabel
                                            className="positionSwitch"
                                            control={
                                                <Switch
                                                    checked={this.state.unlimitedDate}
                                                    onChange={this.setUnlimitedDate}
                                                    value="unlimitedDate"
                                                    color="primary"
                                                />
                                            }
                                            label="Illimitato"
                                        />
                                    </FormGroup>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </FormControl>
                    {
                        this.state.urlSection !== "buyCoupon" &&
                        <>
                            <div style={{ marginTop: '2rem' }}>
                                <b>Prodotti da regalare</b>
                            </div>

                            {
                                this.state.arrayProduct.map((el, key) => {
                                    return (
                                        <div key={key} className="row rowProduct" style={{ 'margin': '15px 0' }}>
                                            <div className="col-6">
                                                <FormControl className='customSelect'>
                                                    <InputLabel>Prodotto</InputLabel>
                                                    <Select
                                                        labelId="keyProductName"
                                                        name="name"
                                                        variant='standard'
                                                        key={key}
                                                        value={this.state.arrayProduct[key].name}
                                                        onChange={(event) => {
                                                            this.updateItem(key, {
                                                                name: event.target.value,
                                                                qty: this.state.arrayProduct[key].qty
                                                            })
                                                        }}
                                                    >
                                                        {
                                                            preferences.allProducts.map((item, k) => {
                                                                return (
                                                                    <MenuItem key={k} value={item.name}>{item.description}</MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    name="qty"
                                                    label="Quantità"
                                                    variant='standard'
                                                    value={this.state.arrayProduct[key].qty}
                                                    onChange={(event) => {
                                                        this.updateItem(key, {
                                                            name: this.state.arrayProduct[key].name,
                                                            qty: parseInt(event.target.value)
                                                        })
                                                    }}
                                                    type="number"
                                                />
                                            </div>
                                            <div className="col-2">
                                                <div className="iconCancel" onClick={() => { this.removeProduct(key) }}>
                                                    <i className="fas fa-times"></i>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="row">
                                <div className="col-1" style={{ 'padding': '0' }}>
                                    <div className="iconPlus" onClick={this.addProduct}>
                                        <i className="fas fa-plus"></i>
                                    </div>
                                </div>
                                <div className="col" style={{ 'paddingLeft': '0' }}>
                                    <hr className="hrForPlus" />
                                </div>
                            </div>
                        </>
                    }
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withLocation(modalCouponGenerate);