import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { API_VPN_BOX_LOGS } from '../../config';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import Loading from '../../common/loading';
import './style.scss';

/**
 * Attivazione modale per la diagnostica della vpnBox
 * 
 */
export default class modalDiagnostics extends Component {
    timeoutDiagnostics = 0;
    constructor(props) {
        super(props);
        this.state = {
            getVpnBoxLogs: '',
            isLoading: true,
        }
    }

    componentDidMount() {
        this.getVpnBoxLogs(this.props.vpnBox.id);
        this.startPolling();
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    startPolling = () => {
        this.timeoutDiagnostics = setInterval(() => {
            this.getVpnBoxLogs(this.props.vpnBox.id);
        }, 5000);
    }

    stopPolling = () => {
        clearInterval(this.timeoutDiagnostics);
    }

    formatterData = (message) => {
        if (!isNull(message) && !isEmpty(message)) {
            document.getElementById("messageList").innerHTML = '';

            let sanitizeMessage = message.split('\n');
            // eslint-disable-next-line
            sanitizeMessage.map((el) => {
                const li = document.createElement("li");
                if (el !== '') {
                    li.textContent = `${el}`;
                    document.getElementById("messageList").appendChild(li);
                }
            });
        }
    }

    getVpnBoxLogs = (id) => {
        axios.get(`${API_VPN_BOX_LOGS}/${id}`)
            .then(response => {
                this.setState({
                    getVpnBoxLogs: response.data,
                    isLoading: false
                });
                this.stopPolling();
                setTimeout(() => {
                    this.formatterData(response.data)
                }, 200);
            })
            .catch(result => {
                if (result && result.response && result.response.status !== 404) {
                    this.props.errorNotify('Errore ' + result.response.status + ' su chiamata Vpn Box Logs');
                }
            });
    }

    /**
     * Crea un TXT con tutti i log della box ed effettua il download di un TXT.
     */
    downloadTXT = () => {
        const downloadUrl = window.URL.createObjectURL(new Blob([this.state.getVpnBoxLogs]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `Log-Box-${this.props.vpnBox.id}.txt`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;

        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalDiagnosticsVpn">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <div className="row">
                        <div className="col-12 p-relative">
                            {
                                this.state.isLoading ? <Loading />
                                    :
                                    <Fragment>
                                        <div className="text-center" style={{ 'marginBottom': '20px' }}>
                                            <button className="btn btn-warning" onClick={this.downloadTXT}                                                >
                                                Scarica TXT
                                            </button>
                                        </div>
                                        <ul id="messageList" />
                                    </Fragment>
                            }
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}