import React, { Component } from 'react';
import { controlRole } from '../../../common/actions/controlRole';
import './style.scss';

export default class actionMenuPromo extends Component {
    /**
     * Invia all'azione del click, il tipo di azione e i dati del player
     */
    actionClick = (action) => {
        this.props.actionClick(action, this.props.promoTemp._id, this.props.promoTemp);
    }

    render() {
        const { data, role } = this.props;
        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        !this.props.promoTemp.isCoupon &&
                        controlRole(role.promo, "api/Promo", "PUT") &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('editPromo'); }}>
                                <i className="far fa-edit orange"></i>
                                Modifica Promo
                            </div>
                        </li>
                    }

                    {
                        this.props.promoTemp.isCoupon &&
                        controlRole(role.promo, "api/Promo/Coupon", "PUT") &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('editCoupon'); }}>
                                <i className="far fa-edit orange"></i>
                                Modifica Coupon
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.promo, "api/Promo/DeleteMany", "DELETE") &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('deletePromo'); }}>
                                <i className="far fa-trash-alt red"></i>
                                Elimina Promo
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}