import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import Loading from '../../../common/loading/loadingInBlock';
import { isNull } from '../../../utility';

export default class platforms extends Component {
    render() {
        const { trialPlatforms, lifetimePlatforms, playerIssuedAtStart, playerIssuedAtEnd, setDateIssuedStartPlayerChange, setDateIssuedEndPlayerChange, loadingGraph7 } = this.props;

        const optionsPlatform = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: 'Acquisti per Piattaforma',
                    font: {
                        size: 20
                    }
                }
            },

            scales: {
                y: {
                    ticks: {
                        beginAtZero: true,
                    }
                }
            }
        };

        const dataPlatform = {
            labels: ["Samsung", "Lg", "Android"],
            datasets: [
                {
                    label: "Trial",
                    backgroundColor: "rgba(52,117,255,0.5)",
                    data: trialPlatforms,
                    hoverBackgroundColor: "rgba(52,117,255,0.5)",
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1
                },

                {
                    label: "Lifetime",
                    backgroundColor: "Whitesmoke",
                    data: lifetimePlatforms,
                    hoverBackgroundColor: 'Whitesmoke',
                    borderColor: "Grey",
                    hoverBorderColor: 'Orange',
                    borderWidth: 1
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChartGains">
                    <FormControl className="licencePlatform">
                        <div className="row">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="col-6">
                                    <DatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        label="Inizo"
                                        // value={playerIssuedAtStart}
                                        defaultValue={dayjs(playerIssuedAtStart)}
                                        onChange={(newValue) => setDateIssuedStartPlayerChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                    />
                                </div>

                                <div className="col-6">
                                    <DatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        label="Fine"
                                        // value={playerIssuedAtEnd}
                                        defaultValue={dayjs(playerIssuedAtEnd)}
                                        onChange={(newValue) => setDateIssuedEndPlayerChange(isNull(newValue) ? null : dayjs(newValue.$d))}

                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                    </FormControl>
                    {
                        loadingGraph7 ? <Loading /> :
                            <Bar options={optionsPlatform} data={dataPlatform} />
                    }
                </div>
            </div>
        )
    }
}