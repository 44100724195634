import React, { Component } from 'react';

export default class actionMenuActiveJob extends Component {

    actionClick = (action, data) => {
        this.props.actionClick(action, data);
    }

    render() {
        const { data } = this.props.data;

        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className="dropdown-menu">
                    {/* <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('edit', this.state.vpn); }}>
                                <i className="far fa-edit orange"></i>
                                Modifica
                            </div>
                        </li> */}

                    <li>
                        <div className="dropdown-item" onClick={() => { this.actionClick('remove', data.id); }}>
                            <i className="far fa-trash-alt red"></i>
                            Elimina
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}