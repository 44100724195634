import axios from 'axios';
import store from '../index';
import { userActionType, notifyActions, roleActions } from './';
import {
  API_SET_PASSWORD_USER,
  API_USER_RESET_TFA,
  API_USER,
  API_USER_ADDRESS,
  API_SEND_MAIL,
  API_LOGIN_URL,
  API_REFRESH_TOKEN_URL
} from '../../config';
import { setAuthorizationToken } from '../../utility';

export const userActions = {
  getUserList,
  resetPassword,
  resetTfa,
  deleteUser,
  sendMail,
  sendNewUser,
  updateUser,
  updateAddress,
  loginRequest,
  setCurrentUser,
  refreshToken,
  logoutRequest
};

/**
 * Effettua la chiamata al server, modifica la password dell'utente selezionato.
 * In questo caso, dato un id, modifica la password di quell'id selezionato
 * con una password generata in modo random.
 * 
 * NB: Fare attenziona a passare tutto l'oggetto utente e non solo l'id utente.
 * 
 * @param {Object} user contiene tutte le informazioni dell'utente, eventualmente bisogna notificargli qualcosa
  */
function resetPassword(user) {
  return (dispatch) => {
    dispatch({
      type: userActionType.RESET_PASSWORD,
      async payload() {
        try {
          // Genera una password alpha numerica random di 10 caratteri
          let temporanyPassword = Math.random().toString(36).slice(-2);
          temporanyPassword += Math.random().toString(36).slice(-2).toUpperCase();
          temporanyPassword += Math.random().toString(36).slice(-2);
          temporanyPassword += Math.random().toString(36).slice(-2).toUpperCase();
          temporanyPassword += Math.random().toString(36).slice(-2);

          await axios.put(`${API_SET_PASSWORD_USER}/${user.id}`, { newPassword: temporanyPassword });

          dispatch(notifyActions.successAction('Reset password avvenuto!'));

          let message = ` Your password has been reset. 
                          Temporarily it is: ` + temporanyPassword + `
                          Log in now and change it with a new password.
                          We recommend that you enter at least 8 characters by entering alphanumeric characters (upper and lower case) and special characters.`;
          let data = {
            //la user id è l'email dell'utente
            email: user.id,
            subject: 'Reset Password',
            body: message
          };

          // invia mail all'utente, notificandogli la nuova password temporanea
          dispatch(sendMail(data));

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('La Password non soddisfa i requisiti minimi o massimi! Riprova di nuovo...')); break;
              case 404: dispatch(notifyActions.errorAction('ID utente non trovato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_SET_PASSWORD_USER} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

function resetTfa(user) {
  return (dispatch) => {
    dispatch({
      type: userActionType.RESET_TFA,
      async payload() {
        try {
          await axios.put(`${API_USER_RESET_TFA}/${user.id}`);

          dispatch(notifyActions.successAction('Reset TFA avvenuto!'));

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction("L'utente non ha il TFA. Non è possibile disattivarlo")); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER_RESET_TFA} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Chiama il BE e ritorna la lista degli utenti in base ai parametri di ricerca inseriti.
 * 
 * @param {Object} data
 */

function getUserList(data) {
  return (dispatch) => {
    dispatch({
      type: userActionType.GET_USER,
      async payload() {
        try {
          let response = await axios.get(`${API_USER}`, { params: data });

          return {
            listUser: response.data.value.value,
            totalUsers: response.data.info.total,
            totalPages: response.data.info.totalPages,
            paramsGetUserListTemp: data
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: notifyActions.errorAction('Inserisci un numero valido!'); break;
              case 403: notifyActions.errorAction('Error 403 - Non sei autorizzato!'); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER}} - GET`)); break;
            }
          }

          return {
            listUser: [],
            totalPages: '',
            totalUsers: '',
            paramsGetUserListTemp: {}
          }
        }
      }
    })
  }
}

/**
 * Cancella l'utente dal DB con le sue relative informazioni.
 * Viene passata tramite url la mail relativa all'utente da cancellare,
 * in caso la mail non esiste notifica che non c'è e non rimuove nulla.
 * 
 * @param {String} userMail 
 */
function deleteUser(userMail) {
  return (dispatch) => {
    dispatch({
      type: userActionType.DELETE_USER,
      async payload() {
        try {
          await axios.delete(`${API_USER}/${userMail}`)
            .then(() => {
              dispatch(notifyActions.successAction('Utente cancellato!'));
              dispatch(getUserList(store.getState().user.paramsGetUserListTemp));
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Email non trovata!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER} - DELETE`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Manda una mail all'utente.
 * 
 * @param {Object} data contiene mail, oggetto e testo della mail 
 */
function sendMail(data) {
  return (dispatch) => {
    dispatch({
      type: userActionType.SEND_MAIL,
      async payload() {
        try {
          await axios.post(`${API_SEND_MAIL}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Mail Inviata! 😉'));
              dispatch(getUserList(store.getState().user.paramsGetUserListTemp));
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Email non trovata!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_SEND_MAIL} - POST, Mail non mandata`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Crea un nuovo utente.
 * 
 * @param {Object} data contiene: email, password, name, surname, country, isReseller
 */

function sendNewUser(data) {
  return (dispatch) => {
    dispatch({
      type: userActionType.SEND_NEW_USER,
      async payload() {
        try {
          await axios.post(`${API_USER}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Nuovo utente creato! 😉'));
              // dispatch(getUserList(store.getState().user.paramsGetUserListTemp));
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Utente NON creato! Dati inseriti non corretti!')); break;
              case 409: dispatch(notifyActions.errorAction('Utente gia esistente! (Email già registrata)')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER} - POST. Utente NON registrato!`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Update di un indirizzo dell'utente.
 * 
 * @param {Object} data contiene: indirizzo di spedizione e fatturazione predefinito dell'utente
 */
function updateAddress(data, id) {
  return (dispatch) => {
    dispatch({
      type: userActionType.UPDATE_ADDRESS,
      async payload() {
        try {
          await axios.put(`${API_USER_ADDRESS}/${id}/${data.id}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Utente aggiornato! 😉'));
              dispatch(getUserList(store.getState().user.paramsGetUserListTemp));
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Utente NON creato! Dati inseriti non corretti!')); break;
              case 409: dispatch(notifyActions.errorAction('Utente gia esistente! (Email già registrata)')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER_ADDRESS} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Update di un utente.
 * 
 * @param {Object} data contiene: isReseller
 */
function updateUser(data) {
  return (dispatch) => {
    dispatch({
      type: userActionType.UPDATE_USER,
      async payload() {
        try {
          await axios.put(`${API_USER}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Utente aggiornato! 😉'));
              dispatch(getUserList(store.getState().user.paramsGetUserListTemp));
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Utente NON creato! Dati inseriti non corretti!')); break;
              case 409: dispatch(notifyActions.errorAction('Utente gia esistente! (Email già registrata)')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}


/**
 * Aggiorna lo store con le info dell'utente. 
 * Se facciamo un refresh di una pagina, perderemmo tutte le info dello state e dello store, 
 * ma ad ogni inizializzazione chiama la funzione refreshToken, e se è presente l'utente, si aggiorna lo store, 
 * in modo da rendere disponibili tutte le info a tutti i componenti dell'applicazione.
 * 
 * Note: invocabile da loggato o subito dopo il corretto Login
 * 
 * @param {*} user 
 */
function setCurrentUser(user) {
  return {
    type: userActionType.SET_CURRENT_USER,
    user
  }
}

/**
 * Dopo aver effettuato l'accesso, imposta il token per le autorizzazioni.
 * 
 * Invocabile solo se non loggato.
 * 
 * @param {*} data include email, password
 */
function loginRequest(data) {
  return (dispatch) => {
    dispatch({
      type: userActionType.LOGIN_REQUEST,
      async payload() {
        try {
          let params = {
            credentials: {
              email: data.email,
              password: data.password,
              captchaCode: data.captchaCode
            },
            pin: data.pin
          };

          let response = await axios.post(`${API_LOGIN_URL}`, params);

          let user = {
            name: response.data.name,
            surname: response.data.surname,
            email: response.data.email,
            country: response.data.country,
            role: response.data.role,
            token: response.data.token,
            infos: response.data.infos
          }

          localStorage.setItem('token', user.token);
          setAuthorizationToken(user.token);
          dispatch(setCurrentUser(user));
          dispatch(roleActions.takeRole(user.infos));
          dispatch(notifyActions.infoAction('Login effettuato!'));

          return {
            user: response.data
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Dati inseriti non corretti!')); break;
              default: dispatch(notifyActions.errorAction('Error' + result.response.status)); break;
            }
          }
          return {
            user: {}
          }
        }
      }
    })
  }
}

/**
 * Questa funzione viene richiamata ogni volta che l'utente aggiorna la pagina (refresh).
 * 
 * Aggiorna il token con uno nuovo che riceve dal Back End e preleva tutti i dati relativi all'utente.
 * Dopodichè setta l'utente nello store in modo da avere tutti i dati sempre disponibili.
 * 
 * @param {*} token 
 */
function refreshToken(token) {
  return (dispatch) => {
    dispatch({
      type: userActionType.REFRESH_TOKEN,
      async payload() {
        try {

          let response = await axios.post(API_REFRESH_TOKEN_URL, { token: token });

          let user = {
            name: response.data.name,
            surname: response.data.surname,
            email: response.data.email,
            country: response.data.country,
            role: response.data.role,
            token: response.data.token,
            infos: response.data.infos
          }

          localStorage.setItem('token', response.data.token);
          setAuthorizationToken(response.data.token);
          dispatch(setCurrentUser(user));
          dispatch(roleActions.takeRole(user.infos));

          return {
            user: user
          }

        } catch (e) {
          localStorage.removeItem('token');
          window.location.reload();
        }
      }
    })
  }
}

/**
 * Esegue il logout dell'utente eliminando le info presenti nel local storage, ed effettua il redirect alla dashboard, richiedendo nuovamente il login.
 * 
 * Invocabile solo da loggato
 * 
 * @param {*} handleNavigate - effettuare il redirect al pannello di login con la funzione handleNavigate
 */
function logoutRequest(handleNavigate) {
  localStorage.removeItem('token');
  handleNavigate('/');
  return {
    type: userActionType.LOGOUT_REQUEST,
    payload: {
      user: {}
    }
  }
}