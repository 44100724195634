import React, { Component, Fragment } from 'react';
import moment from 'moment';

import Loading from '../../../common/loading/loadingInBlock';
import { returnNumber } from '../../../common/actions/returnNumber';
import { controlRole } from '../../../common/actions/controlRole';
import BlockForWidget from './blockForWidget';

export default class tagWidgetLive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment().format('DD/MM/YYYY'),
            time: moment().format('HH:mm'),
            classActive: "payments"
        }
    }

    render() {
        const {
            totalUsers,
            signedUsers,
            totalLicences,
            totalLicencesLifetime,
            totalLicencesTrial,
            totalPlayers,
            samsung,
            lg,
            android,
            totalPayment,
            paymentPayPal,
            paymentStripe,
            paymentBitPay,
            paymentStark,
            paymentPromo,
            paymentNetopia,
            paymentManual,

            // LICENZE RINNOVO VPN
            vpnLicenceActiveOneMonth,
            vpnLicenceActiveThreeMonth,
            vpnLicenceActiveSixMonth,
            vpnLicenceActiveTwelveMonth,
            vpnLicenceActiveTwentyfourMonth,

            loadingGraph1,
            numberVpnPeer,
            role,
            isMobile
        } = this.props;

        return (
            <div className="tagWidgetLive">
                <h5> Dati aggiornati alle {this.state.time} del {this.state.date} </h5>

                {
                    loadingGraph1 ? <Loading /> :
                        <Fragment>
                            <div className="containerDeskWidget">
                                <div className="row">
                                    {
                                        isMobile &&
                                        <div className="row justify-content-center">
                                            <div className="col-12 text-center buttonsYear">
                                                <button
                                                    className={this.state.classActive === "payments" ? "btn btn-primary" : "btn btn-outline-primary"}
                                                    type="button"
                                                    name="statistics"
                                                    value="payments"
                                                    onClick={() => this.setState({ classActive: 'payments' })}
                                                >
                                                    Pagamenti
                                                </button>

                                                <button
                                                    className={this.state.classActive === "users" ? "btn btn-primary" : "btn btn-outline-primary"}
                                                    type="button"
                                                    name="statistics"
                                                    value="users"
                                                    onClick={() => this.setState({ classActive: 'users' })}
                                                >
                                                    Utenti
                                                </button>

                                                <button
                                                    className={this.state.classActive === "licencesPlayer" ? "btn btn-primary" : "btn btn-outline-primary"}
                                                    type="button"
                                                    name="statistics"
                                                    value="licencesPlayer"
                                                    onClick={() => this.setState({ classActive: 'licencesPlayer' })}
                                                >
                                                    Player
                                                </button>

                                                <button
                                                    className={this.state.classActive === "licencesVpn" ? "btn btn-primary" : "btn btn-outline-primary"}
                                                    type="button"
                                                    name="statistics"
                                                    value="licencesVpn"
                                                    onClick={() => this.setState({ classActive: 'licencesVpn' })}
                                                >
                                                    VPN
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    <div className={(isMobile && this.state.classActive !== "payments") ? "col-md-3 col-sm-12 col-12 hidden" : "col-md-3 col-sm-12 col-12"}>
                                        <BlockForWidget
                                            data={[
                                                { [`Pagamenti totali:`]: returnNumber(totalPayment) },
                                                { [`Pagamenti PayPal:`]: returnNumber(paymentPayPal) },
                                                { [`Pagamenti Stripe:`]: returnNumber(paymentStripe) },
                                                { [`Pagamenti BitPay:`]: returnNumber(paymentBitPay) },
                                                { [`Pagamenti Stark:`]: returnNumber(paymentStark) },
                                                { [`Pagamenti Netopia:`]: returnNumber(paymentNetopia) },
                                                { [`Pagamenti Manuali:`]: returnNumber(paymentManual) },
                                                { [`Pagamenti Promo:`]: returnNumber(paymentPromo) },
                                            ]}

                                            isMobile={isMobile}
                                        />
                                    </div>

                                    <div className={(isMobile && this.state.classActive !== "users") ? "col-md-3 col-sm-12 col-12 hidden" : "col-md-3 col-sm-12 col-12"}>
                                        <BlockForWidget
                                            data={[
                                                { [`Utenti Registrati:`]: returnNumber(totalUsers) },
                                                { [`Utenti Confermati:`]: returnNumber(signedUsers) },
                                                { [`Licenze Samsung:`]: returnNumber(samsung) },
                                                { [`Licenze LG:`]: returnNumber(lg) },
                                                { [`Licenze Android:`]: returnNumber(android) },
                                            ]}

                                            isMobile={isMobile}
                                        />
                                    </div>

                                    <div className={(isMobile && this.state.classActive !== "licencesPlayer") ? "col-md-3 col-sm-12 col-12 hidden" : "col-md-3 col-sm-12 col-12"}>
                                        <BlockForWidget
                                            data={[
                                                { [`Licenze Totali:`]: returnNumber(totalLicences) },
                                                { [`Players Totati:`]: returnNumber(totalPlayers) },
                                                { [`Licenze Trial(scadute e attive):`]: returnNumber(totalLicencesTrial) },
                                                { [`Licenze Lifetime:`]: returnNumber(totalLicencesLifetime) },
                                            ]}

                                            isMobile={isMobile}
                                        />
                                    </div>

                                    <div className={(isMobile && this.state.classActive !== "licencesVpn") ? "col-md-3 col-sm-12 col-12 hidden" : "col-md-3 col-sm-12 col-12"}>
                                        <BlockForWidget
                                            data={[
                                                { [`Licenza Vpn 1 Mese:`]: vpnLicenceActiveOneMonth },
                                                { [`Licenza Vpn 3 Mesi:`]: vpnLicenceActiveThreeMonth },
                                                { [`Licenza Vpn 6 Mesi:`]: vpnLicenceActiveSixMonth },
                                                { [`Licenza Vpn 12 Mesi:`]: vpnLicenceActiveTwelveMonth },
                                                { [`Licenze Vpn 24 Mesi:`]: vpnLicenceActiveTwentyfourMonth },
                                                { [`Licenza Vpn Totali:`]: vpnLicenceActiveOneMonth + vpnLicenceActiveThreeMonth + vpnLicenceActiveSixMonth + vpnLicenceActiveTwelveMonth + vpnLicenceActiveTwentyfourMonth },
                                                { [`VPN Peer:`]: controlRole(role.vpnPeer, "api/VpnPeer", "GET") ? numberVpnPeer : '- UNAUTHORIZED -' },
                                            ]}

                                            isMobile={isMobile}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                }
            </div>
        )
    }
}