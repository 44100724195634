import React, { Component, Fragment } from 'react';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@mui/material';
import { connect } from 'react-redux';

import { playerActions, notifyActions, paymentActions, vpnActions } from '../../store/actions';
import { NOT_REGISTERED } from '../../config';
import './style.scss';
import MyTooltip from '../../common/tooltip';
import ModalPlayList from '../../common/modal/modalManageList';
import Date from '../../common/date';
import SelectorCopy from '../../common/selectorCopy';
import { controlRole } from '../../common/actions/controlRole';
import { withNavigate } from '../../utility';

class expanableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: this.props.data.description,
            platform: this.props.data.platform,
            playlist: this.props.data.playLists,
            id: this.props.data._id,
            deviceType: this.props.data.deviceType,
            userId: this.props.data.user,
            model: this.props.data.model,
            version: this.props.data.version,
            vpnPass: this.props.data.vpnPass !== null ? this.props.data.vpnPass : '',

            // dati della licenza associata
            licence: {
                key: this.props.data.licence !== null ? this.props.data.licence.key : '',
                description: this.props.data.licence !== null ? this.props.data.licence.description : '',
                paymentId: this.props.data.licence !== null ? this.props.data.licence.paymentId : '',
                playerId: this.props.data.licence !== null ? this.props.data.licence.playerId : '',
                user: this.props.data.licence !== null ? this.props.data.licence.user : '',
                platform: this.props.data.licence !== null ? this.props.data.licence.platform : '',
                expireAt: this.props.data.licence !== null ? this.props.data.licence.expireAt : '',
            },

            // informazioni che dovranno andare nel CSV da esportare
            headersCSV: [],
            dataCSV: [],

            hasClickEdit: false,
            hasClickRemove: false,
            hasClickNewPlaylist: false,
            titleModal: '',

            title: '',
            url: '',
            keyPlaylist: '',

            type: '',
            modal: false
        }
    }

    toggleModal = () => {
        this.setState({
            hasClickNewPlaylist: false,
            hasClickEdit: false,
            hasClickRemove: false,
            modal: false,
            titleModal: '',
            title: '',
            url: '',
            type: '',
            keyPlaylist: ''
        });
    }

    /**
     * Vai ai dettagli sull'utente selezionato
     */
    goToUser = () => {
        this.props.handleNavigation(this.props.navigate, `/userInDetail/${this.state.userId}`);
    }

    /**
     * Dato un id, vai alla schermata dei pagamenti portando id pagamento nei filtri
     */
    goToPayment = () => {
        this.props.setIdPaymentForDetail(this.state.licence.paymentId)
        this.props.handleNavigation(this.props.navigate, `/payments`);
    }

    /**
     * Dato una key, vai alla schermata delle pass portando key nei filtri
     */
    goToPass = () => {
        this.props.setKeyVpnPassForDetail(this.state.vpnPass)
        this.props.handleNavigation(this.props.navigate, `/vpn/pass`);
    }

    /**
     * Apre modale per aggiungere una nuova playlist
     */
    hasClickNewPlaylist = () => {
        this.setState({
            hasClickNewPlaylist: true,
            titleModal: 'Nuova Playlist',
            type: 'new'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Al click di modifica playlist, riceve in input:
     * - la chiave della playlist da modificare
     * - il titolo della playlist da modificare
     * - l'url della playlist da modificare
     * 
     * e apre la modale
     */
    hasClickEdit = (keyPlaylist, title, url) => {
        this.setState({
            hasClickEdit: true,
            titleModal: 'Modifica Playlist',
            title: title,
            url: url,
            keyPlaylist: keyPlaylist,
            type: 'edit'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Rimuove una playlist.
     * @param key della playlist da rimuovere
     */
    hasClickRemove = (keyPlaylist) => {
        this.setState({
            hasClickRemove: true,
            titleModal: 'Elimina Playlist',
            keyPlaylist: keyPlaylist,
            type: 'delete'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Invia la nuova playlist al player.
     * Vengon inviati i seguenti parametri:
     * - titolo della playlist
     * - url della playlist
     * - deviceId a cui associare la playlist
     */
    sendNewPlaylist = (title, url) => {
        this.props.sendNewPlaylist({
            title: title,
            url: url,
            deviceId: this.state.id,
        });

        this.toggleModal();
        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.getPlayerList(false, this.props.player.paramsGetPlayerListTemp);
        }, 500);
    }

    /**
     * Modifica la playlist del player.
     * Riceve i seguenti parametri:
     * - title (titolo della playlist)
     * - url (url della sorgente)
     * - key (chiave della playlist)
     */
    sendEditPlaylist = (title, url, keyPlaylist) => {
        this.props.sendModifyPlaylist({
            title: title,
            url: url,
            deviceId: this.state.id,
            playListId: keyPlaylist
        });
        this.toggleModal();
        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.refreshPlayList();
        }, 500);
    }

    /**
     * Rimuove la playlist dal player.
     * Vengono passati i seguenti parametri:
     * - key della playlist
     * - device id a cui è associata la playlist
     */
    removePlaylist = (keyPlaylist) => {
        this.props.removePlaylist({
            key: keyPlaylist,
            deviceId: this.state.id,
        });
        this.toggleModal();
        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.refreshPlayList();
        }, 500);
    }

    /**
     * Aggiunge una pass al player.
     */
    addPassToPlayer = (keyPass) => {
        this.props.addPassToPlayer({
            keyPass: keyPass,
            deviceId: this.state.id,
        });
        this.toggleModal();
        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.refreshPlayList();
        }, 500);
    }

    render() {
        const { role } = this.props;
        return (
            <div className="tabExpanablePlayer">
                <FormControl>
                    {
                        this.state.userId !== null &&
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className="btn btn-warning" onClick={this.goToUser}>
                                    Vai ai dettagli sull'utente
                                </button>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.description || ''}
                                label="Descrizione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.platform || ''}
                                label="Piattaforma"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-device-player">ID Device</InputLabel>
                                <Input
                                    id="id-device-player"
                                    value={this.state.id || ''}
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.state.id}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.deviceType || ''}
                                label="Tipo Device"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.model || ''}
                                label="Modello"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.version || ''}
                                label="Versione"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <hr style={{ 'width': '100%' }}></hr>
                    <h5>Licenza</h5>

                    <div className='row'>
                        <div className="col-4">
                            <TextField
                                value={this.state.licence.key || ''}
                                label="Codice Attivazione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.licence.playerId || ''}
                                label="Player che sta utilizzando la licenza"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.licence.user || ''}
                                label="Utente che sta utilizzando la licenza"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-payment-player">ID Pagamento</InputLabel>
                                <Input
                                    id="id-payment-player"
                                    value={this.state.licence.paymentId === NOT_REGISTERED ? 'Non Presente...' : this.state.licence.paymentId}
                                    readOnly
                                    endAdornment={
                                        this.state.licence.paymentId &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.state.licence.paymentId}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                            {
                                                this.state.licence.expireAt === null &&
                                                <span className="clickForPayment" onClick={this.goToPayment}>
                                                    <MyTooltip
                                                        title="Vai a questo pagamento"
                                                        position="top"
                                                        content={<i className="fas fa-share-square"></i>}
                                                    />
                                                </span>
                                            }

                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.licence.description || ''}
                                label="Descrizione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.licence.platform || ''}
                                label="Platform"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <hr style={{ 'width': '100%' }}></hr>
                    <h5>VPN Pass</h5>

                    <div className='row'>
                        <div className="col-4">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-vpnPass-player">Key VPN Pass</InputLabel>
                                <Input
                                    id="id-vpnPass-player"
                                    value={this.state.vpnPass === '' ? "VPN Pass Non Associata..." : this.state.vpnPass}
                                    readOnly
                                    endAdornment={
                                        this.state.vpnPass &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.state.vpnPass}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                            {
                                                this.state.vpnPass &&
                                                <span className="clickForPayment" onClick={this.goToPass}>
                                                    <MyTooltip
                                                        title="Vai a questa VPN Pass"
                                                        position="top"
                                                        content={<i className="fas fa-share-square"></i>}
                                                    />
                                                </span>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    <hr style={{ 'width': '100%' }}></hr>

                    <div className="row addPlaylist">
                        <div className="col">
                            <h5>Lista delle playlists</h5>
                        </div>

                        <div className="col text-right">
                            {
                                controlRole(role.player, "api/Player/AddPlaylist", "POST") &&
                                <button className="btn btn-success" onClick={this.hasClickNewPlaylist}>
                                    <i className="fas fa-plus"></i> Aggiungi Playlist
                                </button>
                            }
                        </div>
                    </div>
                    
                    {
                        this.state.playlist.length === 0 ?
                            <div className="row addPlaylist">
                                <div className="col">
                                    <h6>Non ci sono playlist</h6>
                                </div>
                            </div>
                            :
                            <Fragment>
                                <div className="row">
                                    <div className="col">
                                        <h2>Playlist presenti: <span className="badge text-bg-secondary">{this.state.playlist.length}</span></h2>
                                    </div>
                                </div>

                                <div className="row title">
                                    <div className="col-2">
                                        Nome
                                    </div>
                                    <div className="col-6">
                                        URL
                                    </div>
                                    <div className="col-2 text-center">
                                        Data creazione
                                    </div>
                                    <div className="col-2">
                                    </div>
                                </div>
                                {
                                    this.state.playlist.map((playlist, key) => {
                                        return (
                                            <div className="row playlistContent" key={key}>
                                                <div className="col-2">
                                                    <p className="only"> {playlist.title} </p>
                                                </div>

                                                <div className="col-6">
                                                    <p className="only"> {playlist.source} </p>
                                                </div>

                                                <div className="col-2">
                                                    <Date format="L" date={playlist.createdAt} />
                                                </div>

                                                <div className="col-2 text-center">
                                                    <div className="row iconRow justify-content-center">
                                                        {
                                                            controlRole(role.player, "api/Player/UpdatePlaylist", "PUT") &&
                                                            <div className="col icon edit" onClick={() => { this.hasClickEdit(playlist.key, playlist.title, playlist.source) }}>
                                                                <MyTooltip
                                                                    title="Modifica"
                                                                    position="left"
                                                                    content={<i className="far fa-edit"></i>}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            controlRole(role.player, "api/Player/RemovePlaylist", "DELETE") &&
                                                            <div className="col icon delete" onClick={() => { this.hasClickRemove(playlist.key) }}>
                                                                <MyTooltip
                                                                    title="Elimina"
                                                                    position="left"
                                                                    content={<i className="far fa-trash-alt"></i>}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Fragment>
                    }
                </FormControl>

                {
                    (this.state.hasClickEdit || this.state.hasClickNewPlaylist || this.state.hasClickRemove) &&
                    <ModalPlayList
                        titleModal={this.state.titleModal}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}

                        title={this.state.title}
                        url={this.state.url}
                        deviceId={this.state.id}
                        type={this.state.type}
                        keyPlaylist={this.state.keyPlaylist}
                        errorNotify={this.props.errorNotify}

                        clickNew={this.sendNewPlaylist}
                        clickEdit={this.sendEditPlaylist}
                        clickRemove={this.removePlaylist}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player,
        role: state.role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addPassToPlayer: (data) => {
            dispatch(playerActions.addPassToPlayer(data))
        },
        sendNewPlaylist: (data) => {
            dispatch(playerActions.sendNewPlaylist(data))
        },
        setIdPaymentForDetail: (data) => {
            dispatch(paymentActions.setIdPaymentForDetail(data))
        },
        setKeyVpnPassForDetail: (data) => {
            dispatch(vpnActions.setKeyVpnPassForDetail(data))
        },
        sendModifyPlaylist: (data) => {
            dispatch(playerActions.sendModifyPlaylist(data))
        },
        removePlaylist: (data) => {
            dispatch(playerActions.removePlaylist(data));
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        },
    };
};

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(expanableComponent));