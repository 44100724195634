import './style.scss';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

export default class ErrorStatus extends Component {
  render() {
    return (
      <div className="errorContainer">
        <h1>Pagina Non Trovata</h1>
        <Link className="blue" to="/">Torna Indietro</Link>
      </div>
    );
  }
}