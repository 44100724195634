import React, { Component } from 'react';
import isNull from 'lodash/isNull';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import ModalShowDynamicData from '../../common/modal/modalShowDynamicData';
import { randomString } from '../../common/actions/randomString';
import { Fragment } from 'react';

export default class expanableComponentActiveJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // dati della job associato
            serviceInput: !isNull(this.props.data.serviceInput) ? JSON.parse(this.props.data.serviceInput) : '', //object dynamic
            taskInput: !isNull(this.props.data.taskInput) ? JSON.parse(this.props.data.taskInput) : '', //object dynamic

            modal: false,
            hasClickModal: false,

            shortId: randomString(),

            // variabile usata per passare i dati dinamici alla modale (taskInput e serviceInput)
            dynamicDataForModal: {}
        }
    }

    toggleModal = () => {
        this.setState({
            modal: false,
            shortId: randomString(),
            hasClickModal: false
        });
    }

    showInputInModal = (data) => {
        this.setState({
            dynamicDataForModal: data,
            hasClickModal: true
        });
        setInterval(() => {
            this.setState({ modal: true });
        }, 200);
    }

    /**
     * Ci sono alcuni elementi che sono presenti già nella griglia principale (sono gli elementi comuni),
     * e non devono essere rivisti nei dettagli per evitare confusione.
     * Tra l'altro nemmeno gli input di task e service devono essere visti poiche hanno una visualizzazione
     * in modale a parte.
     * Quindi questa funziona controlla se quel nome nella visualizzazione dinamica debba apparire o meno.
     * Restituisce true se deve vederlo, false altrimenti.
     * 
     * @param {*} name nome da visualizzare
     */
    controlNameInDetail = (name) => {
        switch (name) {
            case 'id': case 'createdAt':
            case 'service': case 'task':
            case 'isBusy': case 'lastException':
            case 'serviceInput': case 'taskInput': return false;
            default: return true;
        }
    }

    /**
     * Controlla se il nome restituisca una data.
     * Restituisce true se deve vederlo come data, false altrimenti.
     * 
     * @param {*} date data da visualizzare
     */
    controlDateInDetail = (date) => {
        switch (date) {
            case 'updatedAt': case 'lastRunAt':
            case 'lastFinishedAt': case 'nextRunAt': return true;
            default: return false;
        }
    }

    formatterData = (data) => {
        if (!isBoolean(data) && !isNull(data) && !isEmpty(data)) {
            let sanitizeMessage = data.split("\n");
            return sanitizeMessage;
        }

        return [];
    }

    render() {
        const {
            serviceInput,
            taskInput,
            shortId,
            modal,
            dynamicDataForModal,
            hasClickModal
        } = this.state;

        const dynamicData = this.props.data;

        return (
            <div className="tabExpanableJob">
                {
                    Object.keys(dynamicData).map(el => {
                        return (
                            <Fragment key={el}>
                                {
                                    this.controlNameInDetail(el) ?
                                        <div className="row" key={el}>
                                            <div className="col-4 text-right">
                                                <b>{el}:</b>
                                            </div>
                                            <div className="col elem">
                                                {
                                                    this.controlDateInDetail(el) ?
                                                        moment(dynamicData[el]).format('DD-MM-YYYY - HH:mm:ss')
                                                        :
                                                        this.formatterData(dynamicData[el]).map(el => {
                                                            return (
                                                                <div key={el}>{el}</div>
                                                            )
                                                        })
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                }
                            </Fragment>
                        )
                    })
                }

                <div className="row justify-content-center marginBorder">
                    <div className="col-6 text-center">
                        <button className="btn btn-info" onClick={() => { this.showInputInModal(taskInput) }}> Task Input </button>
                    </div>
                    <div className="col-6 text-center">
                        <button className="btn btn-info" onClick={() => { this.showInputInModal(serviceInput) }}> Service Input </button>
                    </div>
                </div>

                {
                    hasClickModal &&
                    <ModalShowDynamicData
                        titleModal="Info Job"
                        toggle={this.toggleModal}
                        stateModal={modal}
                        dynamicData={dynamicDataForModal}
                        key={shortId}
                    />
                }

            </div >
        )
    }
}