import React, { Component } from 'react'

export default class blockForWidget extends Component {
    render() {
        const { data, isMobile } = this.props;

        return (
            <div className="blockForWidget">
                {
                    data.map((el, k) => {
                        return (
                            Object.keys(el).map(key => {
                                return (
                                    <div key={k} className="row justify-content-center">
                                        <div className={isMobile ? "col-8 labelKey text-center" : "col-8 labelKey"}>
                                            {key}
                                        </div>

                                        <div className={isMobile ? "col-4 labelValue text-center" : "col-4 labelValue"}>
                                            {el[key]}
                                        </div>
                                    </div>
                                )
                            })
                        )
                    })
                }
            </div>
        )
    }
}