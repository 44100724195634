import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { isEmpty, isNil } from 'lodash';

import './style.scss';
import FormFilter from '../components/formFilters';
import Loading from '../../common/loading';
import ActionMenu from '../components/actionMenu';
import ExpanableComponent from '../components/expanableComponent';
import ModalMail from '../../common/modal/modalMail';
import ModalUser from '../../common/modal/modalUser';
import CSVExport from '../../common/csvExport';
import { returnNationality } from '../../common/actions/returnNationality';
import SelectorCopy from '../../common/selectorCopy';
import IconLicence from '../../common/iconLicence';
import { controlRole } from '../../common/actions/controlRole';
import { withNavigate } from '../../utility';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalMail: false,
            modalUser: false,

            // variabili utilizzate per la modifica di un utente
            email: '',
            country: '',
            isReseller: false,
            name: '',
            surname: '',
            editUser: false,
            editRole: false,
            editGroup: false,
            role: null,
            groupUser: [],

            // controlla se sia stato cliccato nuovo utente o nuova mail
            hasClickNewUser: false,
            hasClickNewMail: false,
            hasClickUpdateAddress: false,
            hasClickRole: false,
            hasClickGroup: false,

            billingAddress: {},
            shippingAddress: {},

            openFilters: true,
            openFiltersDesktop: false,

            loadingForRole: true
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare gli utenti, viene rispedito alla home
        if (!isEmpty(this.props.role.user)) {
            if (!controlRole(this.props.role.user, "api/User", "GET")) {
                this.props.handleNavigation(this.props.navigate, '/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare gli utenti!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
            }
        }

        if (!isEmpty(this.props.role.role)) {
            this.props.getRoleList();
        }

        if (!isEmpty(this.props.role.user) && controlRole(this.props.role.user, "api/User/Group", "GET")) {
            this.props.getGroupList();
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare gli utenti, viene rispedito alla home
        if (!isEmpty(this.props.role.user)) {
            if (!controlRole(this.props.role.user, "api/User", "GET")) {
                this.props.handleNavigation(this.props.navigate, '/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare gli utenti!')
            } else if (this.state.loadingForRole) {

                if (!isEmpty(this.props.role.role)) {
                    this.props.getRoleList();
                }

                if (!isEmpty(this.props.role.user) && controlRole(this.props.role.user, "api/User/Group", "GET")) {
                    this.props.getGroupList();
                }

                this.setState({ loadingForRole: false });
            }
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({ modalMail: !prevState.modalMail }));
        this.setState(prevState => ({ modalUser: !prevState.modalUser }));
        this.setState({
            hasClickNewMail: false,
            hasClickUpdateAddress: false,
            hasClickRole: false,
            hasClickGroup: false,
            hasClickNewUser: false,
            editRole: false,
            editUser: false,
            editGroup: false
        });
    }

    openModalMail = (user) => {
        this.setState({
            userMail: user.id,
            hasClickNewMail: true
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalMail: !prevState.modalMail }));
        }, 200);
    }

    openModalActionUser = (user, action) => {
        if (action === "editGroup") {
            this.setState({
                email: user.id,
                groupUser: user.groups,
                addresses: this.sanitizeUserAddress(user.addresses),
                hasClickGroup: true,
                editGroup: true
            });

        } else if (action === "editRole") {
            this.setState({
                email: user.id,
                role: user.role,
                addresses: this.sanitizeUserAddress(user.addresses),
                hasClickRole: true,
                editRole: true
            });

        } else if (action === "editAddress") {
            this.setState({
                email: user.id,
                country: user.country,
                isReseller: user.isReseller,
                addresses: this.sanitizeUserAddress(user.addresses),
                name: user.name,
                surname: user.surname,
                role: user.role,
                hasClickUpdateAddress: true,
                editUser: true,
            });

        } else if (action === "newUser") {
            this.setState({
                userMail: user.id,
                email: '',
                country: '',
                isReseller: false,
                name: '',
                surname: '',
                addresses: this.sanitizeUserAddress(user.addresses),
                hasClickNewUser: true
            });
        }

        setTimeout(() => {
            this.setState(prevState => ({ modalUser: !prevState.modalUser }));
        }, 200);
    }

    /**
     * Invia i dati della mail al BE, e a sua volta invia la mail all'utente
     */
    sendMail = (mail, oggetto, testo) => {
        let data = {
            email: mail,
            subject: oggetto,
            body: testo
        }
        this.toggleModal();
        this.props.sendMail(data);
    }

    // prendo gli indirizzi e ne estraggo solo quello predefinito di fatturazione
    sanitizeUserAddress = (addresses) => {
        let tempData = {};
        let tempData2 = {};
        if (!isEmpty(addresses)) {
            // eslint-disable-next-line
            addresses.map(el => {
                if ((el.isShipping === false && el.isFavorite === true)) {
                    tempData = el;
                }

                if (el.isShipping === true && el.isFavorite === true) {
                    tempData2 = el;
                }
            })

            this.setState({
                billingAddress: tempData,
                shippingAddress: tempData2
            });

        } else
            return [];
    }

    /**
     * Invia i dati del nuovo utente al BE
     */
    sendNewUser = (data) => {
        this.props.sendNewUser(data);
        this.toggleModal();
    }

    /**
     * Invia i dati al BE dopo aver aggiornato l'utente
     */
    updateAddress = (data, id) => {
        this.props.updateAddress(data, id);
        this.toggleModal();
    }

    /**
     * Invia i dati al BE assegnando il ruolo all'utente
     */
    assignRole = (data) => {
        this.props.assignRole(data);
        this.toggleModal();
    }

    /**
     * Invia i dati al BE assegnando il ruolo all'utente
     */
    assignGroup = (data) => {
        this.props.assignGroup(data);
        this.toggleModal();
    }

    /**
     * Rimuove un utente dal DB
     * @param userMail utente da cancellare
     */
    deleteUser = (userMail) => {
        this.props.deleteUser(userMail);
        this.toggleModal();
    }

    /**
     * Rimuove il ruolo assegnato ad un utente
     * @param userMail utente associato al ruolo da cancellare
     */
    removeRole = (userMail) => {
        this.props.unassignRole(userMail);
        this.toggleModal();
    }

    /**
     * Invia i dati al BE dopo aver aggiornato l'utente
     */
    updateUser = (user) => {
        let data = {
            id: user.id,
            registrationIp: user.registrationIp,
            email: user.email,
            lastLogOn: user.lastLogOn,
            isRegistered: user.isRegistered,
            createdAt: user.createdAt,
            role: user.role,
            isReseller: user.isReseller ? false : true,
        }

        this.props.updateUser(data);
    }

    /**
     * Al click del menu opzioni, controlla quale opzione sia stata selezionata 
     * e di conseguenza esegue l'azione per quella riga.
     * 
     * @param action è il tipo di azione selezionato (piu info, invia mail, reset password, ecc...)
     * @param user è l'oggetto contente le informazioni dell'utente selezionato per cui è richiesta quell'azione
     */
    actionClick = (action, user) => {
        switch (action) {
            case 'moreDetail': this.moreDetail(user); break;
            case 'editAddress': case 'editGroup': case 'editRole': this.openModalActionUser(user, action); break;
            case 'sendMail': this.openModalMail(user); break;
            case 'removeRole': this.removeRole(user.id); break;
            case 'resetPassword': this.props.resetPassword(user); break;
            case 'resetTfa': this.props.resetTfa(user); break;
            case 'deleteUser': this.deleteUser(user.id); break;
            case 'editUser': this.updateUser(user); break;
            default: break;
        }
    }

    /**
     * Visualizza l'utente da configurare in dettaglio
     */
    moreDetail = (user) => {
        this.props.handleNavigation(this.props.navigate, `/userInDetail/${user.id}`);
    }

    render() {
        const { role, user, group, getUserList } = this.props;

        const columns = [
            {
                name: 'Id Utente/Email',
                selector: row => <SelectorCopy item={row.id} infoNotify={this.props.infoNotify} isReseller={row.isReseller} payments={row.paymentsCount} />,
                minWidth: '200px'
            },
            {
                name: 'Country',
                selector: row => returnNationality(row.country),
                minWidth: '150px',
                maxWidth: '150px',
            },
            {
                name: 'Payment',
                selector: row => row.paymentsCount,
                center: true,
                minWidth: '120px',
                maxWidth: '120px',
            },
            {
                name: 'Player',
                selector: row => row.playersCount,
                center: true,
                minWidth: '120px',
                maxWidth: '120px',
            },
            {
                name: 'Pass',
                selector: row => row.vpnPassesCount,
                center: true,
                minWidth: '120px',
                maxWidth: '120px',
            },
            {
                name: 'P.U./Tot',
                selector: row => row.personalPromosUsageCount + "/" + row.personalPromosCount,
                center: true,
                minWidth: '120px',
                maxWidth: '120px',
            },
            {
                name: 'C.U./Tot',
                selector: row => row.couponsUsageCount + "/" + row.couponsCount,
                center: true,
                minWidth: '120px',
                maxWidth: '120px',
            },
            {
                name: 'G.P.U./Tot',
                selector: row => row.geoPromosUsageCount + "/" + row.geoPromosCount,
                center: true,
                minWidth: '120px',
                maxWidth: '120px',
            },
            {
                selector: row => <ActionMenu actionClick={this.actionClick} user={row} role={role} userRole={user} data={user.listUser} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {
                    (this.state.loadingForRole || group.isLoading) ? <Loading /> :
                        <Fragment>
                            <div className="row">
                                <div className="col-12 userDesktop">
                                    <div className="contentFilterSearch">
                                        <FormFilter
                                            getUserList={getUserList}
                                            user={user}

                                            warningNotify={this.props.warningNotify}
                                            errorNotify={this.props.errorNotify}
                                            infoNotify={this.props.infoNotify}
                                            successNotify={this.props.successNotify}
                                        />
                                    </div>

                                    <div className="contentUser">
                                        <div className="row titleMobile">
                                            <div className="col-6 text-start">
                                                <h2 className='inlineBlock'>Utenti</h2>
                                                {
                                                    user.totalUsers &&
                                                    <div className="mybadge">
                                                        {user.totalUsers}
                                                    </div>
                                                }
                                            </div>

                                            {
                                                !isNil(user.listUser) &&
                                                <div className="col-6 text-end">
                                                    <CSVExport
                                                        data={user.listUser}
                                                        filename="list-users.csv"
                                                        label="Scarica la lista degli utenti selezionata"
                                                    />
                                                </div>
                                            }

                                            <div className="col-12 text-center">
                                                {
                                                    controlRole(role.user, "api/User", "POST") &&
                                                    <button className="btn btn-success addMarginForBtn" onClick={() => this.openModalActionUser("", "newUser")}>
                                                        <i className="fas fa-plus" style={{ 'marginRight': '5px' }}></i>
                                                        Aggiungi Utente
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <IconLicence legend forUsers />

                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                                <DataTable
                                                    columns={columns}
                                                    data={user.listUser}
                                                    customStyles={customStyles}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    expandableRows={this.state.isLoading ? false : true}
                                                    expandOnRowClicked
                                                    expandableRowsComponent={ExpanableComponent}
                                                    expandableRowsComponentProps={{
                                                        "clickMoreDetail": this.actionClick,
                                                        "errorNotify": this.props.errorNotify,
                                                        "role": this.props.role
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {this.props.user.isLoading && <Loading />}

                                </div>
                            </div>
                            {
                                this.state.hasClickNewMail &&
                                <ModalMail
                                    titleModal="Invio Mail"
                                    userMail={this.state.userMail}
                                    successNotify={this.props.successNotify}
                                    errorNotify={this.props.errorNotify}
                                    warningNotify={this.props.warningNotify}
                                    click={this.sendMail}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modalMail}
                                    key={this.state.userMail}
                                />
                            }

                            {

                                (this.state.hasClickUpdateAddress || this.state.hasClickNewUser || this.state.hasClickRole || this.state.hasClickGroup) &&
                                <ModalUser
                                    titleModal={
                                        this.state.hasClickUpdateAddress ? "Modifica Indirizzi" :
                                            this.state.hasClickRole ? "Assegna Ruolo" :
                                                this.state.hasClickGroup ? "Assegna Gruppo" :
                                                    "Crea Nuovo Utente"
                                    }

                                    click={
                                        this.state.hasClickUpdateAddress ? this.updateAddress :
                                            this.state.hasClickRole ? this.assignRole :
                                                this.state.hasClickNewUser ? this.sendNewUser :
                                                    this.state.hasClickGroup ? this.assignGroup :
                                                        ''
                                    }

                                    toggle={this.toggleModal}
                                    stateModal={this.state.modalUser}

                                    successNotify={this.props.successNotify}
                                    errorNotify={this.props.errorNotify}
                                    warningNotify={this.props.warningNotify}

                                    email={this.state.email}
                                    isReseller={this.state.isReseller}
                                    groupUser={this.state.groupUser}
                                    editUser={this.state.editUser}
                                    editRole={this.state.editRole}
                                    editGroup={this.state.editGroup}
                                    billingAddress={this.state.billingAddress}
                                    shippingAddress={this.state.shippingAddress}

                                    key={this.state.userMail}
                                    role={this.state.role}
                                    group={group}
                                    roleList={this.props.role.roleList}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default withNavigate(Users);