import React, { Component, Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import { FormGroup, FormControlLabel, Switch, FormControl, TextField, InputLabel, Input, InputAdornment, Checkbox, Select, MenuItem, Radio } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './style.scss';

import { countries } from '../countries';

import MultiSelect from 'react-select';
import makeAnimated from 'react-select/animated';

/**
 * Attivazione modale per aggiungere / modificare promo * 
 */
export default class modalCouponBuy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            discount: '',
            discountValue: '',
            priceValue: '',
            toBuy: null,
            startAt: null,
            endAt: null,
            productName: '',
            qty: 1,
            countries: null,
            totalUses: '',
            startItems: '',

            /**
             * Variabile Passata da userInDetails per capire se sto attivando il COUPON da li.
             * Se è true mi sto gestendo il COUPON da userInDetails, se è false la sto gestendo dalla sezione Promo/Coupon
             */
            isEditCouponUserInDetail: this.props.isEditCouponUserInDetail || false,

            descriptionNull: false,
            discountNull: false,
            toBuyNull: false,
            startItemsNull: false,
            emailFromListNull: false,
            startAtNull: false,
            endAtNull: false,
            productNameNull: false,
            qtyNull: false,
            countriesNull: false,

            unlimitedUse: false,
            unlimitedDate: false,

            disableDate: false,
            disableUse: false,
            disableField: false,

            listCoupon: false,

            /**
             * Array rispettivamente di countries e prodotti da mandare al BE durante la creazine di un COUPON.
             */
            countriesFromListArray: null,
            productsFromListArray: null,

            paymentId: '',
            checkedPayment: false,
            selectRadio: '',
            price: '',
            buyDiscount: '',

            email: '',
            isBusiness: true,
            isShipping: true,
            name: '',
            surname: '',
            code: '',
            firstAddress: '',
            secondAddress: '',
            telephone: '',
            cap: '',
            city: '',
            province: '',
            extraInfo: '',
            country: '',
            isFavorite: true,

            editCoupon: this.props.titleModal === 'Modifica Coupon',
        }
    }

    componentDidMount() {
        if (!this.state.isEditCouponUserInDetail) {
            this.props.listDevice();
            this.props.productList();
        }
    }

    /**
     * onChange utilizzata nella multiSelect quando seleziono i dati.
     * Quando seleziono i dati da mandare al BE le trasformo da oggetti {label: xx, value: xx}
     * a un array di value (valore accettato dal BE).
    */
    setDataFromOptionsList = (options, name) => {
        let arrayTemp = [];

        switch (name) {
            case 'countries':
                if (!isNull(options) || !isEmpty(options)) {
                    // eslint-disable-next-line
                    options.map(el => {
                        arrayTemp.push(el.value);
                    });
                }

                this.setState({
                    countriesFromListArray: arrayTemp,
                    countries: null
                });

                break;

            default:
                this.setState({
                    productsFromListArray: null,
                    countriesFromListArray: null,
                    countries: null
                });
                break;
        }
    }

    setChange = (event) => {
        if (event.target.name === 'checkedPayment') {
            this.setState({
                checkedPayment: !this.state.checkedPayment,
                paymentId: '',
                selectRadio: ''
            });
        } else if (event.target.name === 'productName') {
            this.setState({
                productName: event.target.value,
                price: this.returnPriceForProduct(event.target.value)
            });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }

        switch (event.target.name) {
            case 'description': this.setState({ descriptionNull: false }); break;
            case 'discount': this.setState({ discountNull: false }); break;
            case 'startItems': this.setState({ startItemsNull: false }); break;
            case 'productName': this.setState({ productNameNull: false }); break;
            default: break;
        }
    }

    setPromoTime = (newDate, name) => {
        switch (name) {
            case 'startAt':
                this.setState({
                    startAt: newDate,
                    startAtNull: false
                });
                break;

            case 'endAt':
                this.setState({
                    endAt: newDate,
                    endAtNull: false
                });
                break;

            default: break;
        }
    }

    /**
     * Setta lo stato del numero di usi dell'app in modo che sia illimitato
     */
    setUnlimitedUse = () => {
        this.setState({ unlimitedUse: !this.state.unlimitedUse })
        setTimeout(() => {
            if (this.state.unlimitedUse) {
                this.setState({
                    startItems: null,
                    disableUse: true
                });
            } else {
                this.setState({
                    startItems: '',
                    disableUse: false
                });
            }
        }, 300);
    }

    /**
     * Setta lo stato della data di fine in modo che la promo non scada mai
     */
    setUnlimitedDate = () => {
        this.setState({ unlimitedDate: !this.state.unlimitedDate });
        setTimeout(() => {
            if (this.state.unlimitedDate) {
                this.setState({
                    endAt: null,
                    disableDate: true
                });
            } else {
                this.setState({ disableDate: false });
            }
        }, 300);
    }

    setListCoupon = () => {
        this.setState({ listCoupon: !this.state.listCoupon });
    }

    confirm = () => {
        const { canPostCouponApi, infoNotify, errorNotify } = this.props;

        if (this.state.productName === '') {
            this.setState({ productNameNull: true });
            errorNotify('Prodotto al quale associare il Coupon obbligatorio');
        } else if (this.state.description === '') {
            this.setState({ descriptionNull: true });
            errorNotify('Descrizione del Coupon obbligatoria');
        } else if (this.state.startItems === '') {
            this.setState({ startItemsNull: true });
            errorNotify('Numero di volte di utilizzo del Coupon obbligatorio');
        } else if ((this.state.discount === '' || this.state.discount === null) && (this.state.discountValue === '' || this.state.discountValue === null) && (this.state.priceValue === '' || this.state.priceValue === null)) {
            this.setState({ discountNull: true });
            errorNotify('Sconto del Coupon obbligatoria');
        } else if (this.state.discount > 100) {
            this.setState({ discountNull: true });
            this.props.warningNotify('La percentuale del Coupon non puo superare i 100!');
        } else if (this.state.qty > 1000) {
            this.setState({ qtyNull: true });
            errorNotify('Il Coupon non può superare le 1000 unità!');
        } else if (this.state.qty < 1) {
            this.setState({ qtyNull: true });
            errorNotify('Il Coupon non può avere una quantità minore di 1!');
        } else if (this.state.startAt === null) {
            this.setState({ startAtNull: true });
            errorNotify('Data di inizio del Coupon obbligatoria');
        } else if (this.state.endAt === null && !this.state.unlimitedDate) {
            this.setState({ endAtNull: true });
            errorNotify('Data di fine del Coupon obbligatoria');
        } else if (this.state.checkedPayment && this.state.email === '') {
            errorNotify('Inserisci una mail');
        } else if ((!this.state.checkedPayment && this.state.paymentId === '') || this.state.selectRadio === '') {
            errorNotify('Dati per la fatturazione obbligatori');
        } else if (this.state.checkedPayment && this.state.name === '' && this.state.selectRadio === 'privato') {
            errorNotify('Inserisci un nome');
        } else if (this.state.checkedPayment && this.state.surname === '' && this.state.selectRadio === 'privato') {
            errorNotify('Inserisci un cognome');
        } else if (this.state.checkedPayment && this.state.surname === '' && this.state.selectRadio === 'azienda') {
            errorNotify('Inserisci una Ragione Sociale');
        } else if (this.state.checkedPayment && this.state.country === '') {
            errorNotify('Inserisci un country per la fattura');
        } else if (this.state.checkedPayment && this.state.code === '' && this.state.selectRadio === 'azienda') {
            errorNotify('Inserisci una Partita IVA');
        } else {
            // SOLO CREAZIONE
            let data = {
                webUser: null,
                description: this.state.description,
                startAt: this.state.startAt,
                endAt: this.state.endAt,
                productNames: [this.state.productName],
                discount: this.state.discount === '' ? null : (this.state.discount * 0.01).toFixed(2),
                discountValue: this.state.discountValue === '' ? null : parseFloat(this.state.discountValue).toFixed(2),
                priceValue: this.state.priceValue === '' ? null : parseFloat(this.state.priceValue).toFixed(2),
                qty: this.state.qty,
                listCoupon: this.state.listCoupon,
                countries: this.state.countriesFromListArray,
                startItems: this.state.startItems,
                totalUses: this.state.totalUses,
                paymentId: this.state.paymentId === '' ? null : this.state.paymentId,
                paymentFrom: {
                    email: this.state.email,
                    address: {
                        isBusiness: this.state.selectRadio === 'azienda',
                        isShipping: this.state.isShipping,
                        name: this.state.name,
                        surname: this.state.surname,
                        code: this.state.code,
                        firstAddress: this.state.firstAddress,
                        secondAddress: this.state.secondAddress,
                        telephone: this.state.telephone,
                        cap: this.state.cap,
                        city: this.state.city,
                        province: this.state.province,
                        extraInfo: this.state.extraInfo,
                        country: this.state.country,
                        isFavorite: this.state.isFavorite
                    }
                },
                price: this.state.price,
                buyDiscount: (this.state.buyDiscount * 0.01).toFixed(2)
            };

            if (canPostCouponApi) {
                this.props.clickNew(data, true);
            } else {
                infoNotify('Non hai i permessi per creare un coupon!');
            }
            this.props.toggle();
        }
    }

    /**
     * Ritorna il prezzo associato al nome del prodotto che abbiamo in listino.
     * Serve solo a mostrarlo all'utente, poi il BE metterà i prezzi in automatico, 
     * prendendoli dal listino.
     * 
     * @param {*} item 
     */
    returnPriceForProduct = (item) => {
        const { preferences } = this.props;
        let price;
        // eslint-disable-next-line
        preferences.allProducts.map(el => {
            if (el.name === item) {
                price = el.price;
            }
        })
        return price;
    }

    render() {
        const { stateModal, toggle, titleModal, preferences } = this.props;
        const animatedComponents = makeAnimated();
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalCoupon">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <FormControl>
                        <div className="row justify-content-center">
                            <div className="col-12 mb-3">
                                <FormControl className='customSelect'>
                                    <InputLabel>Seleziona il prodotto</InputLabel>
                                    <Select
                                        name="productName"
                                        variant='standard'
                                        value={this.state.productName}
                                        onChange={this.setChange}
                                    >
                                        {
                                            preferences.allProducts.map((el, key) => {
                                                return (
                                                    <MenuItem value={el.name} key={key}> {el.name} </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="col-md-6 col-sm-12 col-12 mb-3">
                                <TextField
                                    name="description"
                                    label="Descrizione"
                                    variant='standard'
                                    value={this.state.description}
                                    onChange={this.setChange}
                                    error={this.state.descriptionNull}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12 col-12 mb-3">
                                <TextField
                                    name="price"
                                    label="Prezzo del prodotto"
                                    variant='standard'
                                    value={this.state.price}
                                />
                            </div>
                        </div>
                    </FormControl>

                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12 col-12 mb-3">
                            <TextField
                                name="qty"
                                type="number"
                                label="Quantità"
                                variant='standard'
                                value={this.state.qty}
                                onChange={this.setChange}
                                error={this.state.qtyNull}
                            />
                        </div>

                        <div className="col-md-8 col-sm-12 col-12 mb-3">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="standard-adornment-switch1" style={{ marginLeft: '15px' }}>Quanti utilizzi?</InputLabel>
                                <Input
                                    id="standard-adornment-switch1"
                                    name="startItems"
                                    type="number"
                                    value={this.state.startItems}
                                    onChange={this.setChange}
                                    error={this.state.startItemsNull}
                                    disabled={this.state.disableUse || this.state.unlimitedUse} // il controllo con maxItems è fatto per evitare la modifica degli usi a dispozione prima della scadenza del COUPON
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton className="iconVisibility"
                                                aria-label="toggle password visibility"
                                                onClick={this.changeShowPassword}
                                            >
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.unlimitedUse}
                                                                onChange={this.setUnlimitedUse}
                                                                value="unlimitedUse"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Illimitato"
                                                    />
                                                </FormGroup>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    <FormControl>
                        <div className='row justify-content-center'>
                            <div className='col-md-4 col-sm-12 col-12 mb-3'>
                                <TextField
                                    name="discount"
                                    type="number"
                                    label="Sconto (%)"
                                    variant='standard'
                                    value={this.state.discount}
                                    onChange={this.setChange}
                                    error={this.state.discountNull}
                                    disabled={this.state.discountValue !== '' || this.state.priceValue !== ''}
                                />
                            </div>

                            <div className='col-md-4 col-sm-12 col-12 mb-3'>
                                <TextField
                                    name="discountValue"
                                    type="number"
                                    label="Sconto (int)"
                                    variant='standard'
                                    value={this.state.discountValue}
                                    onChange={this.setChange}
                                    error={this.state.discountNull}
                                    disabled={this.state.discount !== '' || this.state.priceValue !== ''}
                                />
                            </div>

                            <div className='col-md-4 col-sm-12 col-12 mb-3'>
                                <TextField
                                    name="priceValue"
                                    type="number"
                                    label="Prezzo Fisso"
                                    variant='standard'
                                    value={this.state.priceValue}
                                    onChange={this.setChange}
                                    error={this.state.discountNull}
                                    disabled={this.state.discount !== '' || this.state.discountValue !== ''}
                                />
                            </div>
                        </div>
                    </FormControl>

                    <FormControl>
                        <div className="row justify-content-center">
                            {
                                !this.state.isEditCouponUserInDetail &&
                                <div className="col-md-8 col-sm-12 col-12 mb-3 downSelect">
                                    <MultiSelect
                                        placeholder="Seleziona Paese"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        name="countries"
                                        defaultValue={
                                            this.state.editCoupon ? this.state.countries : undefined
                                        }
                                        options={countries}
                                        onChange={options => this.setDataFromOptionsList(options, 'countries')}
                                    />
                                </div>
                            }

                            {
                                !this.state.editCoupon &&
                                <div className="col-md-4 col-sm-12 col-12 mb-3 downLabel">
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Download Coupons"
                                            control={
                                                <Switch
                                                    checked={this.state.listCoupon}
                                                    disabled={this.state.editCoupon}
                                                    onChange={this.setListCoupon}
                                                    value="listCoupon"
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            }
                        </div>
                    </FormControl>

                    <FormControl>
                        <div className="row justify-content-center">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="col-md-5 col-sm-12 col-12 mb-3">
                                    <DatePicker
                                        id="dateStartAtPromo"
                                        variant="inline"
                                        label="Inizio Promo"
                                        value={isNull(this.state.startAt) ? this.state.startAt : dayjs(this.state.startAt)}
                                        onChange={(newValue) => this.setPromoTime(isNull(newValue) ? null : dayjs(newValue.$d), 'startAt')}
                                        format="DD/MM/YYYY"
                                    />
                                </div>

                                <div className="col-md-5 col-sm-12 col-12 mb-3">
                                    <DatePicker
                                        id="dateEndAtPromo"
                                        variant="inline"
                                        label="Fine Promo"
                                        value={isNull(this.state.endAt) ? this.state.endAt : dayjs(this.state.endAt)}
                                        onChange={(newValue) => this.setPromoTime(isNull(newValue) ? null : dayjs(newValue.$d), 'endAt')}
                                        disabled={this.state.disableDate || this.state.unlimitedDate}
                                        format="DD/MM/YYYY"
                                    />
                                </div>

                                <div className="col-md-2 col-sm-12 col-12 downLabel">
                                    <FormGroup>
                                        <FormControlLabel
                                            className="positionSwitch"
                                            control={
                                                <Switch
                                                    checked={this.state.unlimitedDate}
                                                    onChange={this.setUnlimitedDate}
                                                    value="unlimitedDate"
                                                    color="primary"
                                                />
                                            }
                                            label="Illimitato"
                                        />
                                    </FormGroup>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </FormControl>

                    <FormControl>
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-sm-12 col-12 mb-3">
                                <TextField
                                    name="paymentId"
                                    label="ID Pagamento"
                                    variant='standard'
                                    value={this.state.paymentId}
                                    onChange={this.setChange}
                                    disabled={this.state.checkedPayment}
                                />
                            </div>

                            <div className="col-md-6 col-sm-12 col-12 mb-3">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.setChange}
                                            checked={this.state.checkedPayment}
                                            name="checkedPayment"
                                        />
                                    }
                                    label="Oppure Crea Fattura"
                                />
                            </div>
                        </div>
                    </FormControl>

                    {
                        this.state.checkedPayment &&
                        <Fragment>
                            <h5 className="createPayment">Crea Fattura</h5>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 col-12">
                                    <FormControlLabel value="male" control={
                                        <Radio
                                            checked={this.state.selectRadio === 'azienda'}
                                            onChange={this.setChange}
                                            value="azienda"
                                            name="selectRadio"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    } label="Azienda" />

                                    <FormControlLabel value="male" control={
                                        <Radio
                                            checked={this.state.selectRadio === 'privato'}
                                            onChange={this.setChange}
                                            value="privato"
                                            name="selectRadio"
                                            inputProps={{ 'aria-label': 'B' }}
                                        />
                                    } label="Privato" />
                                </div>
                            </div>
                        </Fragment>
                    }

                    {
                        this.state.selectRadio !== '' &&
                        <Fragment>
                            <div className="row justify-content-center">
                                <div className="col-md-6 col-sm-12 col-12 mb-3">
                                    <TextField
                                        type="number"
                                        name="buyDiscount"
                                        label="Sconto sul prezzo"
                                        variant='standard'
                                        value={this.state.buyDiscount}
                                        onChange={this.setChange}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="buyDiscount"
                                        label="Prezzo scontato"
                                        variant='standard'
                                        value={parseFloat((this.state.price - ((this.state.price * this.state.buyDiscount) / 100)).toFixed(2))}
                                    />
                                </div>

                                <div className="col-md-4 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="surname"
                                        label={this.state.selectRadio === 'azienda' ? "Ragione Sociale" : "Cognome"}
                                        variant='standard'
                                        value={this.state.surname}
                                        onChange={this.setChange}
                                    />
                                </div>

                                <div className="col-md-4 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="name"
                                        label={this.state.selectRadio === 'azienda' ? "Nome Cognome" : "Nome"}
                                        variant='standard'
                                        value={this.state.name}
                                        onChange={this.setChange}
                                    />
                                </div>

                                <div className="col-md-4 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="code"
                                        label={this.state.selectRadio === 'azienda' ? "Partita IVA" : "Codice Fiscale"}
                                        variant='standard'
                                        value={this.state.code}
                                        onChange={this.setChange}
                                    />
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-md-4 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="email"
                                        label="Email"
                                        variant='standard'
                                        value={this.state.email}
                                        onChange={this.setChange}
                                    />
                                </div>

                                <div className="col-md-4 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="firstAddress"
                                        label="Indirizzo 1"
                                        variant='standard'
                                        value={this.state.firstAddress}
                                        onChange={this.setChange}
                                    />
                                </div>

                                <div className="col-md-4 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="telephone"
                                        label="Telefono"
                                        variant='standard'
                                        value={this.state.telephone}
                                        onChange={this.setChange}
                                    />
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-md-2 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="cap"
                                        label="CAP"
                                        variant='standard'
                                        value={this.state.cap}
                                        onChange={this.setChange}
                                    />
                                </div>

                                <div className="col-md-3 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="province"
                                        label="Provincia"
                                        variant='standard'
                                        value={this.state.province}
                                        onChange={this.setChange}
                                    />
                                </div>

                                <div className="col-md-3 col-sm-12 col-12 mb-3">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            labelId="keyCountry"
                                            name="country"
                                            variant='standard'
                                            value={this.state.country}
                                            onChange={this.setChange}
                                        >
                                            {
                                                countries.map((item, k) => {
                                                    return (
                                                        <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-md-4 col-sm-12 col-12 mb-3">
                                    <TextField
                                        name="city"
                                        label="Città"
                                        variant='standard'
                                        value={this.state.city}
                                        onChange={this.setChange}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    }
                </DialogContent>


                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}