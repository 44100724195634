import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import './style.scss';

/**
 * Attivazione modale per aggiungere / modificare player:
 * 
 *      "clickEditPlayer" : attivo la callback per modificare il player
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 *      "licenceKey" : il codice attivazione della licenza del player
 *      "idPlayer" : id del player
 *      "description" : descrizione del player
 *      "userId" : id dell'utente
 *      "vpnPass" : pass vpn associata al player
 *      "newPlayerWithoutUser" : se presente, indica che si sta creando un nuovo player senza utente associato
 */
export default class modalPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenceKey: '',
            idPlayer: '',
            description: '',
            userId: '',
            vpnPass: '',

            hasIdPlayer: false,
            hasDescription: false,
            idPlayerNull: false,
            descriptionNull: false,
            licenceKeyNull: false,
            vpnPassNull: false
        }

        this.setIdPlayer = this.setIdPlayer.bind(this);
        this.setChange = this.setChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            licenceKey: this.props.licence,
            idPlayer: this.props.idPlayer,
            description: this.props.description,
            userId: this.props.userId,
            vpnPass: this.props.vpnPass
        });
    }

    setIdPlayer = (event) => {
        this.setState({ idPlayer: event.target.value, idPlayerNull: false });
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        switch (event.target.name) {
            case 'licenceKey': this.setState({ licenceKeyNull: false }); break;
            case 'description': this.setState({ descriptionNull: false }); break;
            default: break;
        }
    }

    confirm = () => {
        if (this.state.idPlayer === '') {
            this.setState({ idPlayerNull: true });
            this.props.errorNotify('ID del player obbligatorio');
        } else if (this.state.description === '') {
            this.setState({ descriptionNull: true });
            this.props.errorNotify('Inserisci una descrizione');
        } else if (this.state.description.length < 3 || this.state.description.length > 255) {
            this.setState({ descriptionNull: true });
            this.props.errorNotify('Scegli una descrizione compresa tra 3 e 255 caratteri');
        } else if (this.state.licenceKey === '') {
            this.setState({ licenceKeyNull: true });
            this.props.errorNotify('Inserisci una licenza');
        } else if (this.state.licenceKey.length !== 16) {
            this.setState({ licenceKeyNull: true });
            this.props.errorNotify('La licenza deve avere una lunghezza di 16 caratteri');
        } else if (this.state.vpnPass.length !== 16 && this.state.vpnPass.length !== 0) {
            this.setState({ vpnPassNull: true });
            this.props.errorNotify('La Vpn Pass deve avere una lunghezza di 16 caratteri');
        } else {
            let data = {};
            if (this.props.titleModal === 'Modifica Player') {
                data = {
                    id: this.state.idPlayer,
                    description: this.state.description,
                    user: this.state.userId,
                    licenceKey: this.state.licenceKey,
                    vpnPass: this.state.vpnPass
                };
                this.props.clickEditPlayer(data);
            }
            this.props.toggle();
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;

        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalPlayer">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label text-end">ID Player</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" value={this.state.idPlayer} onChange={this.setIdPlayer} disabled={this.state.idPlayerNull} readOnly={titleModal === 'Modifica Player'} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput2" className="form-label text-end">Licence Key</label>
                        <input type="number" className="form-control" id="exampleFormControlInput2" name="licenceKey" maxLength={16} value={this.state.licenceKey} onChange={this.setChange} disabled={this.state.licenceKeyNull} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput3" className="form-label text-end">Descrizione</label>
                        <input type="text" className="form-control" id="exampleFormControlInput3" name="description" maxLength={16} value={this.state.description} onChange={this.setChange} disabled={this.state.descriptionNull} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput4" className="form-label text-end">ID Utente</label>
                        <input type="text" className="form-control" id="exampleFormControlInput4" name="userId" maxLength={16} value={this.state.userId} readOnly />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput5" className="form-label text-end">VPN Pass</label>
                        <input type="text" className="form-control" id="exampleFormControlInput5" name="vpnPass" maxLength={16} value={this.state.vpnPass} onChange={this.setChange} />
                    </div>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}