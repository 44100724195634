import React, { Component } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { VPN_SETTING_PROXY } from '../../config';
import { countries } from '../countries';
import './style.scss';
import { isEmpty } from '../../utility';


/**
 * Attivazione modale per countryVats
 *      "sendEditVpnSettingList" : se clicco OK, modifico la lista vpn setting
 *      "titleModal" : il titolo della modale quando si apre
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalVpnSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: null,
            addCountry: {}
        }
    }

    setChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,

            addCountry: {
                [event.target.value]: {
                    isEnabled: true
                }
            }
        });
    }

    confirmSend = () => {
        if (isEmpty(this.state.country) && this.props.type === "new") {
            this.props.errorNotify("Selezionare un Paese");

        } else if ((isEmpty(this.state.addCountry) && this.props.type === "new") || (isEmpty(this.props.updateCountry) && this.props.type === "remove")) {
            this.props.errorNotify("Errore riprovare");

        } else {
            this.props.sendEditVpnSettingList(VPN_SETTING_PROXY, this.props.type === "remove" ? this.props.updateCountry : this.state.addCountry)
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;

        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalMail">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <FormControl className="choose-form">
                        {
                            this.props.type === "remove" ?
                                <div>
                                    Vuoi Rimuovere <b>{Object.keys(this.props.updateCountry)}</b> dalla lista ?
                                </div>
                                :
                                <div className="row justify-content-center">
                                    <div className='col-10'>
                                        Il Paese aggiunto avrà una fake VPN
                                    </div>

                                    <div className="col-10">
                                        <FormControl className='customSelect'>
                                            <InputLabel>Country</InputLabel>
                                            <Select
                                                labelId="keyCountry"
                                                name="country"
                                                variant='standard'
                                                value={this.state.country}
                                                onChange={this.setChange}
                                            >
                                                {
                                                    countries.map((item, k) => {
                                                        return (
                                                            <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                        }
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={() => this.confirmSend()}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}