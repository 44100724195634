import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import './style.scss';
import { countries } from '../../common/countries';

/**
 * Attivazione modale per aggiungere una vpn machine
 * 
 * titleModal: titolo della modale
 * click: funzione da attivare al click di OK
 * stateModal: stato della modale (booleano)
 * successNotify
 * errorNotify
 * warningNotify
 * toggle: per chiudere la modale
 * key: non viene passato per le operazioni di modale ma solo per permetter di resettare il costruttore ogni volta che apro la modale
 */
export default class modalVpnMachine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: this.props.vpnTempEdit.key || '',
            host: this.props.vpnTempEdit.credentials ? this.props.vpnTempEdit.credentials.host || '' : '',
            rootUser: this.props.vpnTempEdit.credentials ? this.props.vpnTempEdit.credentials.userId || '' : '',
            rootPassword: this.props.vpnTempEdit.credentials ? this.props.vpnTempEdit.credentials.password || '' : '',
            mantainer: this.props.vpnTempEdit.mantainer || '',
            country: this.props.vpnTempEdit.country || '',
            publicHost: this.props.vpnTempEdit.publicHost || '',
            city: this.props.vpnTempEdit.city || '',
            model: this.props.vpnTempEdit.model || '',
            os: this.props.vpnTempEdit.os || '',
            cpuDescription: this.props.vpnTempEdit.cpuDescription || '',
            cpuCores: this.props.vpnTempEdit.cpuCores || '',
            networkInterfaceName: this.props.vpnTempEdit.networkInterfaceName || '',
            maxBandwidth: this.props.vpnTempEdit.maxBandwidth || '',
            isProxy: this.props.vpnTempEdit.isProxy || false,

            formIsValid: false,
            showPassword: false
        }

        this.setChange = this.setChange.bind(this);
        this.changeShowPassword = this.changeShowPassword.bind(this);
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setChecked = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    }

    changeShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    /**
     * Se i dati sono corretti, prepara l'oggetto da mandare al BE
     * per aggiungere o modificare una VPN
     */
    sendVpn = () => {
        if (this.formIsValid()) {
            let data = {};
            if (this.state.key === '') {
                data = {
                    host: this.state.host,
                    rootUser: this.state.rootUser,
                    rootPass: this.state.rootPassword,
                    mantainer: this.state.mantainer,
                    country: this.state.country,
                    publicHost: this.state.publicHost,
                    city: this.state.city,
                    model: this.state.model,
                    os: this.state.os,
                    cpuDescription: this.state.cpuDescription,
                    cpuCores: this.state.cpuCores,
                    networkInterfaceName: this.state.networkInterfaceName,
                    maxBandwidth: this.state.maxBandwidth,
                    isProxy: this.state.isProxy
                }
            } else {
                data = {
                    key: this.state.key,
                    host: this.state.host,
                    rootUser: this.state.rootUser,
                    rootPass: this.state.rootPassword,
                    mantainer: this.state.mantainer,
                    country: this.state.country,
                    publicHost: this.state.publicHost,
                    city: this.state.city,
                    model: this.state.model,
                    os: this.state.os,
                    cpuDescription: this.state.cpuDescription,
                    cpuCores: this.state.cpuCores,
                    networkInterfaceName: this.state.networkInterfaceName,
                    maxBandwidth: this.state.maxBandwidth,
                    isProxy: this.state.isProxy
                }
            }

            this.props.click(data);

        } else {
            this.props.errorNotify('Compila tutti i campi!');
        }
    }

    /**
     * Controlla se i campi sono stati inseriti tutti
     */
    formIsValid = () => {
        if (this.state.isProxy === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.host === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.rootUser === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.rootPassword === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.mantainer === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.country === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.city === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.model === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.os === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.cpuDescription === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.cpuCores === 0 || this.state.cpuCores === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.networkInterfaceName === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.maxBandwidth === 0 || this.state.maxBandwidth === '') {
            this.setState({ formIsValid: true });
            return false;
        } else {
            this.setState({ formIsValid: false });
            return true;
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;

        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalVpn">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <FormControl className="choose-form">
                        <div className="row">
                            <div className="col-6">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="isProxy"
                                            checked={this.state.isProxy}
                                            onChange={this.setChecked}
                                        />
                                    }
                                    label={this.state.isProxy ? "Proxy" : "VPN"}
                                />
                            </div>

                            <div className="col-6">
                                <TextField
                                    name="host"
                                    variant='standard'
                                    label="Host"
                                    value={this.state.host}
                                    onChange={this.setChange}
                                    error={this.state.host === '' && this.state.formIsValid}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    name="rootUser"
                                    variant='standard'
                                    label="Root User"
                                    value={this.state.rootUser}
                                    onChange={this.setChange}
                                    error={this.state.rootUser === '' && this.state.formIsValid}
                                />
                            </div>

                            <div className="col-6">
                                <FormControl className='customSelect'>
                                    <InputLabel htmlFor="standard-adornment-password">Root Password</InputLabel>
                                    <Input
                                        name="rootPassword"
                                        label="rootPassword"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={this.state.rootPassword}
                                        onChange={this.setChange}
                                        error={this.state.rootPassword === '' && this.state.formIsValid}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.changeShowPassword}
                                                >
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    name="mantainer"
                                    variant='standard'
                                    label="Mantainer"
                                    value={this.state.mantainer}
                                    onChange={this.setChange}
                                    error={this.state.mantainer === '' && this.state.formIsValid}
                                />
                            </div>

                            <div className="col-6">
                                <FormControl className='customSelect'>
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        name="country"
                                        variant='standard'
                                        value={this.state.country}
                                        onChange={this.setChange}
                                        error={this.state.country === '' && this.state.formIsValid}
                                    >
                                        {
                                            countries.map((item, k) => {
                                                return (
                                                    <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    name="publicHost"
                                    variant='standard'
                                    label="Public Host"
                                    value={this.state.publicHost}
                                    onChange={this.setChange}
                                    error={this.state.publicHost === '' && this.state.formIsValid}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    name="city"
                                    variant='standard'
                                    label="City"
                                    value={this.state.city}
                                    onChange={this.setChange}
                                    error={this.state.city === '' && this.state.formIsValid}
                                />
                            </div>

                            <div className="col-6">
                                <TextField
                                    name="model"
                                    variant='standard'
                                    label="Model"
                                    value={this.state.model}
                                    onChange={this.setChange}
                                    error={this.state.model === '' && this.state.formIsValid}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    name="cpuDescription"
                                    variant='standard'
                                    label="CPU Description"
                                    value={this.state.cpuDescription}
                                    onChange={this.setChange}
                                    error={this.state.cpuDescription === '' && this.state.formIsValid}
                                />
                            </div>

                            <div className="col-6">
                                <TextField
                                    name="networkInterfaceName"
                                    variant='standard'
                                    label="Network Interface Name"
                                    value={this.state.networkInterfaceName}
                                    onChange={this.setChange}
                                    error={this.state.networkInterfaceName === '' && this.state.formIsValid}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <TextField
                                    name="os"
                                    variant='standard'
                                    label="OS"
                                    value={this.state.os}
                                    onChange={this.setChange}
                                    error={this.state.os === '' && this.state.formIsValid}
                                />
                            </div>

                            <div className="col-4">
                                <TextField
                                    name="cpuCores"
                                    variant='standard'
                                    label="CPU Cores"
                                    type="number"
                                    value={this.state.cpuCores}
                                    onChange={this.setChange}
                                    error={this.state.cpuCores === '' && this.state.formIsValid}
                                />
                            </div>

                            <div className="col-4">
                                <TextField
                                    name="maxBandwidth"
                                    variant='standard'
                                    label="Max Bandwidth"
                                    type="number"
                                    value={this.state.maxBandwidth}
                                    onChange={this.setChange}
                                    error={this.state.maxBandwidth === '' && this.state.formIsValid}
                                />
                            </div>
                        </div>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.sendVpn}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}