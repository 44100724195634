import React, { Component } from 'react';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@mui/material';
import moment from 'moment';
import MyTooltip from '../../common/tooltip';
import { returnNationality } from '../../common/actions/returnNationality';

import '../pages/style.scss';
import SelectorCopy from '../../common/selectorCopy';
import { isEmpty, withNavigate } from '../../utility';

class expanableComponentBox extends Component {

    /**
     * Dato un ID device, vai alla schermata dei device portando id player nei filtri
     */
    goToVpnPass = (idVpnPass) => {
        this.props.setKeyVpnPassForDetail(idVpnPass);
        this.props.handleNavigation(this.props.navigate, `/vpn/pass`);
    }

    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    render() {
        const { id, description, country, model, platform, version, wiFiPwd, wiFiSSD, createdAt } = this.props.data;

        return (
            <div className="tabExpanablePass">
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-pass">ID Vpn Box</InputLabel>
                                <Input
                                    id="id-vpnBox"
                                    value={id}
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={id}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                            {
                                                <span className="clickGoTo" onClick={() => { this.goToVpnPass(id) }}>
                                                    <MyTooltip
                                                        title="Vai alla VPN Pass Associata"
                                                        position="top"
                                                        content={<i className="fas fa-share-square"></i>}
                                                    />
                                                </span>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(description) ? '-' : description}
                                label="Descrizione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(country) ? '-' : (country === '0' || country === null) ? 'Auto' : returnNationality(country)}
                                label="Paese"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={isEmpty(model) ? '-' : model}
                                label="Modello"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(platform) ? '-' : platform}
                                label="Piattaforma"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(version) ? '-' : version}
                                label="Versione"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={isEmpty(wiFiPwd) ? '-' : wiFiPwd}
                                label="Wifi Password"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(wiFiSSD) ? '-' : wiFiSSD}
                                label="Wifi SSID"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(createdAt) ? '-' : this.formatData(createdAt)}
                                label="CreatedAt"
                                variant='standard'
                            />
                        </div>
                    </div>
                </FormControl>
            </div>
        )
    }
}

export default withNavigate(expanableComponentBox);