import React, { Component, Fragment } from 'react';
import axios from 'axios';

import { API_GET_BACKOFFICE_METHODS } from '../../config';
import './style.scss';
import { randomString } from '../../common/actions/randomString';
import ModalRole from '../../common/modal/modalRole';
import Modal from '../../common/modal';
import Loading from '../../common/loading';
import DataTable from 'react-data-table-component';
import SelectorCopy from '../../common/selectorCopy';
import ActionMenu from '../components/actionMenu';
import CSVExport from '../../common/csvExport';
import ExpanableComponent from '../components/expanableComponent';
import { controlRole } from '../../common/actions/controlRole';

export default class role extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            modalRole: false,
            modal: false,

            // contiene la lista dei ruoli dopo la chiamata al BE
            getRoleList: [],

            // contiene la lista dei metodi dopo la chiamata al BE
            methodsList: {},

            // contiene il nome del ruolo da passare alla modale di modifica
            roleName: '',

            // Variabile che va a true se apro modifica ruolo e a false se apro crea ruolo
            editRole: false,

            // Variabile che va a true se apro assegna ruolo
            assignRole: false,

            // variabili che gestiscono l'apertura della modale di creazione ruolo o modifica ruolo
            hasClickNewRole: false,
            hasClickUpdateRole: false,
            hasClickAssignRole: false,
            hasClickDeleteRole: false,

            titleModal: '',
            contentModal: ''

        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        this.getMethodsList();
        this.props.getRoleList();
    }

    /**
    * Apre la modale per inserire un nuovo ruolo o la modale per modificare un ruolo
    */
    toggleModal = () => {
        this.setState({
            hasClickNewRole: false,
            hasClickUpdateRole: false,
            hasClickAssignRole: false,
            hasClickDeleteRole: false,
            editRole: false,
            assignRole: false,
            roleName: '',
            titleModal: '',
            contentModal: '',
            modalRole: false,
            modal: false
        });
    }

    /**
     * Attiva la modale per inserire un nuovo ruolo
     */
    hasClickNewRole = () => {
        this.setState({
            hasClickNewRole: true,
            shortId: randomString()
        });

        setTimeout(() => {
            this.setState(prevState => ({ modalRole: !prevState.modalRole }));
        }, 200);
    }

    /**
    * Apre la modale di edit e passa il nome del ruolo associato
    */
    hasClickUpdateRole = (role) => {
        this.setState({
            roleName: role.roleName,
            hasClickUpdateRole: true,
            editRole: true
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalRole: !prevState.modalRole }));
        }, 200);
    }

    /**
    * Apre la modale di assegnazione ruolo e passa il nome del ruolo associato
    */
    hasClickAssignRole = (role) => {
        this.setState({
            roleName: role.roleName,
            hasClickAssignRole: true,
            assignRole: true
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalRole: !prevState.modalRole }));
        }, 200);
    }

    /**
    * Apre la modale di elimina ruolo e passa il nome dell'id del ruolo da eliminare
    */
    hasClickDeleteRole = (role) => {
        this.setState({
            roleName: role.roleName,
            hasClickDeleteRole: true,
            assignRole: true,
            titleModal: 'Elimina Ruolo',
            contentModal: 'Sei sicuro di voler eliminare il ruolo ' + role.roleName + ' ?',
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
    * Funzione che restituisce tutti i metodi disponibili per i ruoli e li categorizza per controller 
    * (es. vengono raggruppati tutti sotto lo stesso nome, i ruoli di Vpn si trovano sotto la sezione Vpn etc.)
    */
    getMethodsList = async () => {
        this.setState({ isLoading: true });
        try {
            let map = {};
            await axios.get(`${API_GET_BACKOFFICE_METHODS}`)
                .then((response) => {
                    response.data.forEach(element => {
                        if (element.controller in map) {
                            let list = map[element.controller];
                            list.push(element);
                            map[element.controller] = list;
                        } else {
                            let list = [];
                            list.push(element);
                            map[element.controller] = list;
                        }
                    });
                });
            this.setState({
                methodsList: map,
                isLoading: false,
            });
        } catch (result) {
            const { errorNotify, infoNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response) {
                    case 403: infoNotify('Non hai i permessi per visualizzare i metodi dei ruoli!'); break;
                    default: errorNotify(`Error ${result.response.status} su ${API_GET_BACKOFFICE_METHODS} - GET`); break;
                }
            }
        }
    }

    /**
    * Funzione passata alla modale e invocata per la creazione di un nuovo ruolo
    */
    sendNewRole = (data) => {
        this.props.newRole(data);
        this.toggleModal();
    }

    /**
    * Funzione passata alla modale e invocata per la modifica di un ruolo
    */
    updateRole = (data) => {
        this.props.updateRole(data);
        this.toggleModal();
    }

    /**
    * Chiamata per assegnare un ruolo ad un utente
    */
    assignRole = (data) => {
        this.props.assignRole(data);
        this.toggleModal();
    }

    /**
    * Chiamata per assegnare un ruolo ad un utente
    */
    deleteRole = (data) => {
        this.props.deleteRole(data);
        this.toggleModal();
    }

    /**
    * Action menu per la modifica e la cancellazione di un ruolo
    */
    actionClick = (action, role) => {
        switch (action) {
            case 'editRole': this.hasClickUpdateRole(role); break;
            case 'assignRole': this.hasClickAssignRole(role); break;
            case 'deleteRole': this.hasClickDeleteRole(role); break;
            default: break;
        }
    }

    render() {
        const { role } = this.props;

        const columns = [
            {
                name: '',
                selector: row => "",
                maxWidth: '0px',
                minWidth: '0px'
            },
            {
                name: 'Ruolo',
                selector: row => <SelectorCopy item={row.roleName} infoNotify={this.props.infoNotify} />,
                minWidth: '300px'
            },

            {
                selector: row => <ActionMenu actionClick={this.actionClick} row={row} role={role} data={role.roleList} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                <div className="role">
                    {(role.isLoading || this.state.isLoading) && <Loading />}

                    <div className="row title justify-content-center">
                        <div className="col-6 containerTitle">
                            <h3>Ruoli</h3>
                        </div>

                        <div className="col-6  styleCSV text-end">
                            <CSVExport
                                data={this.state.getRoleList}
                                filename="list-roles.csv"
                                label="Scarica la lista dei ruoli"
                            />
                        </div>

                        <div className="col-12 text-center">
                            {
                                controlRole(role.role, "api/Role", "POST") &&
                                <button className="btn btn-success addMarginForBtn" onClick={this.hasClickNewRole}>
                                    <i className="fas fa-plus" style={{ 'marginRight': '5px' }}></i>
                                    Aggiungi Ruolo
                                </button>
                            }
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-12">
                            {
                                <DataTable
                                    columns={columns}
                                    data={role.roleList}
                                    customStyles={customStyles}
                                    noHeader
                                    striped
                                    highlightOnHover
                                    pointerOnHover
                                    clearSelectedRows
                                    expandableRows={this.state.isLoading ? false : true}
                                    expandOnRowClicked
                                    expandableRowsComponent={ExpanableComponent}
                                    expandableRowsComponentProps={{
                                        "handleNavigation": this.props.handleNavigation
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>

                {
                    (this.state.hasClickNewRole || this.state.hasClickUpdateRole || this.state.hasClickAssignRole) &&
                    <ModalRole
                        titleModal={this.state.hasClickNewRole ? "Crea Nuovo Ruolo" : this.state.hasClickUpdateRole ? "Modifica Ruolo" : this.state.hasClickAssignRole ? "Assegna Ruolo" : ""}
                        listDevice={this.props.getListDevice}
                        clickNew={this.sendNewRole}
                        clickEdit={this.updateRole}
                        clickAssign={this.assignRole}
                        toggle={this.toggleModal}
                        stateModal={this.state.modalRole}
                        roleName={this.state.roleName}
                        editRole={this.state.editRole}
                        assignRole={this.state.assignRole}
                        methodsList={this.state.methodsList}
                        getRoleList={this.props.getRoleList}
                        successNotify={this.props.successNotify}
                        errorNotify={this.props.errorNotify}
                        warningNotify={this.props.warningNotify}
                        key={this.state.shortId}
                        promo={this.props.promo}
                    />
                }

                {
                    this.state.hasClickDeleteRole &&
                    <Modal
                        click={() => { this.deleteRole(this.state.roleName) }}
                        titleModal={this.state.titleModal}
                        contentModal={this.state.contentModal}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}
                    />
                }

            </Fragment>
        )
    }
}