import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import FormFiltersPlaylist from '../components/formFiltersPlaylist';

import { isEmpty, isNil } from 'lodash';
import Date from '../../common/date';
import SelectorCopy from '../../common/selectorCopy';
import Loading from '../../common/loading';
import CSVExport from '../../common/csvExport';
import ModalPlayList from '../../common/modal/modalManageList';
import ActionMenu from '../components/actionMenu';

import './style.scss';
import { controlRole } from '../../common/actions/controlRole';
import { withNavigate } from '../../utility';

class Playlists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingForRole: true,
            isLoading: false,

            openFilters: true,
            openFiltersDesktop: false,

            // informazioni che dovranno andare nel CSV da esportare
            headersCSV: [],
            dataCSV: [],


            hasClickEdit: false,
            hasClickRemove: false,
            hasClickNewPlaylist: false,
            titleModal: '',

            title: '',
            source: '',
            deviceId: '',
            keyPlaylist: '',

            type: '',
            modal: false
        }

        this.setLoading = this.setLoading.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }


    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare le playlists, viene rispedito alla home
        if (!isEmpty(this.props.role.player)) {
            if (!controlRole(this.props.role.player, "api/Player/PlayLists", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le playlists!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare i player, viene rispedito alla home
        if (!isEmpty(this.props.role.player)) {
            if (!controlRole(this.props.role.player, "api/Player/PlayLists", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le playlists!')

            } else
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
        }
    }

    setLoading = (value) => {
        this.setState({ isLoading: value });
    }

    toggleModal = () => {
        this.setState({
            hasClickNewPlaylist: false,
            hasClickEdit: false,
            hasClickRemove: false,
            modal: false,
            titleModal: '',
            title: '',
            source: '',
            type: '',
            deviceId: '',
            keyPlaylist: ''
        });
    }

    /**
     * Apre modale per aggiungere una nuova playlist
     */
    hasClickNewPlaylist = () => {
        this.setState({
            hasClickNewPlaylist: true,
            titleModal: 'Nuova Playlist',
            type: 'newPlaylist'
        });

        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
    * Al click di modifica playlist, riceve in input:
    * - la chiave della playlist da modificare
    * - il titolo della playlist da modificare
    * - l'url della playlist da modificare
    * 
    * e apre la modale
    */
    hasClickEdit = (keyPlaylist, deviceId, title, url) => {
        this.setState({
            hasClickEdit: true,
            titleModal: 'Modifica Playlist',
            title: title,
            source: url,
            keyPlaylist: keyPlaylist,
            deviceId: deviceId,
            type: 'edit'
        });

        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Rimuove una playlist.
     * @param key della playlist da rimuovere
     */
    hasClickRemove = (keyPlaylist, deviceId) => {
        this.setState({
            hasClickRemove: true,
            titleModal: 'Elimina Playlist',
            keyPlaylist: keyPlaylist,
            deviceId: deviceId,
            type: 'delete'
        });

        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Invia la nuova playlist al player.
     * Vengon inviati i seguenti parametri:
     * - titolo della playlist
     * - url della playlist
     * - deviceId a cui associare la playlist
     */
    sendNewPlaylist = (title, url, deviceId) => {
        this.props.sendNewPlaylist({
            title: title,
            url: url,
            deviceId: deviceId,
        });

        this.toggleModal();

        setTimeout(() => {
            if (!isEmpty(this.props.player.paramsGetPlayListTemp)) {
                // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
                this.props.getAllPlayList(this.props.player.paramsGetPlayListTemp);
            }
        }, 500);
    }

    /**
     * Modifica la playlist del player.
     * Riceve i seguenti parametri:
     * - title (titolo della playlist)
     * - url (url della sorgente)
     * - key (chiave della playlist)
     */
    sendEditPlaylist = (title, url, keyPlaylist) => {
        this.props.sendModifyPlaylist({
            title: title,
            url: url,
            deviceId: this.state.deviceId,
            playListId: keyPlaylist
        });

        this.toggleModal();

        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.getAllPlayList(this.props.player.paramsGetPlayListTemp);
        }, 500);
    }

    /**
     * Rimuove la playlist dal player.
     * Vengono passati i seguenti parametri:
     * - key della playlist
     * - device id a cui è associata la playlist
     */
    removePlaylist = (keyPlaylist) => {
        this.props.removePlaylist({
            key: keyPlaylist,
            deviceId: this.state.deviceId,
        });

        this.toggleModal();

        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.getAllPlayList(this.props.player.paramsGetPlayListTemp);
        }, 500);
    }

    /**
     * Al click del menu opzioni, controlla quale opzione sia stata selezionata 
     * e di conseguenza esegue l'azione per quella riga.
     * 
     * @param action è il tipo di azione selezionato
     * @param dataPlayer è l'oggetto contente le informazioni del player selezionato dell'utente per cui è richiesta quell'azione
     */
    actionClick = (action, keyPlaylist, deviceId, title, source) => {
        switch (action) {
            case 'editPlaylist': this.hasClickEdit(keyPlaylist, deviceId, title, source); break;
            case 'removePlaylist': this.hasClickRemove(keyPlaylist, deviceId); break;
            default: break;
        }
    }

    render() {
        const { player, getAllPlayList, role } = this.props;

        const columns = [
            {
                name: 'Titolo Playlist',
                selector: row => <SelectorCopy item={row.title} infoNotify={this.props.infoNotify} />,
                maxWidth: '250px'
            },
            {
                name: 'URL Playlist',
                selector: row => <SelectorCopy item={row.source} infoNotify={this.props.infoNotify} />,
            },
            {
                name: 'Device ID',
                selector: row => <SelectorCopy item={row.deviceId} infoNotify={this.props.infoNotify} />,
                maxWidth: '250px'
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />,
                maxWidth: '200px'
            },
            {
                selector: row => <ActionMenu actionClick={this.actionClick} playlist={row} role={role} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true,
                minWidth: '75px'
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {
                    this.state.loadingForRole ? <Loading /> :
                        <Fragment>
                            <div className="row">
                                <div className="col-12 licenceDesktop">
                                    <div className="contentFilterSearch">
                                        <FormFiltersPlaylist
                                            getAllPlayList={getAllPlayList}
                                            changeDns={this.props.changeDns}
                                            player={player}
                                            role={role}

                                            warningNotify={this.props.warningNotify}
                                            infoNotify={this.props.infoNotify}
                                            errorNotify={this.props.errorNotify}
                                            successNotify={this.props.successNotify}
                                        />
                                    </div>

                                    <div className="contentUser">
                                        <div className="row titleMobile">
                                            <div className="col-6">
                                                <h2 className='inlineBlock'>Playlist</h2>
                                                {
                                                    player.totalPlaylist &&
                                                    <div className="mybadge">
                                                        {player.totalPlaylist}
                                                    </div>
                                                }
                                            </div>

                                            {
                                                !isNil(player.listPlaylist) &&
                                                <div className="col-6 text-end">
                                                    <CSVExport
                                                        data={player.listPlaylist}
                                                        filename="list-licences.csv"
                                                        label="Scarica la lista delle playlist selezionate"
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div className='row'>
                                            <div className="col text-center">
                                                {
                                                    controlRole(role.player, "api/Player/AddPlaylist", "POST") &&
                                                    <button className="btn btn-success" style={{ 'margin': '10px' }} onClick={this.hasClickNewPlaylist}>
                                                        <i className="fas fa-plus"></i> Aggiungi Playlist
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                                <DataTable
                                                    columns={columns}
                                                    data={player.listPlaylist}
                                                    customStyles={customStyles}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    clearSelectedRows
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {(player.isLoading || player.isLoadingChange || this.state.isLoading) && <Loading />}
                                </div>
                            </div>
                        </Fragment>
                }

                {
                    (this.state.hasClickEdit || this.state.hasClickNewPlaylist || this.state.hasClickRemove) &&
                    <ModalPlayList
                        titleModal={this.state.titleModal}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}

                        title={this.state.title}
                        url={this.state.source}
                        deviceId={this.state.deviceId}
                        type={this.state.type}
                        keyPlaylist={this.state.keyPlaylist}
                        errorNotify={this.props.errorNotify}

                        clickNew={this.sendNewPlaylist}
                        clickEdit={this.sendEditPlaylist}
                        clickRemove={this.removePlaylist}
                    />
                }
            </Fragment>
        )
    }
}

export default withNavigate(Playlists);