import React, { Component, Fragment } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { countries } from '../../common/countries';
import { Select, FormControl, MenuItem, InputLabel, TextField, InputAdornment, Input } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { randomString } from '../actions/randomString';
import MultiSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { isEmpty, isNull } from 'lodash';

/**
 * Attivazione modale per creare/modificare utente
 *     
 */
export default class
    modalUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.email || '',
            password: randomString(),
            isReseller: this.props.isReseller || false,

            // variabili indirizzo fatturazione predefinito
            billingId: this.props.billingAddress.id || '',
            billingName: this.props.billingAddress.name || '',
            billingSurname: this.props.billingAddress.surname || '',
            billingCountry: this.props.billingAddress.country || '',
            billingFirstAddress: this.props.billingAddress.firstAddress || '',
            billingSecondAddress: this.props.billingAddress.secondAddress || '',
            billingCity: this.props.billingAddress.city || '',
            billingCap: this.props.billingAddress.cap || '',
            billingProvince: this.props.billingAddress.province || '',
            billingTelephone: this.props.billingAddress.telephone || '',
            billingExtraInfo: this.props.billingAddress.extraInfo || '',

            // variabili indirizzo spedizione predefinito
            shippingId: this.props.shippingAddress.id || '',
            shippingName: this.props.shippingAddress.name || '',
            shippingSurname: this.props.shippingAddress.surname || '',
            shippingCountry: this.props.shippingAddress.country || '',
            shippingFirstAddress: this.props.shippingAddress.firstAddress || '',
            shippingSecondAddress: this.props.shippingAddress.secondAddress || '',
            shippingCity: this.props.shippingAddress.city || '',
            shippingCap: this.props.shippingAddress.cap || '',
            shippingProvince: this.props.shippingAddress.province || '',
            shippingTelephone: this.props.shippingAddress.telephone || '',
            shippingExtraInfo: this.props.shippingAddress.extraInfo || '',

            /**
             * variabili per visualizzare l'indirizzo di spedizione o l'indirizzo di fatturazione
             */
            isBilling: false,
            isShipping: false,
            disabledIsShipping: false,
            disabledIsBilling: false,

            group: isNull(this.props.groupUser) ? [] : this.props.groupUser,
            role: this.props.role || '',

            formIsValid: false,
            showPassword: false,
            editUser: this.props.editUser,
            roleList: this.props.roleList,
            editRole: this.props.editRole,
            editGroup: this.props.editGroup,

            user: ''
        }

        this.setChange = this.setChange.bind(this);
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setReseller = () => {
        this.setState({ isReseller: !this.state.isReseller });
    }

    changeShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    confirmSend = () => {
        // creazione dell'utente
        if (this.formIsValid() && !this.state.editUser && !this.state.editRole && !this.state.editGroup) {
            let data = {
                email: this.state.email,
                password: this.state.password,
                isReseller: this.state.isReseller,
                name: this.state.name,
                surname: this.state.surname,
                country: this.state.country,
            }
            this.props.click(data);
        }
        // modifica dell'utente
        else if (this.formIsValid() && this.state.editUser) {
            if (this.state.isBilling) {
                let data = {
                    id: this.state.billingId,
                    name: this.state.billingName,
                    surname: this.state.billingSurname,
                    firstAddress: this.state.billingFirstAddress,
                    secondAddress: this.state.billingSecondAddress,
                    code: "",
                    city: this.state.billingCity,
                    cap: this.state.billingCap,
                    telephone: this.state.billingTelephone,
                    country: this.state.billingCountry,
                    province: this.state.billingProvince,
                    extraInfo: this.state.billingExtraInfo,
                    isFavorite: true,
                    isShipping: false,
                }
                this.props.click(data, this.state.email);
            }

            else {
                let data = {
                    id: this.state.shippingId,
                    name: this.state.shippingName,
                    surname: this.state.shippingSurname,
                    firstAddress: this.state.shippingFirstAddress,
                    secondAddress: this.state.shippingSecondAddress,
                    code: "",
                    city: this.state.shippingCity,
                    cap: this.state.shippingCap,
                    telephone: this.state.shippingTelephone,
                    country: this.state.shippingCountry,
                    province: this.state.shippingProvince,
                    extraInfo: this.state.shippingExtraInfo,
                    isFavorite: true,
                    isShipping: true,
                }
                this.props.click(data, this.state.email);
            }
        }

        // modifica del ruolo
        else if (this.formIsValid() && this.state.editRole) {
            let data = {
                roleName: this.state.role,
                user: this.state.email
            }
            this.props.click(data);
        }
        // modifica del gruppo
        else if (this.state.editGroup) {
            let data = {
                userId: this.state.email,
                groupsNames: this.sanitizeUserGroupBeforeSend(this.state.group)
            }
            this.props.click(data);
        }
        else {
            this.props.errorNotify('Compila correttamente tutti i campi!');
        }
    }

    /**
     * Controlla se i campi sono stati inseriti tutti
     */
    formIsValid = () => {
        if (!this.state.editRole && !this.state.editUser) {
            if (this.state.email === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.password === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.name === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.surname === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.country === '') {
                this.setState({ formIsValid: true });
                return false;
            } else {
                this.setState({ formIsValid: false });
                return true;
            }
        }
        else if (this.state.editUser && !this.state.isShipping) {
            if (this.state.email === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.billingId === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.billingName === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.billingSurname === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.billingCountry === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.billingFirstAddress === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.billingCap === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.billingProvince === '') {
                this.setState({ formIsValid: true });
                return false;
            } else {
                this.setState({ formIsValid: false });
                return true;
            }
        }
        else if (this.state.editUser && !this.state.isBilling) {
            if (this.state.email === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.shippingId === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.shippingName === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.shippingSurname === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.shippingCountry === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.shippingFirstAddress === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.shippingCap === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.shippingProvince === '') {
                this.setState({ formIsValid: true });
                return false;
            } else {
                this.setState({ formIsValid: false });
                return true;
            }
        }
        else {
            if (this.state.email === '') {
                this.setState({ formIsValid: true });
                return false;
            } else if (this.state.role === '' || this.state.role === null) {
                this.setState({ formIsValid: true });
                return false;
            } else {
                this.setState({ formIsValid: false });
                return true;
            }
        }
    }

    setGropForUser = (options) => {
        this.setState({ group: options });
    }

    sanitizeUserGroupBeforeSend = (data) => {
        let tempData = [];
        if (!isEmpty(data)) {
            // eslint-disable-next-line
            data.map(el => {
                if (el.value === undefined) {
                    tempData.push(el.name)
                } else {
                    tempData.push(el.value)
                }
            })
            return tempData;
        } else
            return [];
    }

    sanitizeUserGroup = (data) => {
        let tempData = [];
        if (!isEmpty(data)) {
            // eslint-disable-next-line
            data.map(el => {
                tempData.push({
                    value: el.name,
                    label: el.name
                })
            })
            return tempData;
        } else
            return [];
    }

    /**
 * Setta le variabili in modo che si attivino le opzioni per l'indirizzo di fatturazione
 */
    setBilling = () => {
        this.setState({
            isBilling: !this.state.isBilling,
            isShipping: false,
        });
        setTimeout(() => {
            this.setState({
                disabledIsShipping: !this.state.isBilling ? false : true
            });
        }, 200);
    }

    /**
     * Setta le variabili in modo che si attivino le opzioni per l'indirizzo di spedizione
     */
    setShipping = () => {
        this.setState({
            isShipping: !this.state.isShipping,
            isBilling: false,
        });
        setTimeout(() => {
            this.setState({
                disabledIsBilling: !this.state.isShipping ? false : true
            });
        }, 200);
    }

    render() {
        const { stateModal, toggle, titleModal, group } = this.props;
        const animatedComponents = makeAnimated();
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalUser">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {
                        this.state.editUser &&
                        <Fragment>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.isBilling} onChange={this.setBilling} value="unlimitedUse" disabled={this.state.disabledIsBilling} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Fatturazione</label>
                            </div>

                            {
                                !isEmpty(this.state.shippingId) &&
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2" checked={this.state.isShipping} onChange={this.setShipping} value="unlimitedUse" disabled={this.state.disabledIsShipping} />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">Spedizione</label>
                                </div>
                            }
                        </Fragment>
                    }

                    {
                        (!this.state.editRole && !this.state.editGroup) &&
                        <FormControl className="choose-form customSelect">
                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="email"
                                        variant='standard'
                                        label="Email"
                                        value={this.state.email}
                                        onChange={this.setChange}
                                        error={this.state.email === '' && this.state.formIsValid}
                                        disabled={this.state.editUser}
                                    />
                                </div>
                                {
                                    // modifica la password solo se è un nuovo utente
                                    !this.state.editUser &&

                                    <div className="col-6">
                                        <FormControl>
                                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                            <Input
                                                name="password"
                                                variant='standard'
                                                label="Password"
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                value={this.state.password}
                                                onChange={this.setChange}
                                                error={this.state.password === '' && this.state.formIsValid}
                                                readOnly
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.changeShowPassword}
                                                        >
                                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                }
                            </div>

                            {
                                (!this.state.editRole && !this.state.editGroup && !this.state.editUser) &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextField
                                                name="name"
                                                variant='standard'
                                                label="Name"
                                                value={this.state.name}
                                                onChange={this.setChange}
                                                error={this.state.name === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <TextField
                                                name="surname"
                                                variant='standard'
                                                label="Surname"
                                                value={this.state.surname}
                                                onChange={this.setChange}
                                                error={this.state.surname === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <FormControl className='customSelect'>
                                                <InputLabel>Country</InputLabel>
                                                <Select
                                                    name="country"
                                                    variant='standard'
                                                    value={this.state.country}
                                                    onChange={this.setChange}
                                                    error={this.state.country === '' && this.state.formIsValid}
                                                >
                                                    {
                                                        countries.map((item, k) => {
                                                            return (
                                                                <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            {
                                this.state.isBilling &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextField
                                                name="billingName"
                                                variant='standard'
                                                label="Name"
                                                value={this.state.billingName}
                                                onChange={this.setChange}
                                                error={this.state.billingName === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <TextField
                                                name="billingSurname"
                                                variant='standard'
                                                label="Surname"
                                                value={this.state.billingSurname}
                                                onChange={this.setChange}
                                                error={this.state.billingSurname === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <TextField
                                                name="billingFirstAddress"
                                                variant='standard'
                                                label="First Address"
                                                value={this.state.billingFirstAddress}
                                                onChange={this.setChange}
                                                error={this.state.billingFirstAddress === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <TextField
                                                name="billingSecondAddress"
                                                variant='standard'
                                                label="Second Address"
                                                value={this.state.billingSecondAddress}
                                                onChange={this.setChange}
                                                error={this.state.billingSecondAddress === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <TextField
                                                name="billingCity"
                                                variant='standard'
                                                label="City"
                                                value={this.state.billingCity}
                                                onChange={this.setChange}
                                                error={this.state.billingCity === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                name="billingCap"
                                                variant='standard'
                                                type="number"
                                                label="CAP"
                                                value={this.state.billingCap}
                                                onChange={this.setChange}
                                                error={this.state.billingCap === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                name="billingTelephone"
                                                variant='standard'
                                                type="number"
                                                label="Telephone"
                                                value={this.state.billingTelephone}
                                                onChange={this.setChange}
                                                error={this.state.billingTelephone === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <FormControl className='customSelect'>
                                                <InputLabel>Country</InputLabel>
                                                <Select
                                                    name="billingCountry"
                                                    variant='standard'
                                                    value={this.state.billingCountry}
                                                    onChange={this.setChange}
                                                    error={this.state.billingCountry === '' && this.state.formIsValid}
                                                >
                                                    {
                                                        countries.map((item, k) => {
                                                            return (
                                                                <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                name="billingProvince"
                                                variant='standard'
                                                label="Province"
                                                value={this.state.billingProvince}
                                                onChange={this.setChange}
                                                error={this.state.billingProvince === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                name="billingExtraInfo"
                                                variant='standard'
                                                label="Extra Info"
                                                value={this.state.billingExtraInfo}
                                                onChange={this.setChange}
                                                error={this.state.billingExtraInfo === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            {
                                this.state.isShipping &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextField
                                                name="shippingName"
                                                variant='standard'
                                                label="Name"
                                                value={this.state.shippingName}
                                                onChange={this.setChange}
                                                error={this.state.shippingName === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <TextField
                                                name="shippingSurname"
                                                variant='standard'
                                                label="Surname"
                                                value={this.state.shippingSurname}
                                                onChange={this.setChange}
                                                error={this.state.shippingSurname === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <TextField
                                                name="shippingFirstAddress"
                                                variant='standard'
                                                label="First Address"
                                                value={this.state.shippingFirstAddress}
                                                onChange={this.setChange}
                                                error={this.state.shippingFirstAddress === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <TextField
                                                name="shippingSecondAddress"
                                                variant='standard'
                                                label="Second Address"
                                                value={this.state.shippingSecondAddress}
                                                onChange={this.setChange}
                                                error={this.state.shippingSecondAddress === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <TextField
                                                name="shippingCity"
                                                variant='standard'
                                                label="City"
                                                value={this.state.shippingCity}
                                                onChange={this.setChange}
                                                error={this.state.shippingCity === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                name="shippingCap"
                                                variant='standard'
                                                type="number"
                                                label="CAP"
                                                value={this.state.shippingCap}
                                                onChange={this.setChange}
                                                error={this.state.shippingCap === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                name="shippingTelephone"
                                                variant='standard'
                                                type="number"
                                                label="Telephone"
                                                value={this.state.shippingTelephone}
                                                onChange={this.setChange}
                                                error={this.state.shippingTelephone === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <FormControl className='customSelect'>
                                                <InputLabel>Country</InputLabel>
                                                <Select
                                                    name="shippingCountry"
                                                    variant='standard'
                                                    value={this.state.shippingCountry}
                                                    onChange={this.setChange}
                                                    error={this.state.shippingCountry === '' && this.state.formIsValid}
                                                >
                                                    {
                                                        countries.map((item, k) => {
                                                            return (
                                                                <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                name="shippingProvince"
                                                variant='standard'
                                                label="Province"
                                                value={this.state.shippingProvince}
                                                onChange={this.setChange}
                                                error={this.state.shippingProvince === '' && this.state.formIsValid}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                name="shippingExtraInfo"
                                                variant='standard'
                                                label="Extra Info"
                                                value={this.state.shippingExtraInfo}
                                                onChange={this.setChange}
                                                error={this.state.shippingExtraInfo === '' && this.state.formIsValid}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </FormControl>
                    }

                    {
                        this.state.editRole &&
                        <FormControl>
                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="email"
                                        variant='standard'
                                        label="Email"
                                        value={this.state.email}
                                        onChange={this.setChange}
                                        error={this.state.email === '' && this.state.formIsValid}
                                        disabled={this.state.editRole}
                                    />
                                </div>

                                <div className="col-6">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Ruolo</InputLabel>
                                        <Select
                                            name="role"
                                            variant='standard'
                                            value={this.state.role}
                                            onChange={this.setChange}
                                            error={this.state.role === '' && this.state.formIsValid}
                                        >
                                            {
                                                this.state.roleList.map((item, k) => {
                                                    return (
                                                        <MenuItem key={k} value={item.roleName}>{item.roleName}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </FormControl>

                    }

                    {
                        this.state.editGroup &&
                        <FormControl>
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <TextField
                                        name="email"
                                        variant='standard'
                                        label="Email"
                                        value={this.state.email}
                                        onChange={this.setChange}
                                        disabled={this.state.editGroup}
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ 'marginTop': '20px' }}>
                                <div className="col-12">
                                    <MultiSelect
                                        defaultValue={this.sanitizeUserGroup(this.state.group)}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={this.sanitizeUserGroup(group.listGroup)}
                                        name="group"
                                        onChange={this.setGropForUser}
                                    />
                                </div>
                            </div>
                        </FormControl>

                    }
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}