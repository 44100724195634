import React, { Component } from 'react';
import { NativeSelect, FormControl } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Loading from '../../../common/loading/loadingInBlock';
import { isNull } from '../../../utility';

export default class gains extends Component {

    render() {
        const { labelsGrossGainsMonths, valueGrossGainsMonths, labelsNetGainsMonths, valueNetGainsMonths, setDateIssuedStartChange, setDateIssuedEndChange, paymentIssuedAtStart, paymentIssuedAtEnd, loadingGraph6, onChange, gainsChange } = this.props;

        let totaleRicaviLordi = 0;
        let totaleRicaviNetti = 0;

        // eslint-disable-next-line
        valueGrossGainsMonths.map(el => {
            totaleRicaviLordi += el;
        })

        // eslint-disable-next-line
        valueNetGainsMonths.map(el => {
            totaleRicaviNetti += el;
        })

        const optionsGains = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: !gainsChange ? 'Ricavi Lordi: ' + totaleRicaviLordi + ' €' : 'Ricavi Netti: ' + totaleRicaviNetti + ' €',
                    font: {
                        size: 20
                    }
                }
            },

            scales: {
                y: {
                    ticks: {
                        beginAtZero: true
                    }
                }
            }
        };

        const dataGrossGains = {
            labels: labelsGrossGainsMonths,
            datasets: [
                {
                    label: "Ricavi",
                    backgroundColor: 'rgba(42,110,255, 0.5)',
                    data: valueGrossGainsMonths,
                    hoverBackgroundColor: 'rgba(42,110,255, 0.5)',
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        const dataNetGains = {
            labels: labelsNetGainsMonths,
            datasets: [
                {
                    label: "Ricavi",
                    backgroundColor: 'rgba(42,110,255, 0.5)',
                    data: valueNetGainsMonths,
                    hoverBackgroundColor: 'rgba(42,110,255, 0.5)',
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChartGains">
                    <FormControl className="licenceGains">
                        <div className="row">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="col-4">
                                    <DatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        label="Inizo"
                                        defaultValue={dayjs(paymentIssuedAtStart)}
                                        onChange={(newValue) => setDateIssuedStartChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                    />
                                </div>

                                <div className="col-4">
                                    <DatePicker
                                        variant="inline"
                                        label="Fine"
                                        defaultValue={dayjs(paymentIssuedAtEnd)}
                                        onChange={(newValue) => setDateIssuedEndChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        format="DD/MM/YYYY"
                                    />
                                </div>
                            </LocalizationProvider>

                            <div className="col-4" style={{ margin: 'auto auto 0 auto' }}>
                                <NativeSelect
                                    name="gainsChange"
                                    value={gainsChange}
                                    onChange={onChange}
                                >
                                    <option className="colorMonth" value={false}>Ricavi Lordi</option>
                                    <option className="colorMonth" value={true}>Ricavi Netti</option>
                                </NativeSelect>
                            </div>
                        </div>
                    </FormControl>

                    {
                        loadingGraph6 ? <Loading /> :
                            <Bar options={optionsGains} data={!gainsChange ? dataGrossGains : dataNetGains} />
                    }

                </div>
            </div>
        )
    }
}