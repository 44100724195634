import React, { Component, Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './style.scss';
import { MAINTENANCE } from '../../config';
import TabModalChange from './tabModalChange';
import { isNull } from '../../utility';

export default class modalChangeLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeChangeLog: this.props.typeChangeLog || '',

            newTypeChangeLog: '',
            dateCreation: null,
            version: this.props.version || '',
            oldVersion: this.props.version || '',
            createdAt: this.props.dateVersion || null,
            logsIt: this.props.type === 'editVersionLog' ? this.props.logsIt : '',
            logsEn: this.props.type === 'editVersionLog' ? this.props.logsEn : '',
            logsEs: this.props.type === 'editVersionLog' ? this.props.logsEs : '',
            logsDe: this.props.type === 'editVersionLog' ? this.props.logsDe : '',
            logsFr: this.props.type === 'editVersionLog' ? this.props.logsFr : '',

            tabActive: 'IT'
        }
    }

    /**
     * Rimuove gli spazi bianchi all'inizio e alla fine di una stringa( gli spazi tra due parole restano es. 'Prova prova ').
     */
    removeWhiteSpace = (roleName) => {
        let removeSpace = roleName.trim();
        return removeSpace;
    }

    confirmSend = () => {
        const { type, newChangeLog, errorNotify, toggle, editVersionLog, editWarning } = this.props;

        /* regex che controlla il formato della versione (X.X.X). possono essere inserite fino a 3 cifre prima del punto 
           (es. XXX.X.XX è un formato valido, XXXX.X. non è un formato valido) */
        const regex = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
        const valid = regex.test(this.state.version);

        switch (type) {
            case 'newChangeLog':
                if (this.state.newTypeChangeLog === '') {
                    errorNotify('Inserisci un nome per aggiungere un nuovo prodotto!');
                } else {
                    newChangeLog(this.state.newTypeChangeLog);
                    toggle();
                }
                break;

            case 'newVersionLog':
            case 'editVersionLog':
                if (this.state.logsIt === '') {
                    errorNotify('Inserisci i logs italiani!');
                } else if (this.state.logsEn === '') {
                    errorNotify('Inserisci i logs inglesi!');
                } else if (this.state.logsEs === '') {
                    errorNotify('Inserisci i logs spagnoli!');
                } else if (this.state.logsDe === '') {
                    errorNotify('Inserisci i logs tedeschi!');
                } else if (this.state.logsFr === '') {
                    errorNotify('Inserisci i logs francesi!');
                } else if (this.state.version === '') {
                    errorNotify('Inserisci la versione!');
                } else if (this.state.createdAt === null) {
                    errorNotify('Inserisci la data di creazione!');
                } else {
                    if (!valid) {
                        errorNotify('La versione deve essere in formato X.X.X');
                    } else {
                        if (this.state.typeChangeLog === MAINTENANCE) {
                            editWarning({
                                "productName": this.state.typeChangeLog,
                                "oldVersion": this.state.oldVersion,
                                "version": this.state.version,
                                "createdAt": this.state.createdAt,
                                "logLanguage": {
                                    "it-IT": this.removeWhiteSpace(this.state.logsIt),
                                    "en-GB": this.removeWhiteSpace(this.state.logsEn),
                                    "es-SP": this.removeWhiteSpace(this.state.logsEs),
                                    "de-DE": this.removeWhiteSpace(this.state.logsDe),
                                    "fr-FR": this.removeWhiteSpace(this.state.logsFr)
                                }
                            });
                        } else {
                            editVersionLog({
                                "productName": this.state.typeChangeLog,
                                "version": this.state.version,
                                "createdAt": this.state.createdAt,
                                "logLanguage": {
                                    "it-IT": this.removeWhiteSpace(this.state.logsIt),
                                    "en-GB": this.removeWhiteSpace(this.state.logsEn),
                                    "es-SP": this.removeWhiteSpace(this.state.logsEs),
                                    "de-DE": this.removeWhiteSpace(this.state.logsDe),
                                    "fr-FR": this.removeWhiteSpace(this.state.logsFr)
                                }
                            });
                        }
                        toggle();
                    }
                }
                break;

            default: break;
        }
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setDate = (newDate) => {
        this.setState({ createdAt: newDate });
    }

    handleClick = (id) => {
        if (id === 1) this.setState({ tabActive: 'IT' });
        if (id === 2) this.setState({ tabActive: 'EN' });
        if (id === 3) this.setState({ tabActive: 'ES' });
        if (id === 4) this.setState({ tabActive: 'DE' });
        if (id === 5) this.setState({ tabActive: 'FR' });
    };

    render() {
        const { stateModal, toggle, titleModal, type, typeChangeLog } = this.props;

        const items = [{
            id: 1,
            content:
                <div className={this.state.tabActive === "IT" ? "navIcon active" : "navIcon"}>
                    <img src={`${process.env.PUBLIC_URL}/flags/IT.svg`} alt='err_img' />
                    <p className="labelNav">Italiano</p>
                </div>
        },
        {
            id: 2,
            content:
                <div className={this.state.tabActive === "EN" ? "navIcon active" : "navIcon"}>
                    <img src={`${process.env.PUBLIC_URL}/flags/UK.svg`} alt='err_img' />
                    <p className="labelNav">INGLESE</p>
                </div>
        },
        {
            id: 3,
            content:
                <div className={this.state.tabActive === "ES" ? "navIcon active" : "navIcon"}>
                    <img src={`${process.env.PUBLIC_URL}/flags/ES.svg`} alt='err_img' />
                    <p className="labelNav">Spagnolo</p>
                </div>
        },
        {
            id: 4,
            content:
                <div className={this.state.tabActive === "DE" ? "navIcon active" : "navIcon"}>
                    <img src={`${process.env.PUBLIC_URL}/flags/DE.svg`} alt='err_img' />
                    <p className="labelNav">Tedesco</p>
                </div>
        },
        {
            id: 5,
            content:
                <div className={this.state.tabActive === "FR" ? "navIcon active" : "navIcon"}>
                    <img src={`${process.env.PUBLIC_URL}/flags/FR.svg`} alt='err_img' />
                    <p className="labelNav">Francese</p>
                </div>
        }];

        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalChangeLog">
                <DialogTitle>
                    {
                        this.state.typeChangeLog === MAINTENANCE ? 'WARNING SUL SITO' :
                            titleModal
                    }

                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {
                        type === 'newChangeLog' &&
                        <div className="row justify-content-center">
                            <div className="col-5">
                                <TextField
                                    name="newTypeChangeLog"
                                    label="Nuovo Prodotto"
                                    variant='standard'
                                    value={this.state.newTypeChangeLog}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    }

                    {
                        (type === 'newVersionLog' || type === 'editVersionLog') &&
                        <Fragment>
                            <div className="row justify-content-center">
                                {
                                    this.state.typeChangeLog === MAINTENANCE &&
                                    <div className="col-6">
                                        <FormControl className='customSelect'>
                                            <InputLabel>Colore dell'ALERT</InputLabel>
                                            <Select
                                                labelId="version"
                                                name="version"
                                                variant='standard'
                                                value={this.state.version}
                                                onChange={this.onChange}
                                            >
                                                <MenuItem value="0.0.1">ROSSO</MenuItem>
                                                <MenuItem value="0.0.2">VERDE</MenuItem>
                                                <MenuItem value="0.0.3">GIALLO</MenuItem>
                                                <MenuItem value="0.0.4">BLUE</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                }

                                {
                                    this.state.typeChangeLog !== MAINTENANCE &&
                                    <Fragment>
                                        <div className="col-6">
                                            <TextField
                                                name="version"
                                                label="Versione"
                                                variant='standard'
                                                value={this.state.version}
                                                onChange={this.onChange}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    id="dateCreation"
                                                    variant="inline"
                                                    label="Data Creazione Commit"
                                                    value={isNull(this.state.createdAt) ? this.state.createdAt : dayjs(this.state.createdAt)}
                                                    onChange={(newValue) => this.setDate(isNull(newValue) ? null : dayjs(newValue.$d))}
                                                    format="DD/MM/YYYY"
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </Fragment>
                                }
                            </div>

                            <div className='myNavTabs'>
                                {
                                    items.map(item => (
                                        <div key={item.id} onClick={() => this.handleClick(item.id)}>
                                            {item.content}
                                        </div>
                                    ))
                                }
                            </div>

                            {
                                this.state.tabActive === "IT" &&
                                <TabModalChange
                                    type={type}
                                    typeChangeLog={typeChangeLog}
                                    logsIt={this.state.logsIt}
                                    onChange={this.onChange}
                                    language="it-IT"
                                />
                            }

                            {
                                this.state.tabActive === "EN" &&
                                <TabModalChange
                                    type={type}
                                    typeChangeLog={typeChangeLog}
                                    logsEn={this.state.logsEn}
                                    onChange={this.onChange}
                                    language="en-GB"
                                />
                            }

                            {
                                this.state.tabActive === "ES" &&
                                <TabModalChange
                                    type={type}
                                    typeChangeLog={typeChangeLog}
                                    logsEs={this.state.logsEs}
                                    onChange={this.onChange}
                                    language="es-SP"
                                />
                            }

                            {
                                this.state.tabActive === "DE" &&
                                <TabModalChange
                                    type={type}
                                    typeChangeLog={typeChangeLog}
                                    logsDe={this.state.logsDe}
                                    onChange={this.onChange}
                                    language="de-DE"
                                />
                            }

                            {
                                this.state.tabActive === "FR" &&
                                <TabModalChange
                                    type={type}
                                    typeChangeLog={typeChangeLog}
                                    logsFr={this.state.logsFr}
                                    onChange={this.onChange}
                                    language="fr-FR"
                                />
                            }
                        </Fragment>
                    }
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}