import React, { Component } from 'react';
import { controlRole } from '../../common/actions/controlRole';

export default class actionMenuPass extends Component {

    render() {
        const { data, role, vpnPass, actionClick } = this.props;
        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        controlRole(role.vpnPass, "api/VpnPass", "PUT", true) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { actionClick('edit', vpnPass); }}>
                                <i className="far fa-edit orange"></i>
                                Modifica
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.vpnPass, "api/VpnPass", "DELETE", true) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { actionClick('remove', vpnPass); }}>
                                <i className="far fa-trash-alt red"></i>
                                Elimina
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}