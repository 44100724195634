import React, { Component } from 'react';
import { FormControl, TextField } from '@mui/material';
import moment from 'moment';
import Axios from 'axios';

import { randomString } from '../../common/actions/randomString';
import { API_VPN_GET_PEER } from '../../config';
// import SelectorCopy from '../../common/selectorCopy';

export default class expanableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // id del peer associato
            peerId: this.props.data.id,

            // dopo la chiamata al BE, questi dati vengono popolati
            dataPeer: {
                vpnPass: '',
                authorizationIp: '',
                connectedAt: '',
                connectionType: '',
                country: '',
                description: '',
                deviceId: '',
                deviceType: '',
                disconnectedAt: '',
                ipAddress: '',
                isp: '',
                machineHost: '',
                machineId: '',
                model: '',
                platform: '',
                privateIp: '',
                privateKey: '',
                publicKey: '',
                serverPublicKey: '',
                version: '',
            },

            shortId: randomString()
        }
        this.getDataPeer();
    }

    getDataPeer = async () => {
        try {
            let response = await Axios.get(`${API_VPN_GET_PEER}/${this.state.peerId}`);
            this.setState({
                dataPeer: {
                    vpnPass: response.data.vpnPass,
                    authorizationIp: response.data.authorizationIp,
                    connectedAt: response.data.connectedAt,
                    connectionType: response.data.connectionType,
                    country: response.data.country,
                    description: response.data.description,
                    deviceId: response.data.deviceId,
                    deviceType: response.data.deviceType,
                    disconnectedAt: response.data.disconnectedAt,
                    ipAddress: response.data.ipAddress,
                    isp: response.data.isp,
                    machineHost: response.data.machineHost,
                    machineId: response.data.machineId,
                    model: response.data.model,
                    platform: response.data.platform,
                    privateIp: response.data.privateIp,
                    privateKey: response.data.privateKey,
                    publicKey: response.data.publicKey,
                    serverPublicKey: response.data.serverPublicKey,
                    version: response.data.version,
                }
            });

        } catch (result) {
            const { errorNotify } = this.props;
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(result.response.status, ' - Errore sui dati del peer'); break;
                }
            }
        }
    }


    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    render() {
        return (
            <div className="tabExpanablePlayer">
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.vpnPass || ' '}
                                label="VPN Pass"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.authorizationIp || ' '}
                                label="Authorization Ip"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.formatData(this.state.dataPeer.connectedAt) || ' '}
                                label="Connesso il"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.connectionType || ' '}
                                label="Tipo di Connessione"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.country || ' '}
                                label="Country"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.description || ' '}
                                label="Descrizione"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.deviceId || ' '}
                                label="Device ID"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.deviceType || ' '}
                                label="Tipo Device"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.version || ' '}
                                label="Versione"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.formatData(this.state.dataPeer.disconnectedAt) || ' '}
                                label="Disconnesso il"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.ipAddress || ' '}
                                label="Indirizzo IP"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.serverPublicKey || ' '}
                                label="Server Public Key"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.isp || ' '}
                                label="ISP"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.machineHost || ' '}
                                label="Macchina Host"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.machineId || ' '}
                                label="ID Macchina"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.model || ' '}
                                label="Model"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.platform || ' '}
                                label="Piattaforma"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dataPeer.privateIp || ' '}
                                label="IP Privato"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={this.state.dataPeer.privateKey || ' '}
                                label="Private Key"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                value={this.state.dataPeer.publicKey || ' '}
                                label="Public Key"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                </FormControl>
            </div>
        )
    }
}