import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';

import './style.scss';
import FormFilter from '../components/formFiltersVpnBoxStock';
import Loading from '../../common/loading';
import Date from '../../common/date';
import { controlRole } from '../../common/actions/controlRole';
import SelectorCopy from '../../common/selectorCopy';
import ModalVpnStock from '../../common/modal/modalVpnStock';
import ModalRenderStock from '../../common/modal/modalRenderStock';
import ExpanableComponent from '../components/expanableComponent';
import ActionMenu from '../components/actionMenu';
import { returnNationality } from '../../common/actions/returnNationality';
import { isEmpty, withNavigate } from '../../utility';

class vpnBoxStock extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // variabili di controllo per modali
            hasClickAddMovementVpnBoxStock: false,
            hasClickDeleteVpnBoxStock: false,
            hasClickAssociateTracking: false,
            hasClickStartRender: false,
            hasClickEndRender: false,
            hasClickRebook: false,

            type: '',

            titleModal: '',
            contentModal: '',
            modal: false,
            modalRender: false,
            // openFilters: true,
            // openFiltersDesktop: false,

            // usato per salvare temporaneamente uno stock
            dataTemp: {},

            loadingForRole: true,
            isLoading: false
        }
    }

    // Commento per tenere traccia del significato del tipo di operazioni per lo stock (operationType):
    // L = Carico delle box in magazzino
    // B = Box pronte per la spedizione
    // U = Box vendute
    // R = Procedura di Reso aperta
    // RR = Procedura di reso chiusa
    // D = Procedura di assistenza aperta
    // DD = Procedura di assistenza chiusa
    // A = Annullato


    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare le stock delle vpnBox, viene rispedito alla home
        if (!isEmpty(this.props.role.vpnBoxStock)) {
            if (!controlRole(this.props.role.vpnBoxStock, "api/VpnBoxStocks", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le Stock delle Vpn Box!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.props.getVpnBoxStockInfo();
                    // mi preparo la lista dei prodotti se l'utente ha bisogno di eseguire dei movimenti
                    this.props.getProductList();
                    this.props.getCountryPurchasableList();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare le le stock delle vpnBox, viene rispedito alla home
        if (!isEmpty(this.props.role.vpnBoxStock)) {
            if (!controlRole(this.props.role.vpnBoxStock, "api/VpnBoxStocks", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le Stock delle Vpn Box!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.props.getVpnBoxStockInfo();
                    // mi preparo la lista dei prodotti se l'utente ha bisogno di eseguire dei movimenti
                    this.props.getProductList();
                    this.props.getCountryPurchasableList();
                }
            }
        }
    }

    /**
     * Se clicca aggiungi Vpn Box in magazzino, attiva la modale
     */
    hasClickAddMovementVpnBoxStock = () => {
        this.setState({
            hasClickAddMovementVpnBoxStock: true,
            titleModal: "Aggiungi Movimento",
            type: 'addMovement'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se clicca elimina stock di Vpn Box dal magazzino, attiva la modale
     */
    hasClickDeleteVpnBoxStock = (data) => {
        this.setState({
            hasClickDeleteVpnBoxStock: true,
            titleModal: "Elimina BOX dal magazzino",
            type: 'delete',
            dataTemp: data
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se clicca associa Vpn Box al tracking, attiva la modale
     */
    hasClickAssociateTracking = (data) => {
        this.setState({
            hasClickAssociateTracking: true,
            titleModal: "Associa il Numero di Spedizione",
            type: 'associateTracking',
            dataTemp: data
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se si vuole avviare un reso o assistenza tecnica, attiva la modale
     */
    hasClickStartRender = (data) => {
        this.setState({
            hasClickStartRender: true,
            titleModal: "Avvia Reso / Assistenza Tecnica",
            dataTemp: data,
            type: 'startRender'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalRender: !prevState.modalRender }));
        }, 200);
    }

    /**
     * Se si vuole chiudere un reso o assistenza tecnica, attiva la modale
     */
    hasClickEndRender = (data) => {
        this.setState({
            hasClickEndRender: true,
            titleModal: "Chiudi Reso / Assistenza Tecnica",
            dataTemp: data,
            type: 'endRender'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalRender: !prevState.modalRender }));
        }, 200);
    }

    /**
     * Se si vuole rimettere un stock in booking, attiva la modale
     */
    hasClickRebook = (data) => {
        this.setState({
            hasClickRebook: true,
            titleModal: "Rimetti in Booking",
            dataTemp: data,
            type: 'rebook'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalRender: !prevState.modalRender }));
        }, 200);
    }

    /**
     * Chiude le modali per la gestione degli stock, resettando i valori
     */
    toggleModal = () => {
        this.setState({
            type: '',
            hasClickAddMovementVpnBoxStock: false,
            hasClickDeleteVpnBoxStock: false,
            hasClickAssociateTracking: false,
            hasClickStartRender: false,
            hasClickEndRender: false,
            hasClickRebook: false,
            dataTemp: {},
            modal: false,
            modalRender: false
        });
    }

    setLoading = (value) => {
        this.setState({ isLoading: value });
    }

    render() {
        const {
            role, stock, preferences, user,
            addVpnStockMovement, sellVpnStock, getVpnBoxStock,
            removeVpnStockMovement, associateTracking,
            startRender, rebook, endRender
        } = this.props;

        const columns = [
            {
                name: 'Stato',
                selector: row =>
                    <span>
                        {(row.operationType === 'R' || row.operationType === 'D') && <b className='green'>Aperto</b>}
                        {(row.operationType === 'RR' || row.operationType === 'DD') && <b className='red'>Chiuso</b>}
                        {(row.operationType === 'A') && <b className='red'>Annullato</b>}
                        {(row.operationType === 'U') && <b className='green'>Attivo</b>}
                    </span>,
                width: '100px',
                center: true,
                omit: (stock.valueTypeForTitle === 'L' || stock.valueTypeForTitle === 'B')
            },
            {
                name: 'ID Movimento',
                selector: row => <SelectorCopy item={row.movId} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'Prodotto',
                selector: row => row.productName,
                omit: stock.valueTypeForTitle === 'L'
            },
            {
                name: 'Country',
                selector: row =>
                    row.payment !== null ?
                        row.payment.shipping !== null ?
                            returnNationality(row.payment.shipping.address.country) : ''
                        : '',
                omit: stock.valueTypeForTitle === 'L'
            },
            {
                name: 'Quantità',
                selector: row => row.qty,
                width: '90px',
                center: true
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />,
                center: true
            },
            {
                selector: row =>
                    <ActionMenu
                        hasClickDeleteVpnBoxStock={this.hasClickDeleteVpnBoxStock}
                        hasClickAssociateTracking={this.hasClickAssociateTracking}
                        hasClickStartRender={this.hasClickStartRender}
                        hasClickEndRender={this.hasClickEndRender}
                        hasClickRebook={this.hasClickRebook}
                        data={stock.listVpnBoxStockMovement}
                        row={row}
                        role={role}
                        user={user}
                    />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {(this.state.loadingForRole || stock.isLoading || preferences.isLoading || this.state.isLoading) && <Loading />}

                <div className="row">
                    <div className="col-12 stockDesktop">

                        <div className="contentFilterSearch">
                            <FormFilter
                                setLoading={this.setLoading}

                                stock={stock}
                                role={role}
                                preferences={preferences}

                                getVpnBoxStock={getVpnBoxStock}

                                warningNotify={this.props.warningNotify}
                                infoNotify={this.props.infoNotify}
                                errorNotify={this.props.errorNotify}
                                successNotify={this.props.successNotify}
                            />
                        </div>

                        <div className="contentUser">
                            <div className="contentBoxStatistics">
                                <div className="row justify-content-center">
                                    <div className="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-8 mb1">
                                        <h6>
                                            Box disponibili per l'acquisto
                                            <div className="mybadge">
                                                {stock.available}
                                            </div>
                                        </h6>
                                    </div>

                                    <div className="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-8 mb1">
                                        <h6>
                                            Box pronte per la spedizione
                                            <div className="mybadge">
                                                {stock.busy}
                                            </div>
                                        </h6>
                                    </div>

                                    <div className="col-xl-3 col-lg-4 col-md-8 col-sm-8 col-8 mb1">
                                        <h6>
                                            Box vendute
                                            <div className="mybadge">
                                                {stock.unload}
                                            </div>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-md-4 col-sm-12 col-12">
                                    {stock.valueTypeForTitle === 'B' && <h2>Box da Spedire</h2>}
                                    {stock.valueTypeForTitle === 'L' && <h2>Box a Magazzino</h2>}
                                    {stock.valueTypeForTitle === 'U' && <h2>Box Vendute</h2>}
                                    {(stock.valueTypeForTitle === 'R' || stock.valueTypeForTitle === 'RR') && <h2>Resi</h2>}
                                    {(stock.valueTypeForTitle === 'D' || stock.valueTypeForTitle === 'DD') && <h2>Assistenza</h2>}
                                </div>
                                <div className="col-md-4 col-sm-12 col-12 text-center">
                                    {
                                        controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "POST") &&
                                        <button className="btn btn-success" onClick={this.hasClickAddMovementVpnBoxStock}>
                                            <i className="fas fa-plus" style={{ 'marginRight': '10px' }} />
                                            Aggiungi Movimento
                                        </button>
                                    }
                                </div>
                                <div className="col-md-4 col-sm-12 col-12" />
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                    <DataTable
                                        columns={columns}
                                        data={stock.listVpnBoxStockMovement}
                                        customStyles={customStyles}
                                        noHeader
                                        striped
                                        highlightOnHover
                                        pointerOnHover
                                        expandableRows={!this.state.isLoading}
                                        expandOnRowClicked={stock.valueTypeForTitle !== 'L'}
                                        expandableRowsComponent={ExpanableComponent}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div >

                {
                    (
                        this.state.hasClickAddMovementVpnBoxStock ||
                        this.state.hasClickDeleteVpnBoxStock ||
                        this.state.hasClickAssociateTracking
                    ) &&
                    <ModalVpnStock
                        addVpnStockMovement={addVpnStockMovement}
                        associateTracking={associateTracking}
                        removeVpnStockMovement={removeVpnStockMovement}
                        sellVpnStock={sellVpnStock}
                        stock={stock}

                        preferences={preferences}
                        successNotify={this.props.successNotify}
                        errorNotify={this.props.errorNotify}
                        warningNotify={this.props.warningNotify}

                        type={this.state.type}
                        dataTemp={this.state.dataTemp}
                        titleModal={this.state.titleModal}
                        stateModal={this.state.modal}
                        toggle={this.toggleModal}
                    />
                }

                {
                    (this.state.hasClickStartRender || this.state.hasClickEndRender || this.state.hasClickRebook) &&
                    <ModalRenderStock
                        startRender={startRender}
                        rebook={rebook}
                        endRender={endRender}

                        stock={stock}
                        preferences={preferences}
                        successNotify={this.props.successNotify}
                        errorNotify={this.props.errorNotify}
                        warningNotify={this.props.warningNotify}

                        type={this.state.type}
                        dataTemp={this.state.dataTemp}
                        titleModal={this.state.titleModal}
                        stateModal={this.state.modalRender}
                        toggle={this.toggleModal}
                    />
                }
            </Fragment >
        )
    }
}

export default withNavigate(vpnBoxStock);