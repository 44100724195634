import React, { Component, Fragment } from 'react';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@mui/material';
import moment from 'moment';
// import axios from 'axios';
import { connect } from 'react-redux';
import isNull from "lodash/isNull";

import './style.scss';
import { playerActions, notifyActions } from '../../store/actions';
import MyTooltip from '../../common/tooltip';
import SelectorCopy from '../../common/selectorCopy';
import { returnNationality } from '../../common/actions/returnNationality';
import CSVExport from '../../common/csvExport';
import Loading from '../../common/loading';
import { PLAYER_TYPE, VPN_TYPE, VPNBOX_TYPE, SHIPPING } from '../../config';
import { controlRole } from '../../common/actions/controlRole';
import isEmpty from 'lodash/isEmpty';
import { withNavigate } from '../../utility';

class expanableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // informazioni che dovranno andare nel CSV da esportare
            headersCSV: [],
            dataCSV: []
        }

        setTimeout(() => {
            this.popolaCSV();
        }, 500);
    }

    /**
     * Dato un ID utente, vai alla schermata degli utenti portando id utente nei filtri
     */
    goToUser = (idUser) => {
        this.props.handleNavigation(this.props.navigate, `/userInDetail/${idUser}`);
    }

    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    /**
     * Dato un oggetto con le informazioni di domicilio,
     * restituisce una stringa nel formato: Via Roma, 24 - Roma (RM) - 12345
     */
    formatDomicile = (domicilio) => {
        if (domicilio) {
            return domicilio.line1 + ', ' + domicilio.city + '(' + domicilio.state + ') - ' + domicilio.postal_code
        } else {
            return
        }
    }

    /**
     * Popola il csv da esportare con le informazioni ricevute
     */
    popolaCSV = () => {
        const headersTemp = [
            { label: "ID Pagamento", key: "idPayment" },
            { label: "Indirizzo IP", key: "ipPayment" },
            { label: "Data Pagamento", key: "datePayment" },
            { label: "Conferma Pagamento", key: "confirmPayment" },
            { label: "Numero fattura", key: "invoiceNumber" },
            { label: "Data Rimborso", key: "dateRefund" },
            { label: "Provider", key: "provider" },
            { label: "ID Transazione provider", key: "idTransactionProvider" },
            { label: "Stato del pagamento", key: "statusPayment" },
            { label: "Nome Prodotto", key: "productName" },
            { label: "Descrizione Prodotto", key: "productDescription" },
            { label: "Prezzo Prodotto", key: "productPrice" },
            { label: "Durata Prodotto", key: "productDuration" },
            { label: "Quantità Prodotto", key: "productQty" },
            { label: "Tipo Prodotto", key: "productType" },
            { label: "DeviceId Prodotto", key: "productDeviceId" },
            { label: "DeviceType Prodotto", key: "productDeviceType" },
        ];

        const dataTemp = [
            {
                idPayment: this.props.data._id,
                ipPayment: this.props.data.customerIp,
                datePayment: this.formatData(this.props.data.createdAt),
                confirmPayment: this.formatData(this.props.data.issuedAt),
                invoiceNumber: this.props.data.invoiceNumber,
                dateRefund: this.formatData(this.props.data.refundAt),
                provider: this.props.data.paymentProvider,
                idTransactionProvider: this.props.data.providerTransactionId,
                statusPayment: this.props.data.paymentStatus,
                productName: this.props.data.products.map((item) => { return (item.name) }),
                productDescription: this.props.data.products.map((item) => { return (item.description) }),
                productDuration: this.props.data.products.map((item) => { return (item.durationInDays) }),
                productPrice: this.props.data.products.map((item) => { return (item.price) }),
                productQty: this.props.data.products.map((item) => { return (item.qty) }),
                productType: this.props.data.products.map((item) => { return (item.type) }),
                productDeviceId: this.props.data.products.map((item) => {
                    if (item.deviceInfo === null || item.deviceInfo === undefined) {
                        return '';
                    } else {
                        return (item.deviceInfo.deviceId);
                    }
                }),
                productDeviceType: this.props.data.products.map((item) => {
                    if (item.deviceInfo === null || item.deviceInfo === undefined) {
                        return '';
                    } else {
                        return (item.deviceInfo.deviceType);
                    }
                })
            }
        ];

        this.setState({
            headersCSV: headersTemp,
            dataCSV: dataTemp
        });
    }

    /**
     * Se una fattura non esiste, allora chiama il BE e la crea.
     * 
     * @param idPayment id del pagamento associato alla fattura da creare
     */
    // createInvoice = async (idPayment) => {
    //     try {
    //         this.setState({ isLoading: true });
    //         await axios.put(`${API_CREATE_INVOICE}/${idPayment}`);
    //         this.setState({ isLoading: false });
    //         this.props.successNotify('Fattura creata correttamente!');
    //         this.props.infoNotify('...chiudi e riapri il tab per aggiornare i dati...');
    //         this.props.getPaymentList();

    //     } catch (result) {
    //         this.setState({ isLoading: false });
    //         if (result && result.response && result.response.status) {
    //             this.props.errorNotify('Errore ' + result.response.status + ' nella creazione della fattura!');
    //         }
    //     }
    // }

    /**
     * Dato un id e un deviceType rimando alla sezione corrispondente
     */
    goToPage = (deviceId, deviceType) => {
        if (deviceType === PLAYER_TYPE) {
            this.props.setIdPlayerForDetail(deviceId)
            this.props.handleNavigation(this.props.navigate, `/player/devices`);

        } else if (deviceType === VPN_TYPE) {
            this.props.setIdVpnPassForDetail(deviceId)
            this.props.handleNavigation(this.props.navigate, `/vpn/pass`);

        } else if (deviceType === VPNBOX_TYPE) {
            this.props.setIdVpnPassForDetail(deviceId)
            this.props.handleNavigation(this.props.navigate, `/vpn/box`);
        }
    }

    render() {
        const { role, data } = this.props;

        return (
            <div className="tabExpanablePayment">
                {this.state.isLoading && <Loading />}
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.formatData(data.createdAt) || ''}
                                label="Quando il pagamento è stato effettuato"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.formatData(data.issuedAt) || ''}
                                label="Quando il pagamento è stato confermato"
                                variant='standard'
                            />
                        </div>

                        <div className="col-2">
                            <TextField
                                value={data.paymentProvider || ''}
                                label="Provider"
                                variant='standard'
                            />
                        </div>

                        <div className="col-2">
                            <TextField
                                value={
                                    data.paymentStatus === 1 ? 'Pagato' :
                                        data.paymentStatus === 3 ? 'Rimborsato' :
                                            data.paymentStatus === 6 ? 'Rimborso Parziale' :
                                                data.paymentStatus === 0 ? 'Non confermato' :
                                                    data.paymentStatus === -1 ? 'Cancellato / Respinto' :
                                                        data.paymentStatus === 4 ? 'Sottoscrizione Attiva' :
                                                            data.paymentStatus === 5 ? 'Sottoscrizione Annullata' :
                                                                data.paymentStatus === 2 ? 'In Attesa' : 'Non Pagato'
                                }
                                label="Stato"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={
                                    (data.providerTransactionId !== data._id && data.paymentProvider === 'PayPal') ?
                                        data.providerTransactionId
                                        :
                                        (data.paymentProvider === 'Netopia_cc' || data.paymentProvider === 'Stripe') ?
                                            data.providerTransactionId
                                            :
                                            'Non esiste transazione...'}
                                label="ID transazione provider"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            {
                                !isNull(data.refundedAt) &&
                                <TextField
                                    value={this.formatData(data.refundedAt) || ''}
                                    label="Data di rimborso"
                                    variant='standard'
                                />
                            }
                        </div>

                        <div className="col-2 text-center">
                            <CSVExport
                                data={this.state.dataCSV}
                                headers={this.state.headersCSV}
                                filename={"payment-" + data._id + ".csv"}
                            />
                        </div>
                    </div>

                    {
                        !isEmpty(data.creditNotes) &&
                        <Fragment>
                            <hr style={{ 'width': '100%' }}></hr>
                            <h5 className='red'>Note Credito</h5>
                            <div className="row">
                                {
                                    data.creditNotes.map((el, key) => {
                                        return (
                                            <div className="col-2" key={key}>
                                                <a className="btn btn-warning" href={el.refundUrl} download>
                                                    Rimborso del {moment(el.refundedAt).format('DD/MM/YYYY')}
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Fragment>
                    }

                    <hr style={{ 'width': '100%' }}></hr>
                    <h5 className='orange'>Utente</h5>

                    <div className="row">
                        <div className="col-2">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-user">Email</InputLabel>
                                <Input
                                    id="id-user"
                                    value={data.email}
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={data.email}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-2">
                            <TextField
                                value={data.invoiceAddress.name || ''}
                                label="Nome"
                                variant='standard'
                            />
                        </div>

                        <div className="col-2">
                            <TextField
                                value={data.invoiceAddress.surname || ''}
                                label="Cognome"
                                variant='standard'
                            />
                        </div>

                        <div className="col-2">
                            <TextField
                                value={data.customerIp || ''}
                                label="IP Registrazione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-2">
                            <TextField
                                value={returnNationality(data.invoiceAddress.country) || ''}
                                label="Nazionalità"
                                variant='standard'
                            />
                        </div>

                        <div className="col-2">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-user">Parent Payment</InputLabel>
                                <Input
                                    id="parent-payment"
                                    value={data._parentPayment}
                                    readOnly
                                    endAdornment={
                                        data._parentPayment !== null &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={data._parentPayment}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    {
                        !isEmpty(data.invoiceAddress) ?
                            <Fragment>
                                <hr style={{ 'width': '100%' }}></hr>
                                <h5 className='green'>Indirizzo Fatturazione</h5>

                                <div className="row">
                                    <div className="col-2">
                                        <TextField
                                            value={data.invoiceAddress.name || ''}
                                            label="Name"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-2">
                                        <TextField
                                            value={data.invoiceAddress.surname || ''}
                                            label="Surname"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-2">
                                        <TextField
                                            value={data.invoiceAddress.city || ''}
                                            label="City"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-2">
                                        <TextField
                                            value={returnNationality(data.invoiceAddress.country) || ''}
                                            label="Country"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-2">
                                        <TextField
                                            value={data.invoiceAddress.province || ''}
                                            label="Province"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-2">
                                        <TextField
                                            value={data.invoiceAddress.cap || ''}
                                            label="Zip Code"
                                            variant='standard'
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-6">
                                        <TextField
                                            value={data.invoiceAddress.firstAddress || ''}
                                            label="First Address"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-6">
                                        <TextField
                                            value={data.invoiceAddress.secondAddress || ''}
                                            label="Second Address"
                                            variant='standard'
                                        />
                                    </div>
                                </div>

                            </Fragment>
                            :
                            "Nessun Indirizzo di Fatturazione..."
                    }

                    <hr style={{ 'width': '100%' }}></hr>
                    <h5 className='blue'>Prodotti</h5>

                    {
                        data.products.map((item, k) => {
                            return (
                                <div className="row blockProduct" key={k}>
                                    <div className="col-3">
                                        <TextField
                                            value={item.name || ''}
                                            label="Nome"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-3">
                                        <TextField
                                            value={item.type || ''}
                                            label="Tipo"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-4">
                                        <TextField
                                            value={item.description || ''}
                                            label="Descrizione"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-1">
                                        <TextField
                                            value={item.qty || ''}
                                            label="Quantità"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-1">
                                        <TextField
                                            value={item.qtyRefunded || '0'}
                                            label="Quantità Rimborsata"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-1">
                                        <TextField
                                            value={item.price || ''}
                                            label="Prezzo"
                                            variant='standard'
                                        />
                                    </div>

                                    {
                                        item.name !== SHIPPING &&
                                        <Fragment>
                                            <div className="col-3">
                                                <TextField
                                                    value={item.deviceInfo === null ? '' : (item.deviceInfo.deviceType || '')}
                                                    label="Device Type"
                                                    variant='standard'
                                                />
                                            </div>

                                            <div className="col-3">
                                                <FormControl className='customSelect'>
                                                    <InputLabel htmlFor="id-device">ID Device</InputLabel>
                                                    {
                                                        item.deviceInfo === null ?
                                                            <Input
                                                                id="id-device"
                                                                value=""
                                                                readOnly
                                                            />
                                                            :
                                                            <Input
                                                                id="id-device"
                                                                value={item.deviceInfo.deviceId}
                                                                readOnly
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <SelectorCopy
                                                                            item={item.deviceInfo.deviceId}
                                                                            infoNotify={this.props.infoNotify}
                                                                            noLabel
                                                                        />
                                                                        <span className="clickForPlayer" onClick={() => { this.goToPage(item.deviceInfo.deviceId, item.deviceInfo.deviceType) }}>
                                                                            <MyTooltip
                                                                                title="Vai a questo ID"
                                                                                position="top"
                                                                                content={<i className="fas fa-share-square"></i>}
                                                                            />
                                                                        </span>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                    }
                                                </FormControl>
                                            </div>



                                            <div className="col-3">
                                                <TextField
                                                    value={item.durationInDays || ''}
                                                    label="Durata"
                                                    variant='standard'
                                                />
                                            </div>

                                            {
                                                (
                                                    item.deviceInfo !== null &&
                                                    item.deviceInfo.deviceType === PLAYER_TYPE &&
                                                    controlRole(role.payment, "api/Payment/ChangeDevice", "PUT")
                                                ) &&
                                                <div className="col-3">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                            let data = {
                                                                deviceId: item.deviceInfo.deviceId,
                                                                paymentId: this.props.data._id,
                                                                providerTransactionId: this.props.data.providerTransactionId
                                                            };

                                                            this.props.changeDevice(data);
                                                        }}
                                                    >
                                                        <i className="fas fa-tools"></i>
                                                        Associa Nuovo Device
                                                    </button>
                                                </div>
                                            }
                                        </Fragment>
                                    }

                                    {
                                        item.promo !== null &&
                                        <div className="col-6">
                                            <TextField
                                                value={!item.promo.isCoupon ? item.promo._id : item.promo.code}
                                                label={!item.promo.isCoupon ? "Id Promo Associata all'Utente" : "Coupon Usato"}
                                                variant='standard'
                                            />
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </FormControl>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        payment: state.payment,
        role: state.role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIdPlayerForDetail: (data) => {
            dispatch(playerActions.setIdPlayerForDetail(data))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        },
    };
};

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(expanableComponent));