import React, { Component, Fragment } from 'react';
import { controlRole } from '../../common/actions/controlRole';
import { SUPER_ADMIN } from '../../config';

import Modal from '../../common/modal';
import './style.scss';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            titleModal: '',
            contentModal: '',
            action: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            titleModal: '',
            contentModal: '',
            action: ''
        }));
    }

    actionClick = (action, user) => {
        this.props.actionClick(action, user);
        this.toggleModal();
    }

    setInfoModal = (titleModal, contentModal, action) => {
        this.setState({
            titleModal: titleModal,
            contentModal: contentModal,
            action: action
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    setReseller = () => {
        if (this.props.user.isReseller) {
            this.setInfoModal('Rimuovi da Reseller', 'Sei sicuro di voler rimuove l\'utente dai Reseller?', 'editUser');

        } else {
            this.setInfoModal('Rendi Reseller', 'Sei sicuro di voler rendere l\'utente Reseller?', 'editUser');

        }
    }

    render() {
        const { data, role, userRole, user } = this.props;

        return (
            <Fragment>
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-ellipsis-v"></i>
                    </button>

                    <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('moreDetail', user); }}>
                                <i className="fas fa-cogs orange"></i>
                                Maggiori Dettagli
                            </div>
                        </li>

                        {
                            userRole.user.role === SUPER_ADMIN &&
                            <li>
                                <div className="dropdown-item" onClick={() => { this.actionClick('editRole', user) }}>
                                    <i className="fas fa-address-book purple"></i>
                                    Assegna Ruolo
                                </div>
                            </li>
                        }

                        {
                            (
                                user.role !== "" &&
                                user.role !== null &&
                                userRole.user.role === SUPER_ADMIN
                            ) &&
                            <li>
                                <div className="dropdown-item" onClick={() => { this.setInfoModal('Rimuovi Ruolo', 'Sei sicuro di voler eliminare il ruolo a questo utente?', 'removeRole') }}>
                                    <i className="fas fa-address-book red"></i>
                                    Rimuovi Ruolo
                                </div>
                            </li>
                        }

                        {
                            controlRole(role.user, "api/User/Group/AssignGroup", "PATCH") &&
                            <li>
                                <div className="dropdown-item" onClick={() => { this.actionClick('editGroup', user) }}>
                                    <i className="fas fa-users-cog purple"></i>
                                    Assegna Gruppo
                                </div>
                            </li>
                        }

                        {
                            controlRole(role.user, "api/User/Address", "PUT") &&
                            <li>
                                <div className="dropdown-item" onClick={() => { this.actionClick('editAddress', user) }}>
                                    <i className="fas fa-address-card blue"></i>
                                    Modifica Indirizzi
                                </div>
                            </li>
                        }

                        {
                            controlRole(role.user, "api/User", "PUT") &&
                            <li>
                                <div className="dropdown-item" onClick={() => this.setReseller()}>
                                    {
                                        user.isReseller ?
                                            <>
                                                <i className="fas fa-address-card red"></i>
                                                Rimuovi da Reseller
                                            </>
                                            :
                                            <>
                                                <i className="fas fa-address-card blue"></i>
                                                Rendi Reseller
                                            </>
                                    }
                                </div>
                            </li>
                        }

                        {
                            controlRole(role.user, "api/User", "DELETE") &&
                            <li>
                                <div className="dropdown-item" onClick={() => { this.setInfoModal('Elimina Utente', 'Sei sicuro di voler eliminare l\'utente?', 'deleteUser'); }}>
                                    <i className="fas fa-user-times red"></i>
                                    Elimina Utente
                                </div>
                            </li>
                        }

                        {
                            controlRole(role.support, "api/Support/BackOfficeSendMail", "POST") &&
                            <li>
                                <div className="dropdown-item" onClick={() => { this.actionClick('sendMail', user) }}>
                                    <i className="far fa-paper-plane blue"></i>
                                    Manda Email
                                </div>
                            </li>
                        }

                        {
                            controlRole(role.user, "api/User/ResetPassword", "PUT") &&
                            <li>
                                <div className="dropdown-item" onClick={() => { this.setInfoModal('Reset Password', 'Sei sicuro di voler resettare la password dell\'utente?', 'resetPassword'); }}>
                                    <i className="fas fa-key green"></i>
                                    Resetta Password
                                </div>
                            </li>
                        }

                        {
                            controlRole(role.user, "api/User/ResetTfa", "PUT") && user.tfa &&
                            <li>
                                <div className="dropdown-item" onClick={() => { this.setInfoModal('Reset TFA', 'Sei sicuro di voler resettare il TFA dell\'utente?', 'resetTfa'); }}>
                                    <i className="fas fa-key green"></i>
                                    Resetta TFA
                                </div>
                            </li>
                        }
                    </ul>
                </div>

                {
                    (this.state.action === 'resetPassword' ||
                        this.state.action === 'resetTfa' ||
                        this.state.action === 'deleteUser' ||
                        this.state.action === 'editUser' ||
                        this.state.action === 'removeRole') &&

                    <Modal
                        click={() => { this.actionClick(this.state.action, user) }}
                        titleModal={this.state.titleModal}
                        contentModal={this.state.contentModal}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}
                    />
                }
            </Fragment>
        )
    }
}