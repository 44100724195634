import React, { Component, Fragment } from 'react';

/**
 * In base al tipo di icona, visualizza una determinata icona.
 * - typeIcon (trial per icona di prova, lifetime per icona a vita)
 * - legend (se presente, attiva la visualizzazione per la legenda)
 * 
 */
export default class index extends Component {
    render() {
        const { typeIcon, legend, forDevice, forOnline, forPayment, forPromo, forExpire, forUsers } = this.props;

        return (
            <Fragment>
                {
                    (legend && forDevice) &&
                    <div className="row">
                        <div className="col" style={{ 'fontSize': '14px', 'fontStyle': 'italic' }}>
                            <span className="iconLifetime">
                                <i className="fas fa-check-circle"></i>
                            </span>
                            <span> Lifetime </span>

                            <span className="iconTrial">
                                <i className="fas fa-clock"></i>
                            </span>
                            <span> Trial </span>
                        </div>
                    </div>
                }
                {
                    (!legend && forDevice) &&
                    <div className={typeIcon === 'trial' ? 'iconTrial' : 'iconLifetime'}>
                        <i className={typeIcon === 'trial' ? 'fas fa-clock' : 'fas fa-check-circle'}></i>
                    </div>
                }
                {
                    (!legend && forOnline) &&
                    <div className={typeIcon === 'online' ? 'iconOnline' : 'iconOffline'}>
                        <i className={typeIcon === 'online' ? 'fas fa-circle' : 'fas fa-circle'}></i>
                    </div>
                }

                {
                    (legend && forPayment) &&
                    <div className="row">
                        <div className="col" style={{ 'fontSize': '12px', 'fontStyle': 'italic' }}>

                            <span className="iconLifetime">
                                <i className="far fa-thumbs-up"></i>
                            </span>
                            <span>
                                Pagato
                            </span>

                            <span className="iconTrial">
                                <i className="far fa-thumbs-down"></i>
                            </span>
                            <span>
                                Cancellato/Respinto
                            </span>

                            <span className="iconWait">
                                <i className="fas fa-clock"></i>
                            </span>
                            <span>
                                In attesa
                            </span>

                            <span className="iconRefund">
                                <i className="fas fa-undo-alt"></i>
                            </span>
                            <span>
                                Rimborsato
                            </span>

                            <span className="iconPartialRefund">
                                <i className="fas fa-undo-alt"></i>
                            </span>
                            <span>
                                Rimborso Parziale
                            </span>

                            <span className="iconTrial">
                                <i className="fas fa-times"></i>
                            </span>
                            <span>
                                Non Confermato
                            </span>

                            <span className="iconSub">
                                <i className="far fa-thumbs-up"></i>
                            </span>
                            <span>
                                Sottoscrizione Attiva
                            </span>

                            <span className="iconNotSub">
                                <i className="fas fa-times"></i>
                            </span>
                            <span>
                                Sottoscrizione Annullata
                            </span>
                        </div>
                    </div>
                }
                {
                    (legend && forUsers) &&
                    <div className="row">
                        <div className="col" style={{ 'fontSize': '12px', 'fontStyle': 'italic' }}>

                            <span style={{ 'margin': '0 2vh' }}>
                                <b>P.U.:</b> Promo Usate / Totali
                            </span>

                            <span style={{ 'margin': '0 2vh' }}>
                                <b>C.U.</b>: Coupons Usati / Totali
                            </span>

                            <span style={{ 'margin': '0 2vh' }}>
                                <b>G.P.U.:</b> Geo Promo Usate / Totali
                            </span>

                        </div>
                    </div>
                }
                {
                    (legend && forPromo) &&
                    <div className="row">
                        <div className="col" style={{ 'fontSize': '14px', 'fontStyle': 'italic' }}>
                            <span className="iconCoupon">
                                <i className="fas fa-gift" style={{ 'fontSize': '15px' }}></i>
                            </span>
                            <span> Coupon </span>

                            <span className="iconCouponExpired">
                                <i className="fas fa-gift" style={{ 'fontSize': '15px' }}></i>
                            </span>
                            <span> Coupon Scaduto </span>

                            <span className="iconPromo">
                                <i className="fas fa-user-tag" style={{ 'fontSize': '15px' }}></i>
                            </span>
                            <span> Promo associata ad un utente </span>

                            <span className="iconPromoExpired">
                                <i className="fas fa-user-tag" style={{ 'fontSize': '15px' }}></i>
                            </span>
                            <span> Promo Scaduta </span>
                        </div>
                    </div>
                }
                {
                    (legend && forExpire) &&
                    <div className="row">
                        <div className="col" style={{ 'fontSize': '14px', 'fontStyle': 'italic' }}>

                            <span className="iconPromo">
                                <i className="fas fa-circle green" style={{ 'fontSize': '15px' }}></i>
                            </span>
                            <span> Licenza Valida </span>

                            <span className="iconPromoExpired">
                                <i className="fas fa-circle red" style={{ 'fontSize': '15px' }}></i>
                            </span>
                            <span> Licenza Scaduta </span>
                        </div>
                    </div>
                }

            </Fragment>
        )
    }
}