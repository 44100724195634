import { notifyActionType } from './';
import { toast } from "react-toastify";

export const notifyActions = {
  successAction,
  errorAction,
  warningAction,
  infoAction
};

const options = {
  autoClose: 6000,
  hideProgressBar: false,
  theme: "colored"
}

function successAction(message) {
  return {
    type: notifyActionType.NOTIFY_SUCCESS,
    async payload() {
      toast.success(message, options);
    }
  };
}

function errorAction(message) {
  return {
    type: notifyActionType.NOTIFY_ERROR,
    async payload() {
      toast.error(message, options);
    }
  };
}

function warningAction(message) {
  return {
    type: notifyActionType.NOTIFY_WARNING,
    async payload() {
      toast.warn(message, options);
    }
  };
}

function infoAction(message) {
  return {
    type: notifyActionType.NOTIFY_INFO,
    async payload() {
      toast.info(message, options);
    }
  };
}