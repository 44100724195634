import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@mui/material';
import { NOT_REGISTERED } from '../../config';
import MyTooltip from '../../common/tooltip';
import SelectorCopy from '../../common/selectorCopy';
import moment from 'moment';

import { playerActions, paymentActions, notifyActions } from '../../store/actions';
import './style.scss';
import { withNavigate } from '../../utility';

class ExpanableComponentLicence extends Component {
    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    /**
     * Vai ai dettagli sull'utente selezionato
     */
    goToUser = () => {
        this.props.handleNavigation(this.props.navigate, `/userInDetail/${this.props.data.user}`);
    }

    /**
     * Dato un id, vai alla schermata dei pagamenti portando id pagamento nei filtri
     */
    goToPayment = () => {
        this.props.setIdPaymentForDetail(this.props.data.paymentId)
        this.props.handleNavigation(this.props.navigate, `/payments`);
    }

    /**
     * Dato un ID device, vai alla schermata dei device portando id player nei filtri
     */
    goToPlayer = () => {
        this.props.setIdPlayerForDetail(this.props.data.playerId);
        this.props.handleNavigation(this.props.navigate, `/player/devices`);
    }

    render() {
        return (
            <div className="tabExpanableLicence">
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="key-licence">Key</InputLabel>
                                <Input
                                    id="key-licence"
                                    value={this.props.data.key || ''}
                                    readOnly
                                    endAdornment={
                                        this.props.data.key &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.props.data.key}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.props.data.platform || ''}
                                label="Piattaforma"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-player">Device ID</InputLabel>
                                <Input
                                    id="id-player"
                                    value={this.props.data.playerId || ''}
                                    readOnly
                                    endAdornment={
                                        this.props.data.playerId &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.props.data.playerId}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />

                                            {
                                                this.props.data.playerId !== '' &&
                                                <span className="clickForPayment" onClick={this.goToPlayer}>
                                                    <MyTooltip
                                                        title="Vai a questo player"
                                                        position="top"
                                                        content={<i className="fas fa-share-square"></i>}
                                                    />
                                                </span>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.props.data.description || ''}
                                label="Descrizione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.formatData(this.props.data.createdAt) || ''}
                                label="Data Creazione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.formatData(this.props.data.expireAt) || ''}
                                label="Data Scadenza"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-user">Utente</InputLabel>
                                <Input
                                    id="id-user"
                                    value={this.props.data.user || ''}
                                    readOnly
                                    endAdornment={
                                        this.props.data.user &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.props.data.user}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />

                                            {
                                                this.props.data.user !== '' &&
                                                <span className="clickForPayment" onClick={this.goToUser}>
                                                    <MyTooltip
                                                        title="Vai a questo utente"
                                                        position="top"
                                                        content={<i className="fas fa-share-square"></i>}
                                                    />
                                                </span>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-4">
                            <FormControl className='customSelect'>
                                <InputLabel htmlFor="id-payment-licence">ID Pagamento</InputLabel>
                                <Input
                                    id="id-payment-licence"
                                    value={this.props.data.paymentId === NOT_REGISTERED ? 'Non Presente...' : this.props.data.paymentId}
                                    readOnly
                                    endAdornment={
                                        this.props.data.paymentId &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.props.data.paymentId}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />

                                            {
                                                this.props.data.expireAt === null &&
                                                <span className="clickForPayment" onClick={this.goToPayment}>
                                                    <MyTooltip
                                                        title="Vai a questo pagamento"
                                                        position="top"
                                                        content={<i className="fas fa-share-square"></i>}
                                                    />
                                                </span>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.props.data.maxAllowedPeers || ''}
                                label="Numero MAX Peers"
                                variant='standard'
                            />
                        </div>
                    </div>
                </FormControl>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIdPlayerForDetail: (data) => {
            dispatch(playerActions.setIdPlayerForDetail(data))
        },
        setIdPaymentForDetail: (data) => {
            dispatch(paymentActions.setIdPaymentForDetail(data))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        },
    };
};

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(ExpanableComponentLicence));