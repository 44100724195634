import React, { Component } from 'react';
import { controlRole } from '../../common/actions/controlRole';
import { withNavigate } from '../../utility';

class actionMenuMachine extends Component {
    // valori che indicano lo status della macchina

    // isFailed: -2
    // isSuspended: -1
    // isCreated: 0
    // isInstalling: 1
    // isStarted: 2
    // isPublished: 3

    render() {
        const { vpn, actionClick, role, data } = this.props;

        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        (
                            // quando lo status è IsStarted
                            // esce il pulsante Published
                            vpn.status === 2 &&
                            controlRole(role.vpnMachine, "api/VpnMachine/Publish", "PUT")
                        ) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { actionClick('publish', vpn); }}>
                                <i className="fas fa-upload blue"></i>
                                Publica
                            </div>
                        </li>
                    }

                    {
                        (
                            // quando lo status è IsPublished
                            // esce il pulsante UnPublish
                            vpn.status === 3 &&
                            controlRole(role.vpnMachine, "api/VpnMachine/UnPublish", "PUT")
                        ) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { actionClick('unPublish', vpn); }}>
                                <i className="fas fa-ban red"></i>
                                Rimuovi Publicazione
                            </div>
                        </li>
                    }

                    {
                        (
                            // quando lo status è IsFailed, isSuspended, isCreated
                            // esce il pulsante Modifica
                            (vpn.status !== 1 && vpn.status !== 2) &&
                            controlRole(role.vpnMachine, "api/VpnMachine/Update", "PUT")
                        ) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { actionClick('edit', vpn); }}>
                                <i className="far fa-edit orange"></i>
                                Modifica
                            </div>
                        </li>
                    }

                    {
                        (
                            // quando lo status è IsFailed, isSuspended, isCreated
                            // esce il pulsante Setup
                            (vpn.status !== 1 && vpn.status !== 2) &&
                            controlRole(role.vpnMachine, "api/VpnMachine/Setup", "POST")
                        ) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { actionClick('setup', vpn); }}>
                                <i className="fas fa-play green"></i>
                                Setup
                            </div>
                        </li>
                    }

                    {
                        (
                            // quando lo status è IsFailed, isSuspended, isCreated
                            // esce il pulsante Elimina
                            (vpn.status !== 1 && vpn.status !== 2) &&
                            controlRole(role.vpnMachine, "api/VpnMachine/Delete", "DELETE")
                        ) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { actionClick('remove', vpn); }}>
                                <i className="far fa-trash-alt red"></i>
                                Elimina
                            </div>
                        </li>
                    }

                    {
                        // quando lo status è IsStarted
                        // esce il pulsante Vai ai peer
                        vpn.status === 2 &&
                        <li>
                            {
                                controlRole(role.vpnPeer, "api/VpnPeer", "GET") &&
                                <div className="dropdown-item" onClick={() => { this.props.handleNavigation(this.props.navigate, `/vpn/peer/${vpn.key}`); }}>
                                    <i className="fas fa-external-link-alt blue"></i>
                                    Vai ai Peer
                                </div>
                            }

                            {
                                controlRole(role.vpnMachine, "api/VpnMachine/Stop", "GET") &&
                                <div className="dropdown-item" onClick={() => { actionClick('stop', vpn); }}>
                                    <i className="fas fa-stop-circle red"></i>
                                    Stop!
                                </div>
                            }
                        </li>
                    }

                    {
                        (
                            // quando lo status è IsSuspended
                            // esce il pulsante Start
                            vpn.status === -1 &&
                            controlRole(role.vpnMachine, "api/VpnMachine/Start", "GET")
                        ) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { actionClick('start', vpn); }}>
                                <i className="fas fa-play-circle green"></i>
                                Start
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}

export default withNavigate(actionMenuMachine);