import React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useState, useEffect } from 'react';

import Loading from '../loading';
import { controlRole } from '../actions/controlRole';
import { SUPER_ADMIN } from '../../config';

import Logo from '../../images/logoWhite.svg';
import { isEmpty } from '../../utility';
import { useLocation, useNavigate } from 'react-router-dom';

function ProSidebar(props) {

    const { toggleOpenMobile, valueSidebarMobile, role, user, logout, versionSite } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(false);
        setIsMobile(window.innerWidth < 769);

        // eslint-disable-next-line
    }, []);

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (
        <>
            {isLoading && <Loading />}

            <div className={isMobile ? "sidebarMobile" : "sidebarDesktop"}>
                {
                    isMobile &&
                    <div className={valueSidebarMobile ? "overlay active" : "overlay"} role="button" tabIndex="0" aria-label="overlay" onClick={() => toggleOpenMobile(!valueSidebarMobile)}></div>
                }

                <Sidebar className={valueSidebarMobile ? "pro-sidebar md toggled" : "pro-sidebar md"}>
                    <Menu className="pro-sidebar-header">
                        <img className="logo" src={Logo} alt="Logo Nanomid" />
                    </Menu>

                    <Menu menuItemStyles={{
                        button: ({ level, active }) => {
                            // only apply styles on first level elements of the tree
                            if (level === 0)
                                return {
                                    color: active ? '#0d6efd' : '#212529',
                                    backgroundColor: active ? '#cddef4' : 'white'
                                };
                            if (level === 1)
                                return {
                                    color: active ? '#0d6efd' : '#212529',
                                };
                        },
                    }}>
                        {
                            // visualizzo solo se l'utente è abilitato a vedere una delle statistiche, altrimenti non mostrare nulla
                            (
                                controlRole(role.user, "api/User/Statistics", "GET") ||
                                controlRole(role.player, "api/Player/Statistics", "GET") ||
                                controlRole(role.payment, "api/Payment/Statistics", "GET") ||
                                controlRole(role.vpnPass, "api/VpnPass/Statistics", "GET")
                            ) &&
                            <MenuItem
                                active={location.pathname === '/' ? true : false}
                                onClick={() => { handleNavigate('/'); toggleOpenMobile(!valueSidebarMobile); }}
                                icon={<i className="fas fa-home"></i>}
                            >
                                Home
                            </MenuItem>
                        }

                        {
                            // visualizzo solo se l'utente è abilitato a vedere gli utenti
                            controlRole(role.user, "api/User", "GET") &&
                            <MenuItem
                                active={location.pathname === '/users' ? true : false}
                                onClick={() => { handleNavigate('/users'); toggleOpenMobile(!valueSidebarMobile); }}
                                icon={<i className="fas fa-users" />}
                            >
                                Utenti
                            </MenuItem>
                        }

                        {
                            (
                                !controlRole(role.player, "api/Player/GetPlayers", "GET") &&
                                !controlRole(role.player, "api/Player/PlayLists", "GET") &&
                                !controlRole(role.player, "api/Player/GetLicences", "GET")
                            ) ? '' :
                                <SubMenu label="Players" icon={<i className="fas fa-tv" />}
                                    active={location.pathname === '/player/devices' || location.pathname === '/player/licences' || location.pathname === '/player/playlists'}
                                    defaultOpen={location.pathname === '/player/devices' || location.pathname === '/player/licences' || location.pathname === '/player/playlists'}
                                >
                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere i players
                                        controlRole(role.player, "api/Player/GetPlayers", "GET") &&
                                        <MenuItem
                                            active={location.pathname === '/player/devices' ? true : false}
                                            onClick={() => { handleNavigate('/player/devices'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            Dispositivi
                                        </MenuItem>
                                    }

                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere le licenze
                                        controlRole(role.player, "api/Player/GetLicences", "GET") &&
                                        <MenuItem
                                            active={location.pathname === '/player/licences' ? true : false}
                                            onClick={() => { handleNavigate('/player/licences'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            Licenze
                                        </MenuItem>
                                    }

                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere le playlist
                                        controlRole(role.player, "api/Player/PlayLists", "GET") &&
                                        <MenuItem
                                            active={location.pathname === '/player/playlists' ? true : false}
                                            onClick={() => { handleNavigate('/player/playlists'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            Playlists
                                        </MenuItem>
                                    }
                                </SubMenu>
                        }

                        {
                            // visualizzo solo se l'utente è abilitato a vedere i pagamenti
                            controlRole(role.payment, "api/Payment", "GET") &&
                            <MenuItem
                                active={location.pathname === '/payments' ? true : false}
                                onClick={() => { handleNavigate('/payments'); toggleOpenMobile(!valueSidebarMobile); }}
                                icon={<i className="fas fa-search-dollar" />}
                            >
                                Pagamenti
                            </MenuItem>
                        }

                        {
                            (
                                !controlRole(role.vpnMachine, "api/VpnMachine", "GET") &&
                                !controlRole(role.vpnPeer, "api/VpnPeer", "GET") &&
                                !controlRole(role.vpnPass, "api/VpnPass", "GET") &&
                                !controlRole(role.vpnBox, "api/VpnBox", "GET")
                            ) ? '' :
                                <SubMenu label="VPN" icon={<i className="fas fa-globe" />}
                                    active={location.pathname === '/vpn/machine' || location.pathname === '/vpn/peer' || location.pathname === '/vpn/pass' || location.pathname === '/vpn/box' || location.pathname === '/vpn/setting'}
                                    defaultOpen={location.pathname === '/vpn/machine' || location.pathname === '/vpn/peer' || location.pathname === '/vpn/pass' || location.pathname === '/vpn/box' || location.pathname === '/vpn/setting'}
                                >
                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere le vpn machine
                                        controlRole(role.vpnMachine, "api/VpnMachine", "GET") &&
                                        <MenuItem
                                            active={location.pathname === '/vpn/machine' ? true : false}
                                            onClick={() => { handleNavigate('/vpn/machine'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            Machine
                                        </MenuItem>
                                    }

                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere le vpn peer
                                        controlRole(role.vpnPeer, "api/VpnPeer", "GET") &&
                                        <MenuItem
                                            active={location.pathname === '/vpn/peer' ? true : false}
                                            onClick={() => { handleNavigate('/vpn/peer'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            Peers
                                        </MenuItem>
                                    }

                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere le VPN Pass
                                        controlRole(role.vpnPass, "api/VpnPass", "GET") &&
                                        <MenuItem
                                            active={location.pathname === '/vpn/pass' ? true : false}
                                            onClick={() => { handleNavigate('/vpn/pass'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            VPN Pass
                                        </MenuItem>
                                    }

                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere le VPN Box
                                        controlRole(role.vpnBox, "api/VpnBox", "GET") &&
                                        <MenuItem
                                            active={location.pathname === '/vpn/box' ? true : false}
                                            onClick={() => { handleNavigate('/vpn/box'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            VPN Box
                                        </MenuItem>
                                    }

                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere le VPN Box
                                        user.user.role === SUPER_ADMIN &&
                                        <MenuItem
                                            active={location.pathname === '/vpn/setting' ? true : false}
                                            onClick={() => { handleNavigate('/vpn/setting'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            VPN Setting
                                        </MenuItem>
                                    }
                                </SubMenu>
                        }

                        {
                            // visualizzo solo se l'utente è abilitato a vedere il bundle
                            !isEmpty(role.bundle) &&
                            <MenuItem
                                active={location.pathname === '/bundle' ? true : false}
                                onClick={() => { handleNavigate('/bundle'); toggleOpenMobile(!valueSidebarMobile); }}
                                icon={<i className="fas fa-box-open" />}
                            >
                                Bundle
                            </MenuItem>
                        }

                        {
                            (
                                !controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "GET")
                            ) ? '' :
                                <SubMenu label="Stock" icon={<i className="fas fa-shopping-cart" />}
                                    active={location.pathname === '/stock/vpnBox'}
                                    defaultOpen={location.pathname === '/stock/vpnBox'}
                                >
                                    {
                                        // visualizzo solo se l'utente è abilitato a vedere lo stock delle vpnBox
                                        controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "GET") &&
                                        <MenuItem
                                            active={location.pathname === '/stock/vpnBox' ? true : false}
                                            onClick={() => { handleNavigate('/stock/vpnBox'); toggleOpenMobile(!valueSidebarMobile); }}
                                        >
                                            VPN Box
                                        </MenuItem>
                                    }
                                </SubMenu>
                        }

                        {
                            // visualizzo solo se l'utente è abilitato a vedere il worker
                            !isEmpty(role.worker) &&
                            <MenuItem
                                active={location.pathname === '/worker' ? true : false}
                                onClick={() => { handleNavigate('/worker'); toggleOpenMobile(!valueSidebarMobile); }}
                                icon={<i className="fas fa-server" />}
                            >
                                Worker
                            </MenuItem>
                        }

                        {
                            // visualizzo solo se l'utente è abilitato a vedere i ruoli o aggiungerli
                            user.user.role === SUPER_ADMIN &&
                            <MenuItem
                                active={location.pathname === '/role' ? true : false}
                                onClick={() => { handleNavigate('/role'); toggleOpenMobile(!valueSidebarMobile); }}
                                icon={<i className="fab fa-accessible-icon" />}
                            >
                                Ruoli
                            </MenuItem>
                        }

                        {
                            // visualizzo solo se l'utente è abilitato a vedere i coupon/promo
                            !controlRole(role.promo, "api/Promo/GetPromos", "GET") ?
                                '' :
                                <SubMenu label="Promo - Coupon" icon={<i className="fas fa-gift" />}
                                    active={location.pathname === '/promo/createdPromo' || location.pathname === '/promo/createdCoupon' || location.pathname === '/promo/buyCoupon' || location.pathname === '/promo/expiredPromo' || location.pathname === '/promo/expiredCoupon'}
                                    defaultOpen={location.pathname === '/promo/createdPromo' || location.pathname === '/promo/createdCoupon' || location.pathname === '/promo/buyCoupon' || location.pathname === '/promo/expiredPromo' || location.pathname === '/promo/expiredCoupon'}
                                >
                                    <MenuItem
                                        active={location.pathname === '/promo/createdPromo' ? true : false}
                                        onClick={() => { handleNavigate('/promo/createdPromo'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        Promo
                                    </MenuItem>

                                    <MenuItem
                                        active={location.pathname === '/promo/createdCoupon' ? true : false}
                                        onClick={() => { handleNavigate('/promo/createdCoupon'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        Coupon Generati
                                    </MenuItem>

                                    <MenuItem
                                        active={location.pathname === '/promo/buyCoupon' ? true : false}
                                        onClick={() => { handleNavigate('/promo/buyCoupon'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        Coupon Venduti
                                    </MenuItem>

                                    <MenuItem
                                        active={location.pathname === '/promo/expiredPromo' ? true : false}
                                        onClick={() => { handleNavigate('/promo/expiredPromo'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        Promo Scadute
                                    </MenuItem>

                                    <MenuItem
                                        active={location.pathname === '/promo/expiredCoupon' ? true : false}
                                        onClick={() => { handleNavigate('/promo/expiredCoupon'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        Coupon Scaduti
                                    </MenuItem>
                                </SubMenu>
                        }

                        {
                            // visualizzo solo se l'utente è abilitato a vedere i changeLog
                            controlRole(role.changeLog, "api/Changelog", "GET") &&
                            <MenuItem
                                active={location.pathname === '/changeLog' ? true : false}
                                onClick={() => { handleNavigate('/changeLog'); toggleOpenMobile(!valueSidebarMobile); }}
                                icon={<i className="fas fa-rss-square" />}
                            >
                                Change Log
                            </MenuItem>
                        }

                        {
                            // visualizzo solo se l'utente è abilitato a vedere le preferences
                            (
                                controlRole(role.preferences, "api/Preferences", "GET") ||
                                controlRole(role.user, "api/User/Group", "GET")
                            ) &&
                            <SubMenu label="Preferenze" icon={<i className="fas fa-cogs" />}
                                active={location.pathname === '/preferences/whitelist' || location.pathname === '/preferences/whitelist' || location.pathname === '/preferences/ivacountry' || location.pathname === '/preferences/groups'}
                                defaultOpen={location.pathname === '/preferences/whitelist' || location.pathname === '/preferences/whitelist' || location.pathname === '/preferences/ivacountry' || location.pathname === '/preferences/groups'}
                            >
                                {
                                    controlRole(role.preferences, "api/Preferences", "GET") &&
                                    <MenuItem
                                        active={location.pathname === '/preferences/whitelist' ? true : false}
                                        onClick={() => { handleNavigate('/preferences/whitelist'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        WhiteList
                                    </MenuItem>
                                }

                                {
                                    controlRole(role.preferences, "api/Preferences", "GET") &&
                                    <MenuItem
                                        active={location.pathname === '/preferences/ivacountry' ? true : false}
                                        onClick={() => { handleNavigate('/preferences/ivacountry'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        IVA Country
                                    </MenuItem>
                                }

                                {
                                    controlRole(role.preferences, "api/Preferences", "GET") &&
                                    <MenuItem
                                        active={location.pathname === '/preferences/pricing' ? true : false}
                                        onClick={() => { handleNavigate('/preferences/pricing'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        Pricing
                                    </MenuItem>
                                }

                                {
                                    controlRole(role.user, "api/User/Group", "GET") &&
                                    <MenuItem
                                        active={location.pathname === '/preferences/groups' ? true : false}
                                        onClick={() => { handleNavigate('/preferences/groups'); toggleOpenMobile(!valueSidebarMobile); }}
                                    >
                                        Gruppi
                                    </MenuItem>
                                }
                            </SubMenu>
                        }

                        <MenuItem
                            style={{ color: '#c61313' }}
                            icon={<i className="fas fa-sign-out-alt" aria-hidden="true"></i>}
                            onClick={() => { logout(handleNavigate); toggleOpenMobile(!valueSidebarMobile); }}>
                            Logout {isMobile ? <span className='version'>v. {versionSite}</span> : ""}
                        </MenuItem>
                    </Menu>
                </Sidebar>
            </div>
        </>
    )
}

export default ProSidebar;