import React, { Component } from 'react';
import { controlRole } from '../../common/actions/controlRole';
import './style.scss';

export default class actionMenu extends Component {

    render() {
        const { data, role, promo } = this.props;
        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        (controlRole(role.promo, "api/Promo/Coupon", "PUT") || controlRole(role.promo, "api/Promo", "PUT")) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.props.actionClick('editPromo', promo); }}>
                                <i className="far fa-edit orange"></i>
                                Modifica {promo.isCoupon ? 'coupon' : 'promo'}
                            </div>
                        </li>
                    }

                    {
                        promo.isCoupon &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.props.actionClick('assignedCoupon', promo); }}>
                                <i className={promo.assignedCoupon ? "fas fa-user-alt-slash red" : "fas fa-user-check cadetblue"}></i>
                                {promo.assignedCoupon ? "Libera Coupon" : "Assegna Coupon"}
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.promo, "api/Promo/DeleteMany", "DELETE") &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.props.actionClick('deletePromo', promo); }}>
                                <i className="far fa-trash-alt red"></i>
                                Rimuovi {promo.isCoupon ? 'coupon' : 'promo'}
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}