import React, { Component, Fragment } from 'react';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@mui/material';
import moment from 'moment';
// import axios from 'axios';
import { connect } from 'react-redux'

import { PLAYER_TYPE, VPN_TYPE, VPNBOX_TYPE } from '../../../config';
import { paymentActions, notifyActions } from '../../../store/actions';
import CSVExport from '../../../common/csvExport';
import './style.scss';
import Modal from '../../../common/modal';
import ModalChangePayment from '../../../common/modal/modalChangePayment';
import Loading from '../../../common/loading';
import { controlRole } from '../../../common/actions/controlRole';
import SelectorCopy from '../../../common/selectorCopy';
import MyTooltip from '../../../common/tooltip';
import isEmpty from 'lodash/isEmpty';
import { withNavigate } from '../../../utility';

class tabExpanablePayment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,

            // quando il pagamento è stato effettuato
            createdAt: this.props.data.createdAt,
            // quando il pagamento è stato confermato
            issuedAt: this.props.data.issuedAt,

            // id di transazione del provider
            providerTransactionId: this.props.data.providerTransactionId,
            // tipo di provider
            paymentProvider: this.props.data.paymentProvider,
            // stato del pagamento (1 andato a buon fine, -1 non andato a buon fine, 2 in attesa di conferma)
            paymentStatus: this.props.data.paymentStatus,

            // numero fattura
            invoiceNumber: this.props.data.invoiceNumber,
            // download fattura
            invoiceUrl: this.props.data.invoiceUrl,

            //url rimborso
            refundUrl: this.props.data.refundUrl,
            // data rimborso
            refundedAt: this.props.data.refundedAt,

            // id pagamento
            id: this.props.data._id,

            // ip dove è stato fatto il pagamento
            customerIp: this.props.data.customerIp,

            // tipo di device pagato
            deviceType: this.props.data.deviceType,

            // id dell'utente che ha pagato
            deviceId: this.props.data.deviceId,
            // dati utente pagamento (email, nome, cognome, country)
            user: this.props.data.user,

            /**
             * Dati dei prodotti acquistati.
             * L'oggetto di ogni prodotto ha:
             * - name
             * - description
             * - durationDays
             * - qty
             * - price
             * - type
             * - deviceInfo (deviceId, deviceType)
             */
            products: this.props.data.products,

            // informazioni che dovranno andare nel CSV da esportare
            headersCSV: [],
            dataCSV: [],

            modal: false,
            titleModal: '',
            contentModal: '',

            modalChangeDevice: false,
            titleModalChangeDevice: 'Associa Pagamento a un nuovo Device',
            hasClickModalChange: false
        }

        setTimeout(() => {
            this.popolaCSV();
        }, 500);

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalChangeDevice = this.toggleModalChangeDevice.bind(this);
    }

    toggleModal() {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    toggleModalChangeDevice() {
        this.setState(prevState => ({ modalChangeDevice: !prevState.modal }));
        if (this.state.modalChangeDevice) {
            this.setState({ hasClickModalChange: false });
        }
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati su cui eseguire le operazioni
     */
    actionClick = (action, data) => {
        switch (action) {
            case 'refundPayment': this.props.refundPayment(this.state.providerTransactionId); break;
            case 'changeDevice': this.changeDeviceId(data); break;
            default: break;
        }
    }

    /**
     * Dato un id e un deviceType rimando alla sezione corrispondente
     */
    goToPage = (deviceId, deviceType) => {
        if (deviceType === PLAYER_TYPE) {
            this.props.setIdPlayerForDetail(deviceId)
            this.props.handleNavigation(this.props.navigate, '/player/devices');
        } else if (deviceType === VPN_TYPE) {
            this.props.setIdVpnPassForDetail(deviceId)
            this.props.handleNavigation(this.props.navigate, '/vpn/pass');
        } else if (deviceType === VPNBOX_TYPE) {
            this.props.setIdVpnPassForDetail(deviceId)
            this.props.handleNavigation(this.props.navigate, '/vpn/box');
        }
    }

    changeDeviceId = (data) => {
        this.setState({
            deviceId: data.deviceId,
            hasClickModalChange: true
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalChangeDevice: !prevState.modal }));
        }, 200);
    }

    updateDevicePayment = (data) => {
        this.props.updateDevicePayment(data);
        this.toggleModalChangeDevice();
    }

    setInfoModal = (titleModal, contentModal, action) => {
        this.setState({
            titleModal: titleModal,
            contentModal: contentModal,
            action: action
        });
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    /**
     * Popola il csv da esportare con le informazioni ricevute
     */
    popolaCSV = () => {
        const headersTemp = [
            { label: "ID Pagamento", key: "idPayment" },
            { label: "Indirizzo IP", key: "ipPayment" },
            { label: "Data Pagamento", key: "datePayment" },
            { label: "Conferma Pagamento", key: "confirmPayment" },
            { label: "Numero fattura", key: "invoiceNumber" },
            { label: "Data Rimborso", key: "dateRefund" },
            { label: "Provider", key: "provider" },
            { label: "ID Transazione provider", key: "idTransactionProvider" },
            { label: "Stato del pagamento", key: "statusPayment" },
            { label: "Nome Prodotto", key: "productName" },
            { label: "Descrizione Prodotto", key: "productDescription" },
            { label: "Prezzo Prodotto", key: "productPrice" },
            { label: "Durata Prodotto", key: "productDuration" },
            { label: "Quantità Prodotto", key: "productQty" },
            { label: "Tipo Prodotto", key: "productType" },
            { label: "DeviceId Prodotto", key: "productDeviceId" },
            { label: "DeviceType Prodotto", key: "productDeviceType" },
        ];

        const dataTemp = [
            {
                idPayment: this.state.id,
                ipPayment: this.state.customerIp,
                datePayment: this.formatData(this.state.createdAt),
                confirmPayment: this.formatData(this.state.issuedAt),
                invoiceNumber: this.state.invoiceNumber,
                dateRefund: this.formatData(this.state.refundAt),
                provider: this.state.paymentProvider,
                idTransactionProvider: this.state.providerTransactionId,
                statusPayment: this.state.paymentStatus,
                productName: this.state.products.map((item) => { return (item.name) }),
                productDescription: this.state.products.map((item) => { return (item.description) }),
                productDuration: this.state.products.map((item) => { return (item.durationInDays) }),
                productPrice: this.state.products.map((item) => { return (item.price) }),
                productQty: this.state.products.map((item) => { return (item.qty) }),
                productType: this.state.products.map((item) => { return (item.type) }),
                productDeviceId: this.state.products.map((item) => {
                    if (item.deviceInfo === null || item.deviceInfo === undefined) {
                        return '';
                    } else {
                        return (item.deviceInfo.deviceId);
                    }
                }),
                productDeviceType: this.state.products.map((item) => {
                    if (item.deviceInfo === null || item.deviceInfo === undefined) {
                        return '';
                    } else {
                        return (item.deviceInfo.deviceType);
                    }
                })
            }
        ];

        this.setState({
            headersCSV: headersTemp,
            dataCSV: dataTemp
        });
    }

    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    /**
     * Dato un oggetto con le informazioni di domicilio,
     * restituisce una stringa nel formato: Via Roma, 24 - Roma (RM) - 12345
     */
    formatDomicile = (domicilio) => {
        if (domicilio) {
            return domicilio.line1 + ', ' + domicilio.city + '(' + domicilio.state + ') - ' + domicilio.postal_code
        } else {
            return
        }
    }

    /**
     * Se una fattura non esiste, allora chiama il BE e la crea.
     * 
     * @param idPayment id del pagamento associato alla fattura da creare
     */
    // createInvoice = async (idPayment) => {
    //     try {
    //         this.setState({ isLoading: true });
    //         await axios.put(`${API_CREATE_INVOICE}/${idPayment}`);
    //         this.setState({ isLoading: false });
    //         this.props.successNotify('Fattura creata correttamente!');
    //         this.props.infoNotify('...chiudi e riapri il tab per aggiornare i dati...');
    //         this.props.refreshList();

    //     } catch (result) {
    //         this.setState({ isLoading: false });
    //         if (result && result.response && result.response.status) {
    //             this.props.errorNotify('Errore ' + result.response.status + ' nella creazione della fattura!');
    //         }
    //     }
    // }

    render() {
        const { role } = this.props;
        return (
            <div className="tabExpanablePayment">
                {(this.props.payment.isLoading || this.state.isLoading) && <Loading />}
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.formatData(this.state.createdAt)}
                                label="Quando il pagamento è stato effettuato"
                                variant='standard'
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.formatData(this.state.issuedAt)}
                                label="Quando il pagamento è stato confermato"
                                variant='standard'
                            />
                        </div>
                    </div>

                    {/* {
                        this.state.refundUrl !== null ?
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        value={this.formatData(this.state.refundedAt)}
                                        label="Data di rimborso"
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className="col-4">
                                </div>
                                <div className="col-4 text-center">
                                    <a className="btn btn-warning" href={this.state.refundUrl} download>
                                        Download Rimborso
                                        <i className="fas fa-download" style={{ 'fontSize': '1.2em', 'padding': '5px' }} />
                                    </a>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-4">
                                </div>
                                <div className="col-4">
                                </div>
                                <div className="col text-center">
                                    {
                                        (
                                            (this.state.paymentProvider === 'PayPal' || this.state.paymentProvider === 'Stripe' || this.state.paymentProvider === 'BitPay') &&
                                            this.state.paymentStatus === 1 && this.state.products[0].type !== 'VPN-BOX-HW' &&
                                            controlRole(role.payment, "api/Payment/partialRefund", "POST")
                                        ) &&
                                        <button className="btn btn-warning" onClick={() => { this.setInfoModal('Rimborsa Pagamento', 'Sei sicuro di voler rimborsare questo pagamento?', 'refundPayment') }}>
                                            Effettua rimborso
                                            <i className="fas fa-sync-alt" style={{ 'fontSize': '1.2em', 'padding': '5px' }} />
                                        </button>
                                    }
                                </div>
                            </div>
                    } */}

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.providerTransactionId}
                                label="ID transazione provider"
                                variant='standard'
                            />
                        </div>
                        <div className="col-2">
                            <TextField
                                value={this.state.paymentProvider}
                                label="Provider"
                                variant='standard'
                            />
                        </div>
                        <div className="col-2">
                            <TextField
                                value={
                                    this.state.paymentStatus === 1 ? 'Pagato' :
                                        this.state.paymentStatus === 3 ? 'Rimborsato' :
                                            this.state.paymentStatus === 6 ? 'Rimborso Parziale' :
                                                this.state.paymentStatus === 0 ? 'Non confermato' :
                                                    this.state.paymentStatus === -1 ? 'Cancellato / Respinto' :
                                                        this.state.paymentStatus === 4 ? 'Sottoscrizione Attiva' :
                                                            this.state.paymentStatus === 5 ? 'Sottoscrizione Cancellata' :
                                                                this.state.paymentStatus === 2 ? 'In Attesa' : 'Non Pagato'
                                }
                                label="Stato"
                                variant='standard'
                            />
                        </div>
                        <div className="col-1 text-center" style={{ 'margin': '0 auto' }}>
                            <CSVExport
                                data={this.state.dataCSV}
                                headers={this.state.headersCSV}
                                filename={"payment-" + this.props.data._id + ".csv"}
                            />
                        </div>
                    </div>

                    {
                        !isEmpty(this.props.data.creditNotes) &&
                        <Fragment>
                            <hr style={{ 'width': '100%' }}></hr>
                            <h5 className='red'>Note Credito</h5>
                            <div className="row">
                                {
                                    this.props.data.creditNotes.map((el, key) => {
                                        return (
                                            <div className="col-2" key={key}>
                                                <a className="btn btn-warning" href={el.refundUrl} download>
                                                    Rimborso del {moment(el.refundedAt).format('DD/MM/YYYY')}
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Fragment>
                    }

                    <hr style={{ 'width': '100%' }}></hr>
                    <h5>Prodotti</h5>
                    {
                        this.state.products.map((item, k) => {
                            return (
                                <div className="row blockProduct" key={k}>
                                    <div className="col-6">
                                        <TextField
                                            value={item.name}
                                            label="Nome"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-6">
                                        <TextField
                                            value={item.description}
                                            label="Descrizione"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-6">
                                        <FormControl className='customSelect'>
                                            <InputLabel htmlFor="id-device-player">Device ID</InputLabel>
                                            <Input
                                                id="id-device-player"
                                                value={(item.deviceInfo === null || item.deviceInfo === undefined) ? ' ' : item.deviceInfo.deviceId}
                                                readOnly
                                                endAdornment={
                                                    !(item.deviceInfo === null || item.deviceInfo === undefined) &&
                                                    <InputAdornment position="end">
                                                        <SelectorCopy
                                                            item={item.deviceInfo.deviceId}
                                                            infoNotify={this.props.infoNotify}
                                                            noLabel
                                                        />

                                                        <span className="clickForPlayer" onClick={() => { this.goToPage(item.deviceInfo.deviceId, item.deviceInfo.deviceType) }}>
                                                            <MyTooltip
                                                                title="Vai a questo ID"
                                                                position="top"
                                                                content={<i className="fas fa-share-square"></i>}
                                                            />
                                                        </span>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="col-6">
                                        <TextField
                                            value={(item.deviceInfo === null || item.deviceInfo === undefined) ? ' ' : item.deviceInfo.deviceType}
                                            label="Device Type"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-3">
                                        <TextField
                                            value={item.qty}
                                            label="Quantità"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-3">
                                        <TextField
                                            value={item.qtyRefunded || '0'}
                                            label="Quantità Rimborsata"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-3">
                                        <TextField
                                            value={item.price}
                                            label="Prezzo"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-3">
                                        <TextField
                                            value={item.durationInDays}
                                            label="Durata"
                                            variant='standard'
                                        />
                                    </div>

                                    <div className="col-3">
                                        <TextField
                                            value={item.type}
                                            label="Tipo"
                                            variant='standard'
                                        />
                                    </div>

                                    {
                                        (
                                            item.deviceInfo !== null &&
                                            item.deviceInfo.deviceType === PLAYER_TYPE &&
                                            controlRole(role.payment, "api/Payment/ChangeDevice", "PUT")
                                        ) &&
                                        <div className="col-3">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    let data = {
                                                        deviceId: item.deviceInfo.deviceId,
                                                        paymentId: this.state.id,
                                                        providerTransactionId: this.state.providerTransactionId
                                                    };
                                                    this.actionClick('changeDevice', data);
                                                }}
                                            >
                                                <i className="fas fa-tools"></i>
                                                Associa Nuovo Device
                                            </button>
                                        </div>
                                    }

                                </div>
                            )
                        })
                    }

                </FormControl>
                <Modal
                    click={() => { this.actionClick(this.state.action); this.toggleModal(); }}
                    titleModal={this.state.titleModal}
                    contentModal={this.state.contentModal}
                    toggle={this.toggleModal}
                    stateModal={this.state.modal}
                />

                {
                    this.state.hasClickModalChange &&
                    <ModalChangePayment
                        titleModal="Associa pagamento a un nuovo device"
                        updateDevicePayment={this.updateDevicePayment}
                        toggle={this.toggleModalChangeDevice}
                        stateModal={this.state.modalChangeDevice}
                        key={this.state.deviceId}
                        deviceId={this.state.deviceId}
                        paymentId={this.state.id}
                        providerTransactionId={this.state.providerTransactionId}
                        errorNotify={this.props.errorNotify}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        payment: state.payment,
        role: state.role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refundPayment: (idPayment) => {
            dispatch(paymentActions.refundPayment(idPayment))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        updateDevicePayment: (data) => {
            dispatch(paymentActions.updateDevicePayment(data))
        },
    };
};

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(tabExpanablePayment));