import React, { Component } from 'react';
import { controlRole } from '../../common/actions/controlRole';
import { SUPER_ADMIN } from '../../config';

export default class actionMenu extends Component {

    render() {
        const { role, row, user, data,
            hasClickDeleteVpnBoxStock, hasClickAssociateTracking, hasClickEndRender, hasClickRebook
        } = this.props;

        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        (controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "PUT") && row.operationType === 'B') &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickAssociateTracking(row)}>
                                <i className="fas fa-shipping-fast orange"></i>
                                Gestisci Ordine
                            </div>
                        </li>
                    }

                    {
                        (controlRole(role.vpnBoxStock, "api/VpnBoxStocks/startReturn", "POST") && row.operationType === 'U') &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickAssociateTracking(row)}>
                                <i className="fas fa-wrench yellow"></i>
                                Reso / Assistenza
                            </div>
                        </li>
                    }

                    {
                        (controlRole(role.vpnBoxStock, "api/VpnBoxStocks/endReturn", "PUT") && (row.operationType === 'R' || row.operationType === 'D')) &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickEndRender(row)}>
                                <i className="fas fa-wrench blue"></i>
                                {row.operationType === 'R' && 'Chiudi Reso'}
                                {row.operationType === 'D' && 'Chiudi Assistenza'}
                            </div>
                        </li>
                    }

                    {
                        (controlRole(role.vpnBoxStock, "api/VpnBoxStocks/rebook", "PUT") && (row.operationType === 'DD')) &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickRebook(row)}>
                                <i className="fas fa-truck-loading green"></i>
                                Rimetti in Booking
                            </div>
                        </li>
                    }

                    {
                        user.user.role === SUPER_ADMIN &&
                        <li>
                            <div className="dropdown-item" onClick={() => hasClickDeleteVpnBoxStock(row)}>
                                <i className="far fa-trash-alt red"></i>
                                Elimina Movimento
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}