import React, { Component } from 'react';
import { controlRole } from '../../common/actions/controlRole';
import { SUPER_ADMIN } from '../../config';

export default class actionMenuBox extends Component {

    actionClick = (action, data) => {
        this.props.actionClick(action, data);
    }

    render() {
        const { data, role, vpnBox, userRole } = this.props;

        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        // oltre a controllare il ruolo viene controllato anche se la box è online.
                        // se offline la diagnostica non appare

                        (controlRole(role.vpnBox, "api/VpnBox/logs", "GET", true) && vpnBox.isOnline) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('diagnostics', vpnBox); }}>
                                <i className="fas fa-wrench blue"></i>
                                Diagnostica
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.vpnBox, "api/VpnBox", "PUT", true) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('edit', vpnBox); }}>
                                <i className="far fa-edit orange"></i>
                                Modifica
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.vpnBox, "api/VpnBox/Reboot", "GET", true) &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('reboot', vpnBox); }}>
                                <i className="fas fa-sync-alt green"></i>
                                Riavvia
                            </div>
                        </li>
                    }

                    {
                        userRole.user.role === SUPER_ADMIN &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('remove', vpnBox); }}>
                                <i className="far fa-trash-alt red"></i>
                                Elimina
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}