import React, { Component, Fragment } from 'react';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isNull } from '../../utility';

export default class formFiltersPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // parametri per filtri ricerca
            id: this.props.vpn.idVpnPassForDetail === '' ? '' : this.props.vpn.idVpnPassForDetail,
            product: '',
            maxPeer: '',
            createdStartAt: null,
            createdEndAt: null,
            expireStartAt: null,
            expireEndAt: null,
            typeOrder: '',
            fieldOrder: '',
            user: '',
            key: this.props.vpn.keyVpnPassForDetail === '' ? '' : this.props.vpn.keyVpnPassForDetail
        };

        this.onChange = this.onChange.bind(this);
        this.setDateCreatedStartAt = this.setDateCreatedStartAt.bind(this);
        this.setDateCreatedEndAt = this.setDateCreatedEndAt.bind(this);
        this.setDateExpireStartAt = this.setDateExpireStartAt.bind(this);
        this.setDateExpireEndAt = this.setDateExpireEndAt.bind(this);
    }

    /**
     * Cambia il valore di quello stato.
     * 
     * @param {*} event 
     */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'typeOrder') {
            setTimeout(() => {
                this.sendParamsForSearch();
            }, 200);
        }
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(24);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    // change per le date
    setDateCreatedStartAt = (newDate) => { this.setState({ createdStartAt: newDate }); }
    setDateCreatedEndAt = (newDate) => { this.setState({ createdEndAt: newDate }); }
    setDateExpireStartAt = (newDate) => { this.setState({ expireStartAt: newDate }); }
    setDateExpireEndAt = (newDate) => { this.setState({ expireEndAt: newDate }); }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            id: '',
            product: '',
            maxPeer: '',
            createdStartAt: null,
            createdEndAt: null,
            expireStartAt: null,
            expireEndAt: null,
            typeOrder: '',
            fieldOrder: '',
            user: '',
            key: ''
        });

        setTimeout(() => {
            this.sendParamsForSearch('vuoto');
        }, 300);
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.sendParamsForSearch();
        }
    }

    /**
     * Invia i parametri filtro per la ricerca.
     * Se il parametro vuoto è presente, invia stringhe vuote per la ricerca.
     * 
     * @param {*} label se è vuoto, manda parametri vuoti
     */
    sendParamsForSearch = (label) => {
        if (label === 'vuoto') {
            this.props.setParamsForSearch({
                id: '',
                product: '',
                maxPeer: '',
                createdStartAt: '',
                createdEndAt: '',
                expireStartAt: '',
                expireEndAt: '',
                typeOrder: '',
                fieldOrder: '',
                user: '',
                sortField: '',
                sortType: '',
                key: '',
            });
        } else {
            this.props.setParamsForSearch({
                id: this.state.id,
                key: this.state.key,
                product: this.state.product,
                maxPeer: this.state.maxPeer,
                createdStartAt: this.state.createdStartAt === null ? '' : this.setHourStart(this.state.createdStartAt.$d),
                createdEndAt: this.state.createdEndAt === null ? '' : this.setHourEnd(this.state.createdEndAt.$d),
                expireStartAt: this.state.expireStartAt === null ? '' : this.setHourStart(this.state.expireStartAt.$d),
                expireEndAt: this.state.expireEndAt === null ? '' : this.setHourEnd(this.state.expireEndAt.$d),
                typeOrder: this.state.typeOrder,
                fieldOrder: this.state.fieldOrder,
                user: this.state.user,
                sortField: this.state.fieldOrder,
                sortType: this.state.typeOrder
            });
        }
    }

    /**
     * Se decido di cambiare il numero delle pass.
     * 
     * @param {*} number 
     */
    setNumberPassForSearch = (number) => {
        // this.props.setChange(s: number });
        setTimeout(() => {
            this.search(true);
        }, 200);
    }

    render() {
        const { numberVpnPass, totalPages, setChange, currentNumberPage } = this.props;
        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="id"
                            variant='standard'
                            value={this.state.id}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="ID Pass"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="key"
                            variant='standard'
                            value={this.state.key}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Key Pass"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={numberVpnPass === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPass' } }, '10')}
                        > 10 </button>
                        <button
                            className={numberVpnPass === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPass' } }, '25')}
                        > 25 </button>
                        <button
                            className={numberVpnPass === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPass' } }, '50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(totalPages) || 0}
                            page={parseInt(currentNumberPage)}
                            onChange={setChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={this.sendParamsForSearch}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            variant='standard'
                                            value={this.state.fieldOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'Id'}>ID VPN Pass</MenuItem>
                                            <MenuItem value={'CreatedAt'}>Data Creazione</MenuItem>
                                            <MenuItem value={'ExpireAt'}>Data Pagamento</MenuItem>
                                            <MenuItem value={'Product'}>Prodotto</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''} className='customSelect'>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            variant='standard'
                                            value={this.state.typeOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            format="DD/MM/YYYY"
                                            id="createdStartAt"
                                            label="Creata dal"
                                            value={this.state.createdStartAt}
                                            onChange={(newValue) => this.setDateCreatedStartAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            id="createdEndAt"
                                            label="Fino a"
                                            value={this.state.createdEndAt}
                                            onChange={(newValue) => this.setDateCreatedEndAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            format="DD/MM/YYYY"
                                            id="expireStartAt"
                                            label="Pagata dal"
                                            value={this.state.expireStartAt}
                                            onChange={(newValue) => this.setDateExpireStartAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            id="expireEndAt"
                                            label="Fino a"
                                            value={this.state.expireEndAt}
                                            onChange={(newValue) => this.setDateExpireEndAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="user"
                                        variant='standard'
                                        value={this.state.user}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Utente"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="product"
                                        variant='standard'
                                        value={this.state.product}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Prodotto"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="maxPeer"
                                        type="number"
                                        variant='standard'
                                        value={this.state.maxPeer}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Numero Peer"
                                    />
                                </div>
                            </div>

                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Fragment>
        )
    }
}