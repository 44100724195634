import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { isEmpty, isNull } from '../../utility';

import './style.scss';

/**
 * Modale per mostrare i contenuti di un oggetto che cambia in maniera dinamica.
 */
export default class modalShowDynamicData extends Component {
    render() {
        const { dynamicData, stateModal, toggle, titleModal } = this.props;

        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalShowDynamicData">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {
                        Object.keys(dynamicData).map(el => {
                            let tempData = dynamicData[el];

                            if (isNull(tempData)) {
                                return null; // Salta questo elemento
                            }

                            return (
                                <div className="row" key={el}>
                                    <div className="col-4 text-right">
                                        <b>{el}:</b>
                                    </div>
                                    <div className="col elem">
                                        {
                                            !isEmpty(tempData) ? tempData :
                                                Object.keys(tempData).map(el => {
                                                    return (
                                                        <div className="row subRow" key={el}>
                                                            <div className="col-4 text-right">
                                                                <b>{el}:</b>
                                                            </div>
                                                            <div className="col elem">
                                                                {
                                                                    !isEmpty(tempData[el]) ? tempData[el] :
                                                                        JSON.stringify(tempData[el])
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-info" onClick={toggle}>Chiudi</button>
                </DialogActions>
            </Dialog>
        )
    }
}