import React, { Component, Fragment } from 'react'

import Login from '../../login/containerLogin';
import Loading from '../../common/loading';
import Statistics from '../components/statistics';
import imgEmpty from '../../images/online-security.gif';
import './style.scss';

export default class Dashboard extends Component {
    render() {
        const { user, player, vpn, payment, role, errorNotify, isMobile } = this.props;

        return (
            <Fragment>
                {
                    (user.isLoading || payment.isLoading || vpn.isLoading || player.isLoading || role.isLoading) ?
                        <Loading /> :
                        (user.isAuthenticated && user.user.email !== undefined) ?
                            role.statistics ?
                                <Statistics
                                    role={role}
                                    isMobile={isMobile}
                                    errorNotify={errorNotify}
                                /> :
                                <div className="text-center">
                                    <div className="emptyStatistics">
                                        <img alt="empty" src={imgEmpty} />
                                        <h3>
                                            Scegli un opzione dalla Dashboard
                                        </h3>
                                    </div>
                                </div>
                            :
                            <Login />
                }
            </Fragment>
        )
    }
}