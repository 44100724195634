import React, { Component } from 'react';
import { FormControl, TextField } from '@mui/material';
import moment from 'moment';

import './style.scss';
import Loading from '../../../common/loading';

export default class tabExpanableVpnPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    render() {
        const {
            createdAt,
            expireAt,
            maxAllowedPeers,
            productName
        } = this.props.data;

        return (
            <div className="tabExpanableVpnPass">
                {this.state.isLoading && <Loading />}
                <FormControl>
                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={this.formatData(createdAt)}
                                label="Data Creazione"
                                variant='standard'
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                value={productName}
                                label="Nome Prodotto"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={this.formatData(expireAt)}
                                label="Data Scadenza"
                                variant='standard'
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                value={maxAllowedPeers}
                                label="Numero Max Peers"
                                variant='standard'
                            />
                        </div>
                    </div>
                </FormControl>
            </div>
        )
    }
}