import React, { Component } from 'react';
import './style.scss';
import { controlRole } from '../../common/actions/controlRole';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            action: ''
        };
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati dei ruoli
     */
    actionClick = (action, row) => {
        this.props.actionClick(action, row);
    }

    render() {
        const { data, role, row } = this.props;

        return (
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v"></i>
                </button>

                <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                    {
                        controlRole(role.role, "api/Role", "PUT") &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('editRole', row); }}>
                                <i className="far fa-edit orange"></i>
                                Modifica Ruolo
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.role, "api/Role/AssignRole", "PATCH") &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('assignRole', row); }}>
                                <i className="fas fa-address-book purple"></i>
                                Assegna Ruolo
                            </div>
                        </li>
                    }

                    {
                        controlRole(role.role, "api/Role", "DELETE") &&
                        <li>
                            <div className="dropdown-item" onClick={() => { this.actionClick('deleteRole', row); }}>
                                <i className="far fa-trash-alt red"></i>
                                Rimuovi Ruolo
                            </div>
                        </li>
                    }
                </ul>
            </div>
        )
    }
}