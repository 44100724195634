import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import ExpanableComponentLicence from '../components/expanableComponentLicence';
import FormFiltersLicence from '../components/formFiltersLicence';

import { isEmpty, isNil } from 'lodash';
import Date from '../../common/date';
import SelectorCopy from '../../common/selectorCopy';
import MyTooltip from '../../common/tooltip';
import Loading from '../../common/loading';
import CSVExport from '../../common/csvExport';

import './style.scss';
import { controlRole } from '../../common/actions/controlRole';
import { withNavigate } from '../../utility';

class Licences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingForRole: true,
            isLoading: false,

            openFilters: true,
            openFiltersDesktop: false
        }

        this.setLoading = this.setLoading.bind(this);
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare i player, viene rispedito alla home
        if (!isEmpty(this.props.role.player)) {
            if (!controlRole(this.props.role.player, "api/Player/GetLicences", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);

                this.props.infoNotify('Non hai nessun permesso per visualizzare i player!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare i player, viene rispedito alla home
        if (!isEmpty(this.props.role.player)) {
            if (!controlRole(this.props.role.player, "api/Player/GetLicences", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le licenze!')

            } else if (this.state.loadingForRole) {
                this.setState({ loadingForRole: false });
            }
        }
    }

    setLoading = (value) => {
        this.setState({ isLoading: value });
    }

    /**
     * Vai ai dettagli sull'utente selezionato
     */
    goToUser = (user) => {
        this.props.handleNavigation(this.props.navigate, `/userInDetail/${user}`);
    }

    /**
     * Dato un ID device, vai alla schermata dei device portando id player nei filtri
     */
    goToPlayer = (player) => {
        this.props.setIdPlayerForDetail(player);
        this.props.handleNavigation(this.props.navigate, `/player/devices`);
    }

    render() {
        const { player, getLicenceList } = this.props;

        const columns = [
            {
                name: 'Codice Attivazione',
                selector: row => <SelectorCopy item={row.key} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'ID Utente',
                selector: row =>
                    row.user === null ? 'Utente non registrato'
                        :
                        <Fragment>
                            <span className="clickGoTo" onClick={() => { this.goToUser(row.user) }} style={{ marginRight: '10px' }}>
                                <MyTooltip
                                    title="Vai a questo utente"
                                    position="top"
                                    content={<i className="fas fa-share-square"></i>}
                                />
                            </span>
                            <SelectorCopy item={row.user} infoNotify={this.props.infoNotify} />
                        </Fragment>,
                minWidth: '280px'
            },
            {
                name: 'Device ID',
                selector: row =>
                    row.playerId === null ? 'Player Non Associato'
                        :
                        <Fragment>
                            <span className="clickGoTo" onClick={() => { this.goToPlayer(row.playerId) }} style={{ marginRight: '10px' }}>
                                <MyTooltip
                                    title="Vai a questo Device ID"
                                    position="top"
                                    content={<i className="fas fa-share-square"></i>}
                                />
                            </span>
                            <SelectorCopy item={row.playerId} infoNotify={this.props.infoNotify} />
                        </Fragment>
            },
            {
                name: 'Platform',
                selector: row => (row.platform === 'sectv-tizen' || row.platform === 'tv-webos') ? "Samsung/LG" : "Android"
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {
                    this.state.loadingForRole ? <Loading /> :
                        <Fragment>
                            <div className="row">
                                <div className="col-12 licenceDesktop">
                                    <div className="contentFilterSearch">
                                        <FormFiltersLicence
                                            getLicenceList={getLicenceList}
                                            player={player}

                                            warningNotify={this.props.warningNotify}
                                            infoNotify={this.props.infoNotify}
                                            errorNotify={this.props.errorNotify}
                                            successNotify={this.props.successNotify}
                                        />
                                    </div>

                                    <div className="contentUser">
                                        <div className="row titleMobile">
                                            <div className="col-6">
                                                <h2 className='inlineBlock'>Licenze</h2>
                                                {
                                                    player.totalLicences &&
                                                    <div className="mybadge">
                                                        {player.totalLicences}
                                                    </div>
                                                }
                                            </div>

                                            {
                                                !isNil(player.listLicences) &&
                                                <div className="col-6 text-end">
                                                    <CSVExport
                                                        data={player.listLicences}
                                                        filename="list-licences.csv"
                                                        label="Scarica la lista delle licenze selezionate"
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <DataTable
                                                    columns={columns}
                                                    data={player.listLicences}
                                                    customStyles={customStyles}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    clearSelectedRows
                                                    expandableRows={this.state.isLoading ? false : true}
                                                    expandOnRowClicked
                                                    expandableRowsComponent={ExpanableComponentLicence}
                                                    expandableRowsComponentProps={{
                                                        "handleNavigation": this.props.handleNavigation
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {(player.isLoading || this.state.isLoading) && <Loading />}
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default withNavigate(Licences);