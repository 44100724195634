import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import './style.scss';
import { API_VPN_GET_PEER, API_VPN_GET_PEER_BY_MACHINE_ID } from '../../config';
import FormFilter from '../components/formFiltersPeer';
import Loading from '../../common/loading';
import ExpanableComponent from '../components/expanableComponentPeer';
import SelectorCopy from '../../common/selectorCopy';
import { returnNationality } from '../../common/actions/returnNationality';
import Date from '../../common/date';
import { withLocation, withNavigate } from '../../utility';

class vpnPeer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pathName: '',
            isLoading: true,

            // contiene la lista di tutte le vpn peer
            getVpnPeerList: [],

            // viene utilizzato come variabile d'appoggio per i dati ricevuti dal BE
            getVpnPeerListTemp: [],

            // numero di pagine vpn peer che ci sono nel DB
            totalPages: '',
            openFilters: true,
            openFiltersDesktop: false,

            // numero di pagina selezionata
            numbersPage: [],

            // quanti peer vuoi visualizzare
            numberVpnPeer: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // usato per i filtri della ricerca
            paramsForSearch: {
                sortField: '',
                sortType: '',
                id: '',
                deviceId: '',
                connectedAtStart: null,
                connectedAtEnd: null,
                vpnPass: '',
                country: '',
                model: '',
                platform: '',
                version: '',
                machineId: ''
            },
        }
    }

    componentDidMount() {
        this.controlPathName();
    }

    /**
     * Se la rotta vpn/peer/{id} ha un id, allora effettua una chiamata diversa al BE.
     */
    controlPathName = () => {
        let path = this.props.location.pathname.split('/');

        if (path[2] === 'peer' && path[3] !== undefined) {
            this.setState({ pathName: path[3] })

            // // ogni 60 secondi controlla i peer online...
            // this.timeout = setInterval(() => {
            //     this.getVpnPeerList();
            //     this.controlAndFillVpnPeer();
            // }, 60000);
        }

        setTimeout(() => {
            this.getVpnPeerList();
        }, 200);
    }

    getVpnPeerList = async (changeNumberPage) => {
        try {
            let params = {
                'elementsByPage': this.state.numberVpnPeer,
                'page': changeNumberPage ? this.state.currentNumberPage : 1,
                'sortField': this.state.paramsForSearch.sortField,
                'sortType': this.state.paramsForSearch.sortType,
                'filters[0][Id]': this.state.paramsForSearch.id,
                'filters[1][DeviceId]': this.state.paramsForSearch.deviceId,
                'filters[2][ConnectedAtStart]': this.state.paramsForSearch.connectedAtStart === null ? '' : this.state.paramsForSearch.connectedAtStart,
                'filters[3][ConnectedAtEnd]': this.state.paramsForSearch.connectedAtEnd === null ? '' : this.state.paramsForSearch.connectedAtEnd,
                'filters[4][Country]': this.state.paramsForSearch.country,
                'filters[5][Model]': this.state.paramsForSearch.model,
                'filters[6][Platform]': this.state.paramsForSearch.platform,
                'filters[7][Version]': this.state.paramsForSearch.version,
                'filters[8][MachineId]': this.state.paramsForSearch.machineId
            };

            let response;
            let temp = [];

            if (this.state.pathName === '') {
                response = await axios.get(`${API_VPN_GET_PEER}`, { params: params });

                this.setState({
                    getVpnPeerList: response.data.value.value,
                    isLoading: false
                });

            } else {
                response = await axios.get(`${API_VPN_GET_PEER_BY_MACHINE_ID}/${this.state.pathName}`, { params: params });

                if (!changeNumberPage) {
                    this.setState({
                        currentNumberPage: 1
                    });
                }

                // eslint-disable-next-line
                response.data.value.value.map((item) => {
                    temp.push({
                        id: item
                    })
                });

                this.setState({
                    getVpnPeerList: temp,
                    isLoading: false
                });
            }

            // salvo nello store tutti i peer che sono online (serve per un controllo incrociato che faccio ogni minuto...)
            this.props.setNumberVpnPeer(response.data.info.total);

            this.setState({
                // getVpnPeerListTemp: response.data.value.value,
                totalPages: response.data.info.totalPages,
            });

            // this.controlAndFillVpnPeer();

            this.setNumbersPage(response.data.info.totalPages);

        } catch (result) {
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Errore ' + result.response.status + ' su chiamata vpn peer');
            }
            this.setState({ isLoading: false });
        }
    }

    /**
     * Questa funzione viene richiamata ogni 1 minuto.
     * 
     * Prende un array di oggetti che è stato restituito dal BE, contenente tutte le informazioni suli peer,
     * verifica se alcune peer non sono piu online e modifica solo l'informazione relativa alla status online del peer.
     * 
     */
    // controlAndFillVpnPeer = () => {
    //     let arrayTemp = [];
    //     // eslint-disable-next-line
    //     this.state.getVpnPeerListTemp.map((item) => {
    //         arrayTemp.push({
    //             _id: item._id,
    //             networkInterfaceName: item.networkInterfaceName,
    //             maxBandwidth: item.maxBandwidth,
    //             mantainer: item.mantainer,
    //             subnet: item.subnet,
    //             country: item.country,
    //             publicIp: item.publicIp,
    //             city: item.city,
    //             model: item.model,
    //             os: item.os,
    //             cpuDescription: item.cpuDescription,
    //             publicKey: item.publicKey,
    //             privateKey: item.privateKey,
    //             cpuCores: item.cpuCores,
    //             createdAt: item.createdAt,
    //             updatedAt: item.updatedAt,
    //             credentials: item.credentials,
    //             status: item.status,
    //             isOnline: this.isOnline(item._id)
    //         });
    //     });

    //     if (!this.state.hasClickModal && this.state.getVpnMachineList !== []) {
    //         this.setState({
    //             getVpnPeerListList: arrayTemp
    //         });
    //     }
    // }

    /**
     * Restituisce l'icona colorata in base allo status.
     * Definisco se la macchina sia online o meno tramite icona verde o rossa.
     * 
     * @param {Boolean} status 
     */
    statusOnline = (status) => {
        if (status) {
            return <i className="fas fa-circle green"></i>;
        } else {
            return <i className="fas fa-circle red"></i>
        }
    }

    /**
     * In base alla risposta del server, 
     * setta il numero delle pagine disponibili per la paginazione
     */
    setNumbersPage = (numbersPage) => {
        let temp = [];
        for (let i = 1; i <= parseInt(numbersPage); i++) {
            temp.push(i);
        }
        this.setState({ numbersPage: temp });
    }

    /**
     * Setta il numero di pagina selezionato dall'utente
     * o il numero di elementi da visualizzare, richiamando il BE.
     * 
     * @param {*} event 
     * @param {*} newItem 
     */
    setChangePagination = (event, newItem) => {
        if (event.target.name === 'numberVpnPeer') {
            this.setState({
                isLoading: true,
                numberVpnPeer: newItem
            });
        }
        else if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                isLoading: true,
                currentNumberPage: newItem,
            });
        }

        setTimeout(() => {
            this.getVpnPeerList(true);
        }, 100);
    }

    /**
     * Setta l'oggetto che contiene i filtri per la ricerca
     * 
     * @param {*} data contengono i filtri per la ricerca
     */
    setParamsForSearch = (data) => {
        this.setState({
            isLoading: true,
            paramsForSearch: data
        });

        setTimeout(() => {
            this.getVpnPeerList();
        }, 100);
    }

    render() {
        let columns;

        if (this.state.pathName === '') {
            columns = [
                {
                    name: 'ID Peer',
                    selector: row => <SelectorCopy item={row.deviceId} infoNotify={this.props.infoNotify} />,
                    sortable: false
                },
                {
                    name: 'Machine ID',
                    selector: row => (row.machineId === '' || row.machineId === null) ? '' : <SelectorCopy item={row.machineId} infoNotify={this.props.infoNotify} />,
                    sortable: false
                },
                {
                    name: 'Country',
                    selector: row => <>{<img src={`${process.env.PUBLIC_URL}/flags/${row.country}.svg`} alt='err_img' />} <span>{returnNationality(row.country)}</span></>,
                    sortable: false
                },
                {
                    name: 'Model / Piattaforma / Versione',
                    selector: row => row.model + ' / ' + row.platform + ' / ' + row.version,
                    sortable: false
                },
                {
                    name: 'VPN Pass',
                    selector: row => (row.vpnPass === '' || row.vpnPass === null) ? '' : <SelectorCopy item={row.vpnPass} infoNotify={this.props.infoNotify} />,
                    sortable: false
                },
                {
                    name: 'ConnectedAt',
                    selector: row => <Date format="L" date={row.connectedAt} />,
                    sortable: false
                },
            ];
        } else {
            columns = [
                {
                    name: 'ID Peer',
                    selector: row => row.deviceId,
                    sortable: false
                }
            ];
        }

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <div className="row">
                <div className="col-12 vpnDesktop">

                    {(this.props.vpn.isLoading || this.state.isLoading) && <Loading />}

                    <div className="contentFilterSearch">
                        <FormFilter
                            totalPages={this.state.totalPages}
                            currentNumberPage={this.state.currentNumberPage}
                            numbersPage={this.state.numbersPage}
                            numberVpnPeer={this.state.numberVpnPeer}
                            setParamsForSearch={this.setParamsForSearch}
                            setChange={this.setChangePagination}

                            warningNotify={this.props.warningNotify}
                            errorNotify={this.props.errorNotify}
                            infoNotify={this.props.infoNotify}
                            successNotify={this.props.successNotify}
                        />
                    </div>

                    <div className="contentUser">
                        <div className="row titleMobile">
                            <div className="col">
                                <h2 className='inlineBlock'>VPN Peer</h2>
                                {
                                    this.props.vpn.totalVpnPeer &&
                                    <div className="mybadge">
                                        {this.props.vpn.totalVpnPeer}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <hr />
                                <DataTable
                                    columns={columns}
                                    data={this.state.getVpnPeerList}
                                    customStyles={customStyles}
                                    noHeader
                                    striped
                                    highlightOnHover
                                    pointerOnHover
                                    expandableRows={this.state.pathName !== ''}
                                    expandOnRowClicked={this.state.pathName !== ''}
                                    expandableRowsComponent={<ExpanableComponent />}
                                    expandableRowsComponentProps={{
                                        "errorNotify": this.props.errorNotify,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withNavigate(withLocation(vpnPeer));