import React, { Component } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

/**
 * Attivazione modale tramite button
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "cnModal" : se voglio aggiungere una classe per la modale
 *      "stateModal" : stato della modale (booleano)
 */
export default class index extends Component {
    render() {
        const { stateModal, toggle, titleModal, contentModal, cnModal, click } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true} className={cnModal}>
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {contentModal}
                </DialogContent>

                {
                    click &&
                    <DialogActions>
                        <button className="btn btn-orange" onClick={click}>OK</button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}