import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Alert, FormControl, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isNull } from '../../utility';

import './style.scss';

/**
 * Attivazione modale per nuovo/modifica gruppo
 */
export default class modalGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.dataGroup.name || '',
            description: this.props.dataGroup.description || '',
            createdAt: this.props.dataGroup.createdAt ? this.props.dataGroup.createdAt : '',
        }

        this.setChange = this.setChange.bind(this);
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setDate = (newDate) => {
        this.setState({ createdAt: newDate });
    }

    confirmSend = () => {
        const { errorNotify, dataGroup, isNewGroup, isUpdateGroup, isDeleteGroup, confirmSend } = this.props;
        if (isNewGroup) {
            if (this.state.name === "" || this.state.description === "") {
                errorNotify("Il campo nome o descrizione, non possono essere vuoti!")
            } else {
                confirmSend({
                    name: this.state.name,
                    description: this.state.description,
                });
            }
        }
        if (isUpdateGroup) {
            if (this.state.description === "") {
                errorNotify("Il campo descrizione non può essere vuoto!")
            } else {
                confirmSend({
                    name: this.state.name,
                    description: this.state.description,
                    createdAt: dataGroup.createdAt //inserisco una props poiche lo state viene modificato con moment...
                });
            }
        }
        if (isDeleteGroup) {
            confirmSend({
                "groupName": this.state.name
            });
        }
    }

    render() {
        const { stateModal, toggle, titleModal, dataGroup, isNewGroup, isUpdateGroup, isDeleteGroup } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalGroup">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {
                        !isDeleteGroup &&
                        <FormControl>
                            <div className="row justify-content-center">
                                <div className="col-6">
                                    <TextField
                                        name="name"
                                        label="Nome"
                                        variant='standard'
                                        value={this.state.name}
                                        onChange={this.setChange}
                                    />
                                </div>
                                {
                                    !isNewGroup &&
                                    <div className="col-6">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                id="dateCreation"
                                                variant="inline"
                                                name="createdAt"
                                                label="Data Creazione"
                                                value={isNull(this.state.createdAt) ? this.state.createdAt : dayjs(this.state.createdAt)}
                                                onChange={(newValue) => this.setDate(isNull(newValue) ? null : dayjs(newValue.$d))}
                                                format="DD/MM/YYYY"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                }
                            </div>
                        </FormControl>
                    }
                    {
                        (isUpdateGroup && !isDeleteGroup) &&
                        <Alert severity="warning"> Con la modifica puoi solo cambiare la descrizione.</Alert>
                    }
                    {
                        !isDeleteGroup &&
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label text-end">Descrizione</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" name="description" rows="5" value={this.state.description} onChange={this.setChange}></textarea>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isDeleteGroup &&
                        <div>
                            Sei sicuro di voler cancellare il gruppo {dataGroup.name} ?
                        </div>
                    }
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}